import { useRef } from "react";
import {
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Button,
  Link,
} from "components/lib";
import { ChevronDownIcon, ExclamationCircleIcon } from "components/app";
import { useHighLevelAlertPatientsQuery } from "hooks/api/patient/useHighLevelAlertPatientsQuery";
import { alertLabel, alertLevelColorScheme } from "data";
import { Link as ReactRouterLink } from "react-router-dom";
import { getDiffFromNowLabel } from "utils";
import { parse } from "date-fns";
import { useDisclosure, useOutsideClick } from "@chakra-ui/react";

export const HighLevelAlertPatientList = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { data: patients = [] } = useHighLevelAlertPatientsQuery();
  const { isOpen, onToggle, onClose } = useDisclosure();

  useOutsideClick({
    ref,
    handler: () => onClose(),
  });

  if (patients.length <= 0) return null;

  return (
    <Popover placement="bottom-end" isOpen={isOpen}>
      <Box ref={ref}>
        <PopoverTrigger>
          <Button
            leftIcon={<ExclamationCircleIcon boxSize="6" color="red.500" />}
            rightIcon={<ChevronDownIcon />}
            colorScheme="red"
            variant="outline"
            borderColor="red.500"
            backgroundColor="red.50"
            onClick={onToggle}
          >
            <Box fontSize="sm" color="red.500">
              {`高レベルのアラートが出ている患者が ${patients.length} 人います`}
            </Box>
          </Button>
        </PopoverTrigger>

        <PopoverContent borderRadius={0} w="400px">
          <PopoverBody padding={0}>
            <Box as="ul" maxH="80vh" overflow="scroll">
              {patients.map((patient) => (
                <Box
                  key={patient.id}
                  as="li"
                  borderBottom="1px solid"
                  borderBottomColor="gray.300"
                  _last={{
                    border: "none",
                  }}
                >
                  <Link
                    as={ReactRouterLink}
                    to={`/patientDetail/${patient.id}`}
                    color="black"
                    display="flex"
                    alignItems="center"
                    px="2"
                    py="1.5"
                    gap="4"
                    onClick={onClose}
                    _hover={{
                      backgroundColor: "orange.50",
                    }}
                  >
                    <ExclamationCircleIcon
                      boxSize="6"
                      color={alertLevelColorScheme[patient.alertLevel].primary}
                    />
                    <Box flex="1">
                      <Box
                        fontSize="xs"
                        color={
                          alertLevelColorScheme[patient.alertLevel].primary
                        }
                      >
                        {alertLabel[patient.alertLevel]}
                      </Box>
                      <Box fontWeight="bold">
                        {patient.lastName} {patient.firstName}
                      </Box>
                    </Box>
                    <Box fontSize="xs" color="gray.700">
                      {patient.lastAlertedDate !== null &&
                        getDiffFromNowLabel(
                          parse(
                            patient.lastAlertedDate,
                            "yyyy-MM-dd",
                            new Date()
                          )
                        )}
                    </Box>
                  </Link>
                </Box>
              ))}
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Box>
    </Popover>
  );
};
