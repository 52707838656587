import { FC, ReactNode } from "react";
import * as Sentry from "@sentry/react";
import { Title } from "components/app";
import { Box, Container } from "components/lib";
import { useScreenViewLogger } from "hooks/useScreenViewLogger";

const Fallback: FC = () => {
  useScreenViewLogger();

  return (
    <Box>
      <Title title="Error" />
      <Box mt={16}>
        <Container mt="8" textAlign="center">
          <Box color="gray.700" fontSize="3xl">
            500 Server Error
          </Box>
          <Box fontSize="3xl" mt="6">
            お探しのページを表示できません
          </Box>

          <Box as="p" mt="8">
            サーバーで問題が発生しているためページを表示できません。
            <br />
            しばらく時間を置いてからやり直してください。
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

type Props = {
  children: ReactNode;
};
export const ErrorBoundary: FC<Props> = ({ children }) => {
  return (
    <Sentry.ErrorBoundary fallback={<Fallback />}>
      {children}
    </Sentry.ErrorBoundary>
  );
};
