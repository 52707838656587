import { FC } from "react";
import {
  ModalHeader as ChakraModalHeader,
  ModalHeaderProps,
} from "@chakra-ui/react";

type Props = ModalHeaderProps;

export const ModalHeader: FC<Props> = ({ children, ...rest }) => {
  return (
    <ChakraModalHeader
      borderBottom="1px"
      borderBottomColor="gray.300"
      {...rest}
    >
      {children}
    </ChakraModalHeader>
  );
};
