import { Box, Tab, Tabs } from "components/lib";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

type TabType = "hospital" | "staffs";

type Props = {
  activeTab: TabType;
};

export const HospitalAndStaffSwitchTab: FC<Props> = ({ activeTab }) => {
  const navigate = useNavigate();

  const isActive = (tab: TabType) => {
    return tab === activeTab;
  };

  const handleClickTab = (tab: TabType) => {
    if (isActive(tab)) return;

    switch (tab) {
      case "hospital":
        navigate("/hospital");
        return;
      case "staffs":
        navigate("/staffs");
        return;
      default:
        return;
    }
  };

  return (
    <Box
      backgroundColor="white"
      borderBottom="1px"
      borderBottomColor="gray.300"
    >
      <Tabs>
        <Tab
          as={isActive("hospital") ? "div" : "a"}
          isActive={isActive("hospital")}
          onClick={() => handleClickTab("hospital")}
        >
          問い合わせ先情報
        </Tab>
        <Tab
          as={isActive("staffs") ? "div" : "a"}
          isActive={isActive("staffs")}
          onClick={() => handleClickTab("staffs")}
        >
          医療者・スタッフ
        </Tab>
      </Tabs>
    </Box>
  );
};
