import {
  Td,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  Box,
  Button,
  Spinner,
} from "components/lib";
import { ExclamationCircleIcon } from "components/app";
import { alertLabel, alertLevelColorScheme } from "data";
import { PatientDailyAlertSummary } from "types";
import { FC } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { usePatientDailyAlertDetailsQuery } from "hooks/api/patient/usePatientDailyAlertDetailsQuery";
import { parseISO, format } from "date-fns";

type Props = {
  patientId: string;
  alert: PatientDailyAlertSummary;
};

export const PatientAlertListItem: FC<Props> = ({ patientId, alert }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    data: alerts = [],
    refetch,
    isFetching,
  } = usePatientDailyAlertDetailsQuery(patientId, alert.date);

  const handleClickTableCell = () => {
    refetch();
    onOpen();
  };

  return (
    <Td
      border="2px"
      borderColor="gray.100"
      cursor={alert.maxAlertLevel >= 1 ? "pointer" : ""}
      onClick={alert.maxAlertLevel >= 1 ? handleClickTableCell : undefined}
      backgroundColor={
        alert.maxAlertLevel >= 1
          ? alertLevelColorScheme[alert.maxAlertLevel].secondary
          : "transparent"
      }
    >
      {alert.maxAlertLevel >= 1 && (
        <ExclamationCircleIcon
          boxSize="5"
          color={alertLevelColorScheme[alert.maxAlertLevel].primary}
        />
      )}

      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody py="6">
            <Box>
              {isFetching ? (
                <Box p="4" textAlign="center">
                  <Spinner />
                </Box>
              ) : (
                <Box>
                  {alerts.map((alert, index) => (
                    <Box
                      key={index}
                      borderBottom="1px"
                      borderBottomColor="gray.300"
                      p="2.5"
                    >
                      <Box display="flex" alignItems="center" gap="2">
                        <ExclamationCircleIcon
                          boxSize="5"
                          color={
                            alertLevelColorScheme[alert.alertLevel].primary
                          }
                        />
                        <Box
                          fontWeight="bold"
                          color={
                            alertLevelColorScheme[alert.alertLevel].primary
                          }
                        >
                          {alertLabel[alert.alertLevel]}
                        </Box>
                        <Box h="16px" w="1px" backgroundColor="gray.300" />
                        <Box>{alert.title}</Box>
                      </Box>
                      <Box mt="2">{alert.description}</Box>
                      <Box mt="2" fontSize="xs" color="gray.700">
                        {format(parseISO(alert.updatedAt), "MM月dd日 hh:mm")}
                      </Box>
                    </Box>
                  ))}
                </Box>
              )}

              <Box mt="10" textAlign="center">
                <Button onClick={onClose}>閉じる</Button>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Td>
  );
};
