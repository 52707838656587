import { alertLevelColorScheme } from "data";
import { Box, Td } from "components/lib";
import { ExclamationCircleIcon } from "components/app";
import { useDisclosure } from "@chakra-ui/react";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "components/lib";
import { FC } from "react";
import { MoodIcon } from "../MoodIcon";
import { PatientDailySymptom } from "types";

type Props = {
  mood: {
    value: number | null;
  };
  symptoms: PatientDailySymptom[];
  index: number;
};

export const Mood: FC<Props> = ({ mood, symptoms, index }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  symptoms = symptoms.sort((a, b) => {return (b.values[index]?.grade ?? 0) - (a.values[index]?.grade ?? 0)})
  const symptomNames = symptoms.map((symptom) => symptom.symptomName);
  const values = symptoms.map((symptom) => {
    return symptom.values[index];
  });

  const handleClick = () => {
    if (mood.value === null) return;
    onOpen();
  };

  const getMoodText = (value: number | null) => {
    switch (value) {
      case 1:
        return "元気がない";
      case 2:
        return "普通";
      case 3:
        return "元気";
      default:
        return "";
    }
  };

  return (
    <Td
      border="2px"
      borderColor="gray.100"
      py="1"
      onClick={handleClick}
      cursor={mood.value === null ? "" : "pointer"}
    >
      <MoodIcon mood={mood.value} />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody p="10">
            <Box display="flex" alignItems="center" gap="2">
              <MoodIcon mood={mood.value} boxSize="8" />
              <Box fontSize="2xl" fontWeight="bold">
                {getMoodText(mood.value)}
              </Box>
            </Box>

            <Box display="flex" gap="2" flexWrap="wrap" mt="5">
              {values.map((value, index) => {
                if (value !== null) {
                  return (
                    <Box
                      key={index}
                      backgroundColor="gray.100"
                      borderRadius="100px"
                      display="flex"
                      alignItems="center"
                      gap="1"
                      px="3"
                      py="1"
                    >
                      <Box>{value.scale}</Box>
                      <Box>{symptomNames[index]}</Box>
                      <ExclamationCircleIcon
                        color={alertLevelColorScheme[value.grade ?? -1].primary}
                      />
                    </Box>
                  );
                }
                return null;
              })}
            </Box>

            <Box display="flex" justifyContent="center" gap="5" mt="10">
              <Button w="120px" onClick={onClose}>
                閉じる
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Td>
  );
};
