import { Box, Spinner } from "components/lib";
import { FC, useEffect, useMemo } from "react";
import HighchartsReact from "highcharts-react-official";
import { Highcharts } from "utils/chart";
import { useIsFirstRender } from "hooks/useIsFirstRender";
import { getChartOptions } from "./getChartOptions";
import { useParams } from "react-router-dom";
import { usePatientDataDisplayTermsContext } from "contexts/PatientDataDisplayTermsContext";
import {
  usePatientBloodPressureRecordsQuery,
  useRemoveCacheOnUnMount,
} from "hooks/api/patient/usePatientBloodPressureRecordsQuery";
import { usePatientTreatmentRecordsQuery } from "hooks/api/patient/usePatientTreatmentRecordsQuery";

export const PatientBloodPressureChart: FC = () => {
  const { patientId = "" } = useParams<{ patientId: string }>();
  const isFirstRender = useIsFirstRender();
  const { displayStartDate, displayEndDate, displayTerms } =
    usePatientDataDisplayTermsContext();
  const {
    data = [],
    isFetching,
    refetch,
  } = usePatientBloodPressureRecordsQuery(patientId, {
    from_date: displayStartDate,
    to_date: displayEndDate,
  });
  const { data: treatmentRercoreds = [] } = usePatientTreatmentRecordsQuery(
    patientId,
    {
      from_date: displayStartDate,
      to_date: displayEndDate,
    }
  );

  useEffect(() => {
    if (!isFirstRender) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId, displayStartDate, displayEndDate]);

  useRemoveCacheOnUnMount();

  const options = useMemo(
    () => getChartOptions(data, treatmentRercoreds, displayTerms),
    [data, treatmentRercoreds, displayTerms]
  );

  return (
    <Box mt="10">
      <Box fontSize="2xl" textAlign="center">
        血圧
      </Box>
      <Box mt="4">
        {isFetching ? (
          <Box p="4" textAlign="center">
            <Spinner />
          </Box>
        ) : (
          <HighchartsReact highcharts={Highcharts} options={options} />
        )}
      </Box>
    </Box>
  );
};
