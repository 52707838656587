import {
  FormHelperText as ChakraFormHelperText,
  HelpTextProps,
} from "@chakra-ui/react";
import { FC } from "react";

export const FormHelperText: FC<HelpTextProps> = ({ children, ...rest }) => {
  return (
    <ChakraFormHelperText mt="1" color="gray.800" {...rest}>
      {children}
    </ChakraFormHelperText>
  );
};
