import { useQuery } from "react-query";
import {
  GetDataByTermsRequest,
  GetPatientLifelogsResponse,
  PatientLifelogs,
} from "types";
import { get } from "lib/axios";
import { useQueryClient } from "react-query";
import { useEffect } from "react";
import { formatDateToString } from "utils";

const getPatientDailyLofeLogs = async (
  patientId: string,
  params: GetDataByTermsRequest
): Promise<PatientLifelogs> => {
  const { data } = await get<GetPatientLifelogsResponse>(
    `/patients/${patientId}/life_logs`,
    {
      params: {
        from_date: formatDateToString(params.from_date),
        to_date: formatDateToString(params.to_date),
      },
    }
  );

  return {
    date: [...data.date],
    defecation: data.defecation.map((d) => ({
      count: d.count,
      type: d.type,
    })),
    energyLevel: [...data.energy_level],
    stressBalance: [...data.stress_balance],
    note: [...data.note],
  };
};

export const usePatientDailyLifeLogsQuery = (
  patientId: string,
  params: GetDataByTermsRequest
) => {
  return useQuery(
    [`patientLifeLogs`],
    () => {
      return getPatientDailyLofeLogs(patientId, params);
    },
    {
      retry: false,
      staleTime: 1000 * 60 * 5,
    }
  );
};

export const useRemoveCacheOnUnMount = () => {
  const queryClient = useQueryClient();

  useEffect(() => {
    return () => {
      queryClient.removeQueries("patientLifeLogs");
    };
  }, [queryClient]);
};
