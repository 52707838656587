import { TreatmentType, DiseaseType, AlertLevel, JobType } from "types";

export const treatmentType: Readonly<{ [key in TreatmentType]: string }> = {
  chemotherapy: "抗がん剤",
  radiation_therapy_chemotherapy: "放射線＋抗がん剤",
  molecular_targeted_therapy: "分子標的薬",
};

export const diseaseType: Readonly<{ [key in DiseaseType]: string }> = {
  cervical_intraepithelial_neoplasia_other: "子宮頸部上皮内腫瘍・その他",
  cervical_cancer: "子宮頸がん",
  endometrial_cancer: "子宮体がん",
  ovarian_cancer_fallopian_tube_cancer_peritoneal_cancer:
    "卵巣がん・卵管がん・腹膜がん",
  invading_mole_hydatidiform_mole: "侵入奇胎・絨毛がん",
  uterine_sarcoma: "子宮肉腫",
  vaginal_cancer: "腟がん",
  vulvar_cancer: "外陰がん",
};

export const alertLabel: Readonly<{ [key in AlertLevel]: string }> = {
  4: "注意レベル4",
  3: "注意レベル3",
  2: "注意レベル2",
  1: "注意レベル1",
  0: "",
  [-1]: "",
};

export const alertLevelColorScheme: Readonly<{
  [key in AlertLevel]: { primary: string; secondary: string };
}> = {
  4: {
    primary: "purple.300",
    secondary: "purple.50",
  },
  3: {
    primary: "pink.300",
    secondary: "pink.50",
  },
  2: {
    primary: "yellow.800",
    secondary: "yellow.100",
  },
  1: {
    primary: "blue.300",
    secondary: "blue.50",
  },
  0: {
    primary: "green.400",
    secondary: "green.50",
  },
  [-1]: {
    primary: "gray.700",
    secondary: "white",
  },
};

export const jobTypeLabel: Readonly<{ [key in JobType]: string }> = {
  doctor: "医師",
};

export const manualLink: Readonly<string> =
  "https://hakarute.notion.site/hakarute/42c47874852a4eb595496b367bb3cf20";
