import { createIcon } from "@chakra-ui/react";

export const CheckIcon = createIcon({
  displayName: "Check",
  defaultProps: {
    color: "#636369",
    boxSize: "5",
  },
  viewBox: "0 0 24 24",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6757 7.26285C19.0828 7.63604 19.1104 8.26861 18.7372 8.67573L11.4038 16.6757C11.2235 16.8725 10.9719 16.989 10.7052 16.9993C10.4384 17.0095 10.1787 16.9127 9.98368 16.7304L5.31701 12.3668C4.91361 11.9896 4.89237 11.3568 5.26958 10.9534C5.64679 10.55 6.2796 10.5287 6.683 10.9059L10.612 14.5798L17.2628 7.32428C17.636 6.91716 18.2686 6.88966 18.6757 7.26285Z"
      fill="currentColor"
    />
  ),
});
