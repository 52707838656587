import { FC } from "react";
import {
  Box,
  Modal,
  ModalCloseButton,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalHeader,
} from "components/lib";

import { CreateTagForm } from "../CreateTagForm";
import { EditTagIconButton } from "./EditTagIconButton";
import { DeleteTagIconButton } from "./DeleteTagIconButton";
import { useTagsQuery } from "hooks/api/tag/useTagsQuery";
import { Tag } from "types";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  canAttatchToPatient?: boolean;
  patientId?: string;
  patientTags?: Tag[];
  onChangePatientTags?: (newPatientTags: Tag[]) => void;
  onDeleteTag?: (tagId: string) => void;
};

export const EditTagModal: FC<Props> = ({ isOpen, onClose }) => {
  const { data: allTags = [] } = useTagsQuery();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>タグを管理</ModalHeader>
        <ModalCloseButton />
        <ModalBody p="0">
          <Box as="ul">
            {allTags.map((tag) => (
              <Box
                key={tag.id}
                as="li"
                pl="4"
                pr="2"
                py="2"
                display="flex"
                alignItems="center"
                borderBottom="1px"
                borderBottomColor="gray.300"
              >
                <Box flex="1">{tag.name}</Box>
                <EditTagIconButton tag={tag} allTags={allTags} />
                <DeleteTagIconButton tag={tag} />
              </Box>
            ))}
          </Box>

          <CreateTagForm allTags={allTags} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
