import { Box, Button, Link } from "components/lib";
import { FC } from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
} from "components/lib";
import { ChevronDownIcon } from "components/app";
import { Link as ReactRouterLink } from "react-router-dom";
import { useAuthContext } from "contexts/AuthContext";
import { manualLink } from "data";

const NavigationListItem: FC<{
  to?: string;
  onClick?: () => void;
  isExternal?: boolean;
}> = ({ to = "", onClick, children, isExternal }) =>
  isExternal === true ? (
    <Box as="li">
      <Link
        href={to}
        color="black"
        display="block"
        isExternal
        _hover={{
          textDecoration: "none",
          background: "orange.50",
        }}
        py={4}
        px={3}
        onClick={onClick}
      >
        {children}
      </Link>
    </Box>
  ) : (
    <Box as="li">
      <Link
        as={ReactRouterLink}
        to={to}
        color="black"
        display="block"
        _hover={{
          textDecoration: "none",
          background: "orange.50",
        }}
        py={4}
        px={3}
        onClick={onClick}
      >
        {children}
      </Link>
    </Box>
  );

export const LoggedInUserPopoverNavigation: FC = () => {
  const { currentUser, signOut } = useAuthContext();

  if (currentUser === undefined) {
    return null;
  }

  return (
    <Popover placement="bottom-end">
      <PopoverTrigger>
        <Button
          variant="outline"
          rightIcon={<ChevronDownIcon color="orange.800" boxSize="4" />}
        >
          {currentUser.fullName} としてログイン中
        </Button>
      </PopoverTrigger>
      <PopoverContent borderRadius={0} w="220px">
        <PopoverBody padding={0}>
          <Box as="ul">
            <NavigationListItem to="/patients">患者一覧</NavigationListItem>
            {currentUser.isAdmin && (
              <NavigationListItem to="/hospital">設定/管理</NavigationListItem>
            )}
            <NavigationListItem onClick={signOut}>
              ログアウト
            </NavigationListItem>
            <NavigationListItem isExternal to={manualLink}>
              使い方
            </NavigationListItem>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
