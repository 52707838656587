import { Box, Tr, Td } from "components/lib";
import { FC, useEffect } from "react";
import { usePatientDataDisplayTermsContext } from "contexts/PatientDataDisplayTermsContext";
import { useParams } from "react-router-dom";
import {
  usePatientDailySymptomsAndMoodsQuery,
  useRemoveCacheOnUnMount,
} from "hooks/api/patient/usePatientDailySymptomsAndMoodsQuery";
import { useIsFirstRender } from "hooks/useIsFirstRender";
import { DataDisplayTable } from "../DataDisplayTable";
import { TreatmentRecordList } from "../TreatmentRecordList";
import { Mood } from "./Mood";
import { Symptom } from "./Symptom";

export const PatientDailySymptomList: FC = () => {
  const { patientId = "" } = useParams<{ patientId: string }>();
  const isFirstRender = useIsFirstRender();
  const { displayStartDate, displayEndDate, eachDayOfTerms } =
    usePatientDataDisplayTermsContext();
  const { data, isFetching, refetch } = usePatientDailySymptomsAndMoodsQuery(
    patientId,
    {
      from_date: displayStartDate,
      to_date: displayEndDate,
    }
  );

  useEffect(() => {
    if (!isFirstRender) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId, displayStartDate, displayEndDate]);

  useRemoveCacheOnUnMount();

  return (
    <Box mt="10">
      <Box textAlign="center" fontSize="2xl">
        症状
      </Box>

      <DataDisplayTable isLoading={isFetching}>
        <TreatmentRecordList />

        {/* 気分一覧 */}
        <Tr>
          <Td border="2px" borderColor="gray.100" py="1">
            気分
          </Td>
          {data?.moods.map((mood, index) => (
            <Mood
              key={index}
              index={index}
              mood={mood}
              symptoms={data.symptoms}
            />
          ))}
        </Tr>

        {/* 症状一覧 */}
        {data?.symptoms.map((symptom, index) => (
          <Tr key={index}>
            <Td border="2px" borderColor="gray.100" py="1">
              {symptom.symptomName}
            </Td>

            {symptom.values.map((value, index) => (
              <Symptom
                key={index}
                symptomName={symptom.symptomName}
                date={eachDayOfTerms[index]}
                value={value}
              />
            ))}
          </Tr>
        ))}
      </DataDisplayTable>
    </Box>
  );
};
