import { FC } from "react";
import { Box } from "components/lib";
import { BoxProps } from "@chakra-ui/react";

type Props = {
  isActive?: boolean;
  size?: "sm" | "md";
  onClick?: () => void;
  as?: BoxProps["as"];
};

export const Tab: FC<Props> = ({
  isActive = false,
  size = "md",
  onClick,
  children,
  as,
}) => {
  const getSize = () => {
    switch (size) {
      case "md":
        return "200px"; // TODO rem or token に置き換える
      default:
        return "120px";
    }
  };

  return (
    <Box
      tabIndex={0}
      onClick={onClick}
      as={as}
      display="flex"
      minW={getSize()}
      height="3.75rem"
      justifyContent="center"
      alignItems="center"
      borderBottom="4px solid"
      borderBottomColor={isActive ? "orange.800" : "transparent"}
      fontWeight={isActive ? "bold" : "noraml"}
      color={isActive ? "orange.800" : "noraml"}
      _hover={{
        cursor: isActive ? "" : "pointer",
      }}
    >
      {children}
    </Box>
  );
};

// tabContext 作って、タブ変更を onTabChange で処理する。
// See https://mantine.dev/core/tabs/
export const Tabs: FC = ({ children }) => {
  return (
    <Box display="flex" justifyContent="center">
      {children}
    </Box>
  );
};
