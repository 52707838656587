import {FC, useMemo, useRef} from "react";
import {
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  DayPicker,
  Box,
} from "components/lib";
import { CalendarIcon, ChevronDownIcon } from "components/app";
import { useDisclosure, useOutsideClick } from "@chakra-ui/react";
import { usePatientDataDisplayTermsContext } from "contexts/PatientDataDisplayTermsContext";
import {addMonths, addWeeks} from "date-fns";

export const DisplayStartDaySelectButton: FC = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { isOpen, onClose, onToggle } = useDisclosure();
  const { displayEndDate, setDisplayEndDate, displayTerms  } =
    usePatientDataDisplayTermsContext();

  useOutsideClick({
    ref,
    handler: () => onClose(),
  });

  const handleSelectDay = (day: Date) => {
    const endDate = () => {
      switch (displayTerms) {
        case "3weeks":
          return addWeeks(day, 3);
        case "3months":
          return addMonths(day, 3);
        case "6months":
          return addMonths(day, 6);
      }
    }
    setDisplayEndDate(endDate());
    onClose();
  };

  const displayStartDate = useMemo(() => {
    switch (displayTerms) {
      case "3weeks":
        return addWeeks(displayEndDate, -3);
      case "3months":
        return addMonths(displayEndDate, -3);
      case "6months":
        return addMonths(displayEndDate, -6);
    }
  }, [displayEndDate, displayTerms]);

  return (
    <Popover size="lg" isOpen={isOpen}>
      <Box ref={ref}>
        <PopoverTrigger>
          <Button
            onClick={onToggle}
            variant="outline"
            leftIcon={<CalendarIcon />}
            rightIcon={<ChevronDownIcon color="orange.800" />}
          >
            表示開始日
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverBody>
            <DayPicker
              selectedDate={displayStartDate}
              onSelect={handleSelectDay}
            />
          </PopoverBody>
        </PopoverContent>
      </Box>
    </Popover>
  );
};
