import { initializeApp, FirebaseOptions, FirebaseApp } from "firebase/app";
import {
  Analytics,
  getAnalytics,
  logEvent as firebaseLogEvent,
} from "firebase/analytics";

export let app: FirebaseApp | undefined = undefined;
export let analytics: Analytics | undefined = undefined;
export const logEvent = firebaseLogEvent;

export const setUpFirebase = () => {
  const firebaseOptions: FirebaseOptions = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };

  app = initializeApp(firebaseOptions);
  analytics = getAnalytics(app);
};
