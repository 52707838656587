import { createIcon } from "@chakra-ui/react";

export const MailIcon = createIcon({
  displayName: "MailIcon",
  defaultProps: {
    color: "#636369",
  },
  viewBox: "0 0 80 80",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.6667 19.9997H63.3334C65.1744 19.9997 66.6668 21.4921 66.6668 23.333V27.8334L40.0001 39.6853L13.3334 27.8334V23.333C13.3334 21.4921 14.8258 19.9997 16.6667 19.9997ZM13.3334 35.1288V56.6663C13.3334 58.5073 14.8258 59.9997 16.6667 59.9997H63.3334C65.1744 59.9997 66.6668 58.5073 66.6668 56.6663V35.1289L40.0001 46.9807L13.3334 35.1288ZM6.66675 23.333C6.66675 17.8102 11.1439 13.333 16.6667 13.333H63.3334C68.8563 13.333 73.3334 17.8102 73.3334 23.333V56.6663C73.3334 62.1892 68.8563 66.6663 63.3334 66.6663H16.6667C11.1439 66.6663 6.66675 62.1892 6.66675 56.6663V23.333Z"
      fill="currentColor"
    />
  ),
});
