import { FC } from "react";
import { GoodFaceIcon, NormalFaceIcon, BadFaceIcon } from "components/app";
import { Box } from "components/lib";
import { IconProps } from "@chakra-ui/react";

type Props = {
  mood: number | null;
  boxSize?: IconProps["boxSize"];
};

export const MoodIcon: FC<Props> = ({ mood, boxSize = "5" }) => {
  switch (mood) {
    case 1:
      return <BadFaceIcon boxSize={boxSize} />;
    case 2:
      return <NormalFaceIcon boxSize={boxSize} />;
    case 3:
      return <GoodFaceIcon boxSize={boxSize} />;
    case null:
      return <Box>-</Box>;
    default:
      return null;
  }
};
