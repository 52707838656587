import { ChangeEvent, FC } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
} from "components/lib";
import { treatmentType, diseaseType } from "data";
import { TagFilter } from "./TagFilter";
import { usePatientSearchContext } from "contexts/PatientSearchContext";
import { DiseaseType, TreatmentType } from "types";
import { usePatientsQuery } from "hooks/api/patient/usePatientsQuery";

// 患者一覧の絞り込み
export const PatientListFilter: FC = () => {
  const { isFetching } = usePatientsQuery();
  const { updateSearchParams, searchParams, clearFilter } =
    usePatientSearchContext();
  const {
    treatmentTypes: selectedTreatmentTypes,
    diseaseTypes: selectedDiseaseTypes,
  } = searchParams;

  const handleChangeTreatmentType = (e: ChangeEvent<HTMLInputElement>) => {
    const treatmentType = e.target.value as TreatmentType;
    const newValue = e.target.checked
      ? [...selectedTreatmentTypes, treatmentType]
      : selectedTreatmentTypes.filter((tt) => tt !== treatmentType);

    updateSearchParams("treatmentTypes", newValue);
  };

  const handleChangeDiseaseType = (e: ChangeEvent<HTMLInputElement>) => {
    const diseaseType = e.target.value as DiseaseType;
    const newValue = e.target.checked
      ? [...selectedDiseaseTypes, diseaseType]
      : selectedDiseaseTypes.filter((dt) => dt !== diseaseType);

    updateSearchParams("diseaseTypes", newValue);
  };

  return (
    <Box minW={["240px", "240px", "240px", "240px", "283px"]}>
      <Box display="flex" px={4} py={5}>
        <Box backgroundColor="white" fontWeight="bold" flex="1">
          絞り込み
        </Box>
        <Button variant="link" size="sm">
          <Box as="span" fontWeight="bold" onClick={clearFilter}>
            クリア
          </Box>
        </Button>
      </Box>

      <Accordion
        allowToggle
        allowMultiple
        defaultIndex={[0, 1, 2]}
        reduceMotion
      >
        <TagFilter />

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontSize="sm">
                治療フェーズ
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel as="ul" p="0" backgroundColor="white">
            {Object.entries(treatmentType).map(([key, value]) => (
              <Box
                key={key}
                as="li"
                px={4}
                py={2.5}
                borderTop="1px"
                borderTopColor="gray.300"
              >
                <Checkbox
                  w="100%"
                  spacing={4}
                  size="lg"
                  value={key}
                  onChange={handleChangeTreatmentType}
                  isChecked={selectedTreatmentTypes.includes(
                    key as TreatmentType
                  )}
                  isDisabled={isFetching}
                >
                  <Box fontSize="sm" color="gray.800">
                    {value}
                  </Box>
                </Checkbox>
              </Box>
            ))}
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left" fontSize="sm">
                がんの種類
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel as="ul" p={0} backgroundColor="white">
            {Object.entries(diseaseType).map(([key, value]) => (
              <Box
                key={key}
                as="li"
                px={4}
                py={2.5}
                borderTop="1px"
                borderTopColor="gray.300"
              >
                <Checkbox
                  w="100%"
                  spacing={4}
                  size="lg"
                  value={key}
                  onChange={handleChangeDiseaseType}
                  isChecked={selectedDiseaseTypes.includes(key as DiseaseType)}
                  isDisabled={isFetching}
                >
                  <Box fontSize="sm">{value}</Box>
                </Checkbox>
              </Box>
            ))}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};
