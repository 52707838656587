import { FC } from "react";
import { Modal as ChakraModal, ModalProps } from "@chakra-ui/react";

export * from "./ModalBody";
export * from "./ModalCloseButton";
export * from "./ModalContent";
export * from "./ModalHeader";
export * from "./ModalOverlay";

type Props = ModalProps;

export const Modal: FC<Props> = ({ children, ...rest }) => {
  return <ChakraModal {...rest}>{children}</ChakraModal>;
};
