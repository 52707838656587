import { FC, useCallback, useMemo } from "react";
import { Table, TableContainer, Tbody, Td, Thead, Tr } from "components/lib";
import { Spinner } from "@chakra-ui/react";
import { Patient, PatientsSortableKey } from "types";
import { useNavigate } from "react-router-dom";
import { usePatientSearchContext } from "contexts/PatientSearchContext";
import { PatientListHeader } from "./PatientListHeader";
import { PatientListItem } from "./PatientListItem";
import { usePatientsQuery } from "hooks/api/patient/usePatientsQuery";

export const PatientList: FC = () => {
  const { searchParams, updateSearchParams, updateSortOrder } = usePatientSearchContext();
  const { sortKeys, orderTypes } = searchParams;
  const navigate = useNavigate();

  const { isFetching, data: patients = [] } = usePatientsQuery();

  const handleClickTableRow = useCallback(
    (patient: Patient) => {
      navigate(`/patientDetail/${patient.id}`);
    },
    [navigate]
  );

  const handleChangeSort = (sortKey: PatientsSortableKey) => {
    const index = sortKeys.indexOf(sortKey)
    if (index === -1) {
      updateSortOrder([sortKey], ['ASC'])
    } else if(orderTypes[index] === 'ASC') {
      updateSearchParams("orderTypes", orderTypes.map((x, i) => i === index ? 'DESC' : x ));
    } else {
      updateSearchParams("orderTypes", orderTypes.map((x, i) => i === index ? 'ASC' : x ));
    }
  };

  // 余計な再描画を避けるために、メモ化しています。
  const List = useMemo(() => {
    return patients.map((patient) => (
      <PatientListItem
        key={patient.id}
        patient={patient}
        onClick={handleClickTableRow}
      />
    ));
  }, [patients, handleClickTableRow]);

  return (
    <TableContainer
      maxH="100%"
      overflowY="auto"
      backgroundColor="white"
      borderRadius="16px"
    >
      <Table variant="unstyled" style={{ tableLayout: "fixed" }}>
        <Thead backgroundColor="white">
          <Tr fontSize="sm">
            <PatientListHeader
              w={["24px", "24px", "24px", "24px", "32px"]}
              pl="6"
              pr="2"
              borderRadius="16px 0 0 0"
            />
            <PatientListHeader
              w={["64px", "64px", "64px", "64px", "82px"]}
              pr="4"
              sortable
              isSortActive={sortKeys.includes("alert_level")}
              order={orderTypes[sortKeys.indexOf("alert_level")]}
              onClick={() => handleChangeSort("alert_level")}
            >
              注意Lv
            </PatientListHeader>
            <PatientListHeader
              pr="4"
              w={["auto", "auto", "auto", "120px", "168px"]}
            >
              氏名/ID
            </PatientListHeader>
            <PatientListHeader
              pr="4"
              w={["auto", "auto", "auto", "auto", "264px"]}
            >
              病名/治療フェーズ
            </PatientListHeader>
            <PatientListHeader
              pr="4"
              w={["auto", "auto", "auto", "120px", "148px"]}
              sortable
              isSortActive={sortKeys.includes("last_treatment_date")}
              order={orderTypes[sortKeys.indexOf("last_treatment_date")]}
              onClick={() => handleChangeSort("last_treatment_date")}
            >
              前回治療・診察
            </PatientListHeader>
            <PatientListHeader pr="6" borderRadius="0 16px 0 0">
              タグ
            </PatientListHeader>
          </Tr>
        </Thead>

        <Tbody pt="2" overflowY="scroll">
          {isFetching ? (
            <Tr>
              <Td colSpan={6} p="6">
                <Spinner />
              </Td>
            </Tr>
          ) : patients.length <= 0 ? (
            <Tr>
              <Td
                colSpan={6}
                borderTop="1px"
                borderTopColor="gray.300"
                py="12"
                color="gray.700"
              >
                条件に一致する患者が存在しません
              </Td>
            </Tr>
          ) : (
            List
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
