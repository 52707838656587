import { Box, Tr, Td } from "components/lib";
import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { usePatientDataDisplayTermsContext } from "contexts/PatientDataDisplayTermsContext";
import { useIsFirstRender } from "hooks/useIsFirstRender";
import {
  usePatientDailyAlertSummariesQuery,
  useRemoveCacheOnUnMount,
} from "hooks/api/patient/usePatientDailyAlertSummariesQuery";
import { DataDisplayTable } from "../DataDisplayTable";
import { TreatmentRecordList } from "../TreatmentRecordList";
import { PatientAlertListItem } from "./PatientAlertListItem";

export const PatientDailyAlertList: FC = () => {
  const { patientId = "" } = useParams<{ patientId: string }>();
  const isFirstRender = useIsFirstRender();
  const { displayStartDate, displayEndDate } =
    usePatientDataDisplayTermsContext();
  const {
    data: alerts = [],
    isFetching,
    refetch,
  } = usePatientDailyAlertSummariesQuery(patientId, {
    from_date: displayStartDate,
    to_date: displayEndDate,
  });

  useEffect(() => {
    if (!isFirstRender) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId, displayStartDate, displayEndDate]);

  useRemoveCacheOnUnMount();

  return (
    <Box mt="3">
      <Box textAlign="center" fontSize="2xl">
        アラート
      </Box>
      <DataDisplayTable isLoading={isFetching}>
        <TreatmentRecordList />
        <Tr>
          <Td border="2px" borderColor="gray.100" py="1">
            最大アラート
          </Td>
          {alerts.map((alert, index) => (
            <PatientAlertListItem
              key={index}
              alert={alert}
              patientId={patientId}
            />
          ))}
        </Tr>
        <Tr>
          <Td border="2px" borderColor="gray.100" py="1">
            件数
          </Td>
          {alerts.map((alert, index) => (
            <Td key={index} border="2px" borderColor="gray.100">
              {alert.count}
            </Td>
          ))}
        </Tr>
      </DataDisplayTable>
    </Box>
  );
};
