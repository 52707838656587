import { useQuery } from "react-query";
import { GetStaffsResponse, Staff } from "types";
import { get } from "lib/axios";
import { useQueryClient } from "react-query";

const getStaffs = async (): Promise<Staff[] | undefined> => {
  const { data: staffs } = await get<GetStaffsResponse>(`/staffs`);

  return staffs.map((staff) => ({
    id: staff.id,
    hospitalId: staff.hospital_id,
    fullName: staff.full_name,
    jobType: staff.job_type,
    isAdmin: staff.is_admin,
    isSuperAdmin: staff.is_super_admin,
    isTemporary: staff.is_temporary,
    email: staff.email,
  }));
};

export const useStaffsQuery = () => {
  return useQuery(["staffs"], getStaffs, {
    retry: false,
    staleTime: 1000 * 60 * 10,
  });
};

export const useInvalidateStaffsCache = () => {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries("staffs");
};
