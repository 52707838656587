import { FC, useEffect } from "react";
import { Box, Container } from "components/lib";
import { Header, Title } from "components/app";
import { LoginForm } from "./LoginForm";
import { useAuthContext } from "contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { useScreenViewLogger } from "hooks/useScreenViewLogger";

export const LoginPage: FC = () => {
  const { currentUser } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser !== undefined) {
      navigate("/patients");
    }
  }, [currentUser, navigate]);

  useScreenViewLogger();

  return (
    <Box>
      <Title title="ログイン" />
      <Header />
      <Box mt={16}>
        <Box textAlign="center" as="h1" fontSize="4xl">
          ログイン
        </Box>

        <Container maxW="25rem" pt="16">
          <LoginForm />
        </Container>
      </Box>
    </Box>
  );
};
