import { createIcon } from "@chakra-ui/react";

export const ExclamationCircleIcon = createIcon({
  displayName: "ExclamationCircleIcon",
  defaultProps: {
    color: "gray.700",
  },
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2V2ZM10.65 7.47C10.65 7.11196 10.7922 6.76858 11.0454 6.51541C11.2986 6.26223 11.642 6.12 12 6.12C12.358 6.12 12.7014 6.26223 12.9546 6.51541C13.2078 6.76858 13.35 7.11196 13.35 7.47V11.22C13.35 11.578 13.2078 11.9214 12.9546 12.1746C12.7014 12.4278 12.358 12.57 12 12.57C11.642 12.57 11.2986 12.4278 11.0454 12.1746C10.7922 11.9214 10.65 11.578 10.65 11.22V7.47ZM12 17.63C11.5694 17.6274 11.1574 17.4545 10.8539 17.1491C10.5504 16.8437 10.38 16.4306 10.38 16C10.382 15.68 10.4787 15.3678 10.6579 15.1027C10.8371 14.8376 11.0908 14.6315 11.387 14.5105C11.6831 14.3894 12.0085 14.3587 12.3221 14.4224C12.6357 14.486 12.9234 14.6411 13.149 14.868C13.3746 15.095 13.5279 15.3836 13.5896 15.6976C13.6513 16.0116 13.6186 16.3368 13.4957 16.6322C13.3728 16.9277 13.1652 17.1801 12.899 17.3577C12.6328 17.5352 12.32 17.63 12 17.63Z"
      fill="currentColor"
    />
  ),
});
