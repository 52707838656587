import { createIcon } from "@chakra-ui/react";

export const ChevronRightIcon = createIcon({
  displayName: "ChevronRight",
  defaultProps: {
    color: "white",
  },
  viewBox: "0 0 16 16",
  path: (
    <path
      d="M5.61267 2.94761C5.24011 3.32016 5.24011 3.92197 5.61267 4.29453L9.31907 8.00093L5.61267 11.7073C5.24011 12.0799 5.24011 12.6817 5.61267 13.0542C5.98522 13.4268 6.58703 13.4268 6.95958 13.0542L11.3442 8.66961C11.7168 8.29706 11.7168 7.69524 11.3442 7.32269L6.95958 2.93806C6.59658 2.57506 5.98522 2.57506 5.61267 2.94761Z"
      fill="currentColor"
    />
  ),
});
