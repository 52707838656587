import { createContext, useContext, FC, useState } from "react";
import {PatientSearchParams, PatientsOrderType, PatientsSortableKey} from "types";

/**
 * 患者一覧の検索条件を管理する Context。
 */
interface Context {
  searchParams: PatientSearchParams;
  updateSortOrder: (sortKeys: PatientsSortableKey[], orderTypes: PatientsOrderType[]) => void;
  clearFilter: () => void;
  updateSearchParams: <K extends keyof PatientSearchParams>(
    key: K,
    value: PatientSearchParams[K]
  ) => void;
}

const PatientSearchContext = createContext<Context | null>(null);

export const PatientSearchContextProvider: FC = ({ children }) => {
  const [searchParams, setSearchParams] = useState<PatientSearchParams>({
    tagIds: [],
    treatmentTypes: [],
    diseaseTypes: [],
    sortKeys: ['alert_level'],
    orderTypes: ['DESC'],
  });

  const updateSortOrder = (sortKeys: PatientsSortableKey[], orderTypes: PatientsOrderType[]) => {
    setSearchParams({
      ...searchParams,
      sortKeys: sortKeys,
      orderTypes: orderTypes,
    });
  };

  const clearFilter = () => {
    setSearchParams({
      ...searchParams,
      tagIds: [],
      treatmentTypes: [],
      diseaseTypes: [],
    });
  };

  const updateSearchParams: Context["updateSearchParams"] = (key, value) => {
    setSearchParams({
      ...searchParams,
      [key]: value,
    });
  };

  return (
    <PatientSearchContext.Provider
      value={{
        searchParams,
        updateSortOrder,
        clearFilter,
        updateSearchParams,
      }}
    >
      {children}
    </PatientSearchContext.Provider>
  );
};

export const usePatientSearchContext = () => {
  const context = useContext(PatientSearchContext);

  if (context === null) throw Error("PatientSearchContext is null.");

  return context;
};
