import { FC } from "react";
import {
  AccordionItem as ChakraAccordionItem,
  AccordionItemProps,
} from "@chakra-ui/react";

type Props = AccordionItemProps;

export const AccordionItem: FC<Props> = ({ children, ...rest }) => {
  return (
    <ChakraAccordionItem borderColor="gray.300" {...rest}>
      {children}
    </ChakraAccordionItem>
  );
};
