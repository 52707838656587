import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { logEvent, analytics } from "lib/firebase";

export const useScreenViewLogger = () => {
  const location = useLocation();

  useEffect(() => {
    if (process.env.NODE_ENV === "development") return;

    setTimeout(() => {
      if (analytics) {
        logEvent(analytics, "screen_view");
      }
    }, 0);
  }, [location]);
};
