import { FC, useState } from "react";
import {
  Input,
  Link,
  FormControl,
  FormLabel,
  Box,
  Button,
  FormErrorMessage,
} from "components/lib";
import { LogInForm as LogInFormType } from "types";
import { useForm } from "react-hook-form";
import { useToast } from "hooks/useToast";
import { Link as ReactRouterLink } from "react-router-dom";
import { useAuthContext } from "contexts/AuthContext";
import { useBoolean } from "@chakra-ui/react";
import axios from "axios";

export const LoginForm: FC = () => {
  const toast = useToast();
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useBoolean(false);
  const { signIn } = useAuthContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LogInFormType>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (data: LogInFormType) => {
    try {
      setIsLoading.on();
      await signIn(data.email, data.password);
    } catch (e) {
      setIsLoading.off();

      if (axios.isAxiosError(e)) {
        const { response } = e;
        if (response && (response.status === 401 || response.status === 422)) {
          setHasError(true);
          toast({
            title: "メールアドレスまたはパスワードが間違っています",
            status: "error",
          });
        } else {
          toast({
            title: "エラーが発生しました。",
            status: "error",
          });
          throw e;
        }
      } else {
        toast({
          title: "エラーが発生しました。",
          status: "error",
        });
        throw e;
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <FormControl isInvalid={errors.email !== undefined || hasError}>
        <FormLabel htmlFor="email">メールアドレス</FormLabel>
        <Input
          {...register("email", { required: "必須項目です" })}
          onFocus={() => setHasError(false)}
          id="email"
          type="email"
        />
        {errors.email && (
          <FormErrorMessage>{errors.email.message}</FormErrorMessage>
        )}
      </FormControl>

      <FormControl
        mt={10}
        isInvalid={errors.password !== undefined || hasError}
      >
        <FormLabel htmlFor="password">パスワード</FormLabel>
        <Input
          {...register("password", { required: "必須項目です" })}
          onFocus={() => setHasError(false)}
          id="password"
          type="password"
        />
        {errors.password && (
          <FormErrorMessage>{errors.password.message}</FormErrorMessage>
        )}
      </FormControl>

      <Button
        type="submit"
        isFullWidth
        size="lg"
        mt={10}
        colorScheme="orange"
        isLoading={isLoading}
      >
        ログイン
      </Button>

      <Box textAlign="center" mt={6}>
        <Link as={ReactRouterLink} to="/requestResetPassword">
          パスワードを忘れた方
        </Link>
      </Box>
    </form>
  );
};
