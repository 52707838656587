import { FC } from "react";
import isEmail from "validator/lib/isEmail";
import {
  Input,
  FormControl,
  FormLabel,
  Button,
  FormErrorMessage,
} from "components/lib";
import { useForm } from "react-hook-form";
import { usePasswordResetEmailMutation } from "hooks/api/auth/usePasswordResetEmailMutation";
import { useToast } from "hooks/useToast";

type Props = {
  onSuccess: () => void;
};

export const RequestResetPasswordForm: FC<Props> = ({ onSuccess }) => {
  const { mutate, isLoading } = usePasswordResetEmailMutation();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = (data: { email: string }) => {
    mutate(data.email, {
      onSuccess: () => {
        onSuccess();
      },
      onError: (e) => {
        toast({
          title: "エラーが発生しました",
          status: "error",
        });
        throw e;
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <FormControl isInvalid={errors.email !== undefined}>
        <FormLabel htmlFor="email">メールアドレス</FormLabel>
        <Input
          {...register("email", {
            required: "必須項目です",
            validate: {
              validEmail: (v) => {
                return isEmail(v) ? undefined : "不正なメールアドレスです";
              },
            },
          })}
          id="email"
          type="email"
        />
        {errors.email && (
          <FormErrorMessage>{errors.email.message}</FormErrorMessage>
        )}
      </FormControl>

      <Button
        type="submit"
        isFullWidth
        size="lg"
        mt={10}
        colorScheme="orange"
        isLoading={isLoading}
      >
        送信
      </Button>
    </form>
  );
};
