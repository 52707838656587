import { FC } from "react";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  IconButton,
} from "components/lib";
import { TrashIcon } from "components/app";
import { useDisclosure } from "@chakra-ui/react";
import { useToast } from "hooks/useToast";
import { Hospital, Contact } from "types";
import { useInvalidateHospitalQuery } from "hooks/api/hospital/useHospitalQuery";
import { useUpdateHospitalMutation } from "hooks/api/hospital/useUpdateHospitalMutation";

type Props = {
  hospital: Hospital;
  contact: Contact;
};

export const DeleteContactButton: FC<Props> = ({ hospital, contact }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const invalidateHospitalQuery = useInvalidateHospitalQuery();
  const { mutate, isLoading } = useUpdateHospitalMutation();
  const toast = useToast();

  const handleDeleteContact = () => {
    mutate(
      {
        description: hospital.contactInfo.description,
        contacts: hospital.contactInfo.contacts.filter(
          (_contact) => _contact.id !== contact.id
        ),
      },
      {
        onSuccess: () => {
          invalidateHospitalQuery();
          toast({
            title: "対応窓口を削除しました",
            status: "success",
          });
          onClose();
        },
        onError: (e) => {
          toast({
            title: "エラーが発生しました",
            status: "error",
          });
          throw e;
        },
      }
    );
  };

  if (contact === undefined) return null;

  return (
    <>
      <IconButton
        aria-label="削除"
        icon={<TrashIcon boxSize="6" color="orange.800" />}
        variant="ghost"
        onClick={onOpen}
      />

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnEsc={!isLoading}
        closeOnOverlayClick={!isLoading}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>対応窓口を削除</ModalHeader>
          <ModalCloseButton />
          <ModalBody p="10">
            <Box as="p" textAlign="center">
              {contact.name} を削除します。
              <br />
              よろしいですか?
            </Box>
            <Box display="flex" justifyContent="center" gap="16px" mt="10">
              <Button
                colorScheme="gray"
                onClick={onClose}
                isDisabled={isLoading}
              >
                キャンセル
              </Button>
              <Button onClick={handleDeleteContact} isLoading={isLoading}>
                削除
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
