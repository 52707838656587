import { forwardRef } from "react";
import { Checkbox as ChakraCheckbox, CheckboxProps } from "@chakra-ui/react";

type Props = CheckboxProps;

export const Checkbox = forwardRef<HTMLInputElement, Props>(
  ({ children, ...rest }, ref) => {
    return (
      <ChakraCheckbox
        ref={ref}
        colorScheme="orange"
        borderRadius="4px"
        fontWeight="normal"
        borderColor="gray.700"
        {...rest}
      >
        {children}
      </ChakraCheckbox>
    );
  }
);
