import { FC, useMemo } from "react";
import { Box, Container, Tabs, Tab, Button } from "components/lib";
import { ChevronLeftIcon, ChevronRightIcon } from "components/app";
import { DisplayStartDaySelectButton } from "./DisplayStartDaySelectButton";
import { usePatientDataDisplayTermsContext } from "contexts/PatientDataDisplayTermsContext";
import { addMonths, addWeeks } from "date-fns";
import { DateDisplayTerms } from "types";
import { useNavigate } from "react-router-dom";

export const PatientDetailPageNavigation: FC = () => {
  const {
    displayEndDate,
    setDisplayEndDate,
    displayTerms,
    setDisplayTerms,
  } = usePatientDataDisplayTermsContext();
  const navigate = useNavigate();

  const handleShowPrevTerms = () => {
    switch (displayTerms) {
      case "3weeks":
        setDisplayEndDate(addWeeks(displayEndDate, -3));
        return;
      case "3months":
        setDisplayEndDate(addMonths(displayEndDate, -3));
        return;
      case "6months":
        setDisplayEndDate(addMonths(displayEndDate, -6));
        return;
    }
  };

  const handleShowNextTerms = () => {
    switch (displayTerms) {
      case "3weeks":
        setDisplayEndDate(addWeeks(displayEndDate, 3));
        return;
      case "3months":
        setDisplayEndDate(addMonths(displayEndDate, 3));
        return;
      case "6months":
        setDisplayEndDate(addMonths(displayEndDate, 6));
        return;
    }
  };

  const handleChangeTerms = (terms: DateDisplayTerms) => {
    setDisplayTerms(terms);
  };

  const termsLabel = useMemo(() => {
    switch (displayTerms) {
      case "3weeks":
        return "3週間";
      case "3months":
        return "3ヶ月";
      case "6months":
        return "6ヶ月";
    }
  }, [displayTerms]);

  return (
    <Box backgroundColor="white">
      <Container maxW="1440px" px="6">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Box
              display="flex"
              gap="3"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Button
                variant="link"
                colorScheme="gray"
                color="gray.800"
                leftIcon={<ChevronLeftIcon color="gray.800" boxSize="6" />}
                onClick={() => navigate("/patients")}
              >
                患者一覧に戻る
              </Button>
              <Box w="px" h="1.5rem" backgroundColor="gray.300" />

              <DisplayStartDaySelectButton />
            </Box>
          </Box>
          <Box>
            <Tabs>
              <Tab
                isActive={displayTerms === "3weeks"}
                size="sm"
                onClick={() => handleChangeTerms("3weeks")}
              >
                3週間
              </Tab>
              <Tab
                isActive={displayTerms === "3months"}
                size="sm"
                onClick={() => handleChangeTerms("3months")}
              >
                3ヶ月
              </Tab>
              <Tab
                isActive={displayTerms === "6months"}
                size="sm"
                onClick={() => handleChangeTerms("6months")}
              >
                6ヶ月
              </Tab>
            </Tabs>
          </Box>
          <Box>
            <Box display="flex" gap="3" justifyContent="flex-end">
              <Button
                leftIcon={<ChevronLeftIcon />}
                onClick={handleShowPrevTerms}
              >
                前の{termsLabel}
              </Button>
              <Button
                rightIcon={<ChevronRightIcon />}
                onClick={handleShowNextTerms}
              >
                次の{termsLabel}
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
