import { forwardRef } from "react";
import { Select as ChakraSelect, SelectProps } from "@chakra-ui/react";

type Props = SelectProps;

export const Select = forwardRef<HTMLSelectElement, Props>(
  ({ children, ...rest }, ref) => {
    return (
      <ChakraSelect
        ref={ref}
        backgroundColor="white"
        errorBorderColor="red.500"
        focusBorderColor="orange.800"
        borderColor="gray.300"
        {...rest}
      >
        {children}
      </ChakraSelect>
    );
  }
);
