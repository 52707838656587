import { FC } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
} from "components/lib";
import { AddIcon } from "components/app";
import { useDisclosure } from "@chakra-ui/react";
import { useCreateStaffMutation } from "hooks/api/staff/useCreateStaffMutation";
import { StaffForm as StaffFormType } from "types";
import { useToast } from "hooks/useToast";
import { useHospitalQuery } from "hooks/api/hospital/useHospitalQuery";
import { useInvalidateStaffsCache } from "hooks/api/staff/useStaffsQuery";
import { StaffForm } from "../StaffForm";

export const CreateStaffButton: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: hospital } = useHospitalQuery();
  const { mutate, isLoading } = useCreateStaffMutation();
  const invalidateStaffsCache = useInvalidateStaffsCache();
  const toast = useToast();

  const onSubmit = (data: StaffFormType) => {
    mutate(data, {
      onSuccess: () => {
        invalidateStaffsCache();
        toast({ title: "招待メールを送信しました", status: "success" });
        onClose();
      },
      onError: (e) => {
        toast({
          title: "エラーが発生しました。",
        });
        throw e;
      },
    });
  };

  return (
    <>
      <Button
        variant="outline"
        backgroundColor="white"
        leftIcon={<AddIcon boxSize="5" color="orange.800" />}
        onClick={onOpen}
      >
        医療者・スタッフを追加
      </Button>

      <Modal
        size="5xl"
        isOpen={isOpen}
        onClose={onClose}
        closeOnEsc={!isLoading}
        closeOnOverlayClick={!isLoading}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>医療者・スタッフを追加</ModalHeader>
          <ModalCloseButton />
          <ModalBody p="10">
            <StaffForm
              onSubmit={onSubmit}
              isLoading={isLoading}
              validEmailDomainList={hospital?.whitelistDomains}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
