import { FC } from "react";
import { Accordion as ChakraAccordion, AccordionProps } from "@chakra-ui/react";

export * from "./AccordionButton";
export * from "./AccordionPanel";
export * from "./AccordionIcon";
export * from "./AccordionItem";

type Props = AccordionProps;

export const Accordion: FC<Props> = ({ children, ...rest }) => {
  return <ChakraAccordion {...rest}>{children}</ChakraAccordion>;
};
