import { extendTheme } from "@chakra-ui/react";
export const theme = extendTheme({
  styles: {
    global: {
      "html, body": {
        fontFamily: "'Noto Sans JP', sans-serif",
        height: "100%",
        color: "black",
      },
      body: {
        backgroundColor: "gray.100",
      },
      li: {
        listStyle: "none",
      },
      "#root": {
        height: "100%",
      },
    },
  },
  colors: {
    black: "#2C2B2B",
    gray: {
      100: "#F8F7F7",
      300: "#ECEBEB",
      400: "#CAC9C9",
      500: "#ADACAC",
      700: "#6E6D6D",
      800: "#4E4D4D",
      900: "#2C2B2B",
    },
    red: {
      50: "#FFEBEE",
      500: "#FF382F",
    },
    orange: {
      50: "#FEF0E5",
      500: "#FE7C19",
      600: "#FE7C19",
      800: "#FE7C19",
    },
    green: {
      50: "#E6FAEC",
      400: "#34D876",
      700: "#00AC43",
    },
    purple: {
      50: "#F3E8FD",
      300: "#B476F3",
    },
    pink: {
      50: "#FEE7F1",
      300: "#FF70AC",
    },
    yellow: {
      100: "#F9F6C2",
      800: "#E7A01C",
    },
    blue: {
      50: "#E4F2FF",
      300: "#68B6FF",
      600: "#3488EE",
    },
  },
});
