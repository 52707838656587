import { Box, Spinner } from "components/lib";
import { FC, useMemo, useEffect } from "react";
import HighchartsReact from "highcharts-react-official";
import { Highcharts } from "utils/chart";
import {
  getAlertChartOptions,
  getALertChartCallback,
} from "./getAlertChartOptions";
import { getCountChartOptions } from "./getCountChartOptions";
import { useIsFirstRender } from "hooks/useIsFirstRender";
import { useParams } from "react-router-dom";
import { usePatientDataDisplayTermsContext } from "contexts/PatientDataDisplayTermsContext";
import {
  usePatientDailyAlertSummariesQuery,
  useRemoveCacheOnUnMount,
} from "hooks/api/patient/usePatientDailyAlertSummariesQuery";
import { usePatientTreatmentRecordsQuery } from "hooks/api/patient/usePatientTreatmentRecordsQuery";

export const PatientAlertChart: FC = () => {
  const { patientId = "" } = useParams<{ patientId: string }>();
  const isFirstRender = useIsFirstRender();
  const { displayStartDate, displayEndDate, displayTerms } =
    usePatientDataDisplayTermsContext();

  const {
    data: alertSummaries = [],
    isFetching,
    refetch,
  } = usePatientDailyAlertSummariesQuery(patientId, {
    from_date: displayStartDate,
    to_date: displayEndDate,
  });

  const { data: treatmentRercoreds = [] } = usePatientTreatmentRecordsQuery(
    patientId,
    {
      from_date: displayStartDate,
      to_date: displayEndDate,
    }
  );

  const alertChartOptions = useMemo(
    () =>
      getAlertChartOptions(alertSummaries, treatmentRercoreds, displayTerms),
    [alertSummaries, treatmentRercoreds, displayTerms]
  );
  const alertCallback = useMemo(
    () => getALertChartCallback(alertSummaries),
    [alertSummaries]
  );

  const countChartOptions = useMemo(
    () => getCountChartOptions(alertSummaries, displayTerms),
    [alertSummaries, displayTerms]
  );

  useEffect(() => {
    if (!isFirstRender) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId, displayStartDate, displayEndDate]);

  useRemoveCacheOnUnMount();

  return (
    <Box mt="10">
      <Box fontSize="2xl" textAlign="center">
        アラート
      </Box>
      <Box mt="4">
        {isFetching ? (
          <Box p="4" textAlign="center">
            <Spinner />
          </Box>
        ) : (
          <Box backgroundColor="white" borderRadius="16px" overflow="hidden">
            <Box borderBottom="1px" borderBottomColor="gray.300">
              <HighchartsReact
                highcharts={Highcharts}
                options={alertChartOptions}
                callback={alertCallback}
              />
            </Box>
            <HighchartsReact
              highcharts={Highcharts}
              options={countChartOptions}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
