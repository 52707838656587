import { FC } from "react";
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  TableColumnHeaderProps,
  TableCellProps,
  Spinner,
} from "components/lib";
import { useStaffsQuery } from "hooks/api/staff/useStaffsQuery";
import { CheckIcon } from "components/app";
import { jobTypeLabel } from "data";
import { EditStaffIconButton } from "./EditStaffIconButton";
import { DeleteStaffIconButton } from "./DeleteStaffIconButton";

const TableHeader: FC<TableColumnHeaderProps> = ({ children, ...rest }) => (
  <Th pt="6" pb="4" px="4" textAlign="left" border="none" {...rest}>
    {children}
  </Th>
);
const TableData: FC<TableCellProps> = ({ children, ...rest }) => (
  <Td py="4" px="4" textAlign="left" fontSize="md" {...rest}>
    {children}
  </Td>
);

export const StaffList: FC = () => {
  const { data: staffs, isLoading } = useStaffsQuery();

  return (
    <TableContainer backgroundColor="white" borderRadius="16px" mt="8">
      <Table variant="unstyled">
        <Thead>
          <Tr>
            <TableHeader pl="10" w="80px">
              管理者
            </TableHeader>
            <TableHeader w="120px">役職</TableHeader>
            <TableHeader w="200px">氏名</TableHeader>
            <TableHeader>メールアドレス</TableHeader>
            <TableHeader w="120px"></TableHeader>
          </Tr>
        </Thead>
        <Tbody>
          {isLoading && (
            <Tr>
              <TableData colSpan={6} textAlign="center">
                <Spinner />
              </TableData>
            </Tr>
          )}

          {staffs?.map((staff, index) => (
            <Tr key={index} borderTop="1px" borderTopColor="gray.300" px="4">
              <TableData pl="10">
                {staff.isAdmin && <CheckIcon boxSize="6" />}
              </TableData>
              <TableData>{jobTypeLabel[staff.jobType]}</TableData>
              <TableData>{staff.fullName}</TableData>
              <TableData>{staff.email}</TableData>
              <TableData py="0" pr="4" textAlign="right">
                {!staff.isSuperAdmin && <EditStaffIconButton staff={staff} />}
                {!staff.isSuperAdmin && <DeleteStaffIconButton staff={staff} />}
              </TableData>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
