import { Box, Link } from "components/lib";
import { useDisclosure } from "@chakra-ui/react";
import { FC } from "react";
import { EditTagModal } from "components/app";

export const EditTagLink: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box textAlign="center" p="2" borderTop="1px" borderTopColor="gray.300">
      <Link fontSize="sm" fontWeight="bold" onClick={onOpen}>
        タグの追加・削除
      </Link>
      <EditTagModal isOpen={isOpen} onClose={onClose} />
    </Box>
  );
};
