import { useMutation } from "react-query";
import { PostPatientTagsResponse, Tag } from "types";
import { put } from "lib/axios";
import { AxiosError } from "axios";

const updatePatientTagsMutation = async (params: {
  patientId: string;
  tagIds: string[];
}) => {
  const res = await put<PostPatientTagsResponse>(
    `/patients/${params.patientId}/tags`,
    params.tagIds.map((tagId) => ({
      id: tagId,
    }))
  );
  return res.data;
};

export const useUpdatePatientTagsMutation = () => {
  return useMutation<
    Tag[],
    AxiosError,
    { patientId: string; tagIds: string[] }
  >((params) => {
    return updatePatientTagsMutation(params);
  });
};
