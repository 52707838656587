import { useQuery, useQueryClient } from "react-query";
import {
  GetPatientInteractiveSerchResponse,
  PatientInteractiveSerchResult,
} from "types";
import { get } from "lib/axios";

const searchPatientInteractive = async (
  query: string
): Promise<PatientInteractiveSerchResult[] | undefined> => {
  if (query === "") return undefined;

  const { data: results } = await get<GetPatientInteractiveSerchResponse>(
    "/patients/search_interactive",
    {
      params: {
        q: query,
      },
    }
  );

  return results.map((result) => ({
    id: result.id,
    patientCode: result.patient_code,
    name: result.name,
  }));
};

export const usePatientInteractiveSearchQuery = (query: string) => {
  return useQuery(
    [`patientInteractiveSearch`],
    () => {
      return searchPatientInteractive(query);
    },
    {
      retry: false,
      staleTime: 1000 * 60 * 10,
      enabled: false, // マウント時に query を実行しない
    }
  );
};

export const useRemoveQueryCache = () => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.removeQueries("patientInteractiveSearch");
  };
};
