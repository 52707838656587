import { Link } from "components/lib";
import { useDisclosure } from "@chakra-ui/react";
import { FC } from "react";
import { useQueryClient } from "react-query";
import { Tag, Patient } from "types";
import { EditPatientTagModal } from "components/app";

type Props = {
  patient: Patient;
};

export const EditTagLink: FC<Props> = ({ patient }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const queryClient = useQueryClient();
  const cacheKey = ["patientDetail", patient.id];

  const handleChangePatientTag = (newTags: Tag[]) => {
    const patientDetail = queryClient.getQueryData<Patient>(cacheKey);
    if (patientDetail === undefined) return;

    queryClient.setQueryData(cacheKey, {
      ...patientDetail,
      tags: newTags,
    });

    // 患者リストのキャッシュを無効にする。
    queryClient.removeQueries("patientList");
  };

  return (
    <>
      <Link onClick={onOpen} fontSize="sm">
        タグの追加・編集
      </Link>

      <EditPatientTagModal
        isOpen={isOpen}
        onClose={onClose}
        patient={patient}
        onChangePatientTags={handleChangePatientTag}
      />
    </>
  );
};
