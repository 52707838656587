import { isSameMonth } from "date-fns";
import { Highcharts, getProtLinesByTreatmentRecords } from "utils/chart";
import {
  PatientStepRecords,
  PatientTreatmentRecords,
  DateDisplayTerms,
} from "types";
import { getXaxisIntervalByTerms } from "utils/chart";

export const getChartOptions = (
  data: PatientStepRecords,
  treatmentRecords: PatientTreatmentRecords,
  terms: DateDisplayTerms
) => {
  if (data.length <= 0) return undefined;

  let prevDate: Date | null = null;

  const options: Highcharts.Options = {
    chart: {
      borderRadius: 16,
      spacingTop: 29,
      spacingLeft: 0,
      spacingRight: 0,
      spacingBottom: 27,
    },
    title: {
      text: "",
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        type: "column",
        data,
        color: "#68B6FF",
        zIndex: 99,
        marker: {
          enabled: false,
        },
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            enabled: false,
          },
        },
      },
    ],
    xAxis: {
      title: {
        text: "",
      },
      type: "datetime",
      startOnTick: true,
      minPadding: 0,
      tickWidth: 0,
      tickLength: 0,
      gridLineWidth: 1,
      opposite: true,
      lineColor: "#e5e5e5",
      plotLines: getProtLinesByTreatmentRecords(treatmentRecords),
      tickPositioner: function () {
        const newTicks = [],
          min = this.min || 0,
          max = this.max || 0,
          interval = getXaxisIntervalByTerms(terms);

        for (let i = min; i <= max; i += interval) {
          newTicks.push(i);
        }

        return newTicks;
      },
      labels: {
        useHTML: true,
        style: {
          fontSize: "14px",
          color: "#000",
          padding: "0",
        },
        formatter: function () {
          const currentDate = new Date(this.value);

          if (prevDate == null || !isSameMonth(prevDate, currentDate)) {
            prevDate = currentDate;
            return Highcharts.dateFormat(
              '<div style="font-size: 14px; text-align: center; color: #000;"><span style="font-weight: bold; display: inline-block; margin-bottom: 2px; white-space:nowrap;">%Y年%m月</span><br><span>%e(%a)</span></div>',
              this.value as number
            );
          } else {
            prevDate = currentDate;
            return Highcharts.dateFormat("%e(%a)", this.value as number);
          }
        },
      },
    },
    yAxis: {
      title: {
        text: "",
      },
      max: 15000,
      labels: {
        useHTML: true,
        align: "right",
        x: 0,
        formatter: function () {
          if (this.isLast) {
            return (
              '<div class="highcharts__value-label highcharts__value-unit" style="width: 130px;text-align:right; padding-right: 16px;">' +
              this.value.toLocaleString() +
              "歩以上" +
              "</div>"
            );
          } else {
            return (
              '<div class="highcharts__value-label" style="width: 130px; text-align: right; padding-right:16px;">' +
              this.value.toLocaleString() +
              "</div>"
            );
          }
        },
      },
    },
    tooltip: {
      headerFormat: "",
      useHTML: true,
      pointFormatter: function () {
        return (
          '<div class="highcharts__tooltip-value" style="text-align: center;">' +
          this.y?.toLocaleString() +
          "歩</div>"
        );
      },
      footerFormat: '<div class="highcharts__tooltip-date">{point.key}</div>',
      xDateFormat: "%Y.%m.%d",
      followTouchMove: true,
      backgroundColor: "#333",
      borderRadius: 4,
      borderWidth: 4,
      borderColor: "#333",
      shadow: false,
      padding: 4,
      shape: "square",
      style: {
        color: "#ffffff",
        fontSize: "10px",
      },
    },
  };

  return options;
};
