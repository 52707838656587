import { createIcon } from "@chakra-ui/react";

export const CalendarIcon = createIcon({
  displayName: "Calendar",
  defaultProps: {
    color: "#FF6E4E",
  },
  viewBox: "0 0 24 24",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 3C6.44772 3 6 3.44772 6 4V5C4.34315 5 3 6.34315 3 8V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V8C21 6.34315 19.6569 5 18 5V4C18 3.44772 17.5523 3 17 3C16.4477 3 16 3.44772 16 4V5H8V4C8 3.44772 7.55228 3 7 3ZM19 9H5V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V9ZM9 13V11H7V13H9ZM9 15V17H7V15H9ZM13 11H11V13H13V11ZM11 15H13V17H11V15ZM17 13V11H15V13H17ZM17 15V17H15V15H17Z"
      fill="#FF6E4E"
    />
  ),
});
