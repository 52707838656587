import { Box, Tr, Td } from "components/lib";
import { FC, useEffect } from "react";
import { usePatientDataDisplayTermsContext } from "contexts/PatientDataDisplayTermsContext";
import { useParams } from "react-router-dom";
import { useIsFirstRender } from "hooks/useIsFirstRender";
import {
  usePatientDailyLifeLogsQuery,
  useRemoveCacheOnUnMount,
} from "hooks/api/patient/usePatientDailyLifeLogsQuery";
import { StressBalance } from "./StressBalance";
import { DataDisplayTable } from "../DataDisplayTable";
import { TreatmentRecordList } from "../TreatmentRecordList";
import { Note } from "./Note";

export const PatientDailyLifeLogList: FC = () => {
  const { patientId = "" } = useParams<{ patientId: string }>();
  const isFirstRender = useIsFirstRender();
  const { displayStartDate, displayEndDate } =
    usePatientDataDisplayTermsContext();
  const { data, isFetching, refetch } = usePatientDailyLifeLogsQuery(
    patientId,
    {
      from_date: displayStartDate,
      to_date: displayEndDate,
    }
  );

  useEffect(() => {
    if (!isFirstRender) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId, displayStartDate, displayEndDate]);

  useRemoveCacheOnUnMount();

  return (
    <Box mt="10">
      <Box textAlign="center" fontSize="2xl">
        ライフログ
      </Box>

      <DataDisplayTable isLoading={isFetching}>
        <TreatmentRecordList />

        <Tr>
          <Td border="2px" borderColor="gray.100" py="1">
            便の回数
          </Td>
          {data?.defecation.map((defecation, index) => (
            <Td key={index} border="2px" borderColor="gray.100" py="1">
              {defecation.count ?? "-"}
            </Td>
          ))}
        </Tr>
        <Tr>
          <Td border="2px" borderColor="gray.100" py="1">
            エネルギーLv
          </Td>
          {data?.energyLevel.map((energyLevel, index) => (
            <Td key={index} border="2px" borderColor="gray.100" py="1">
              {energyLevel ?? "-"}
            </Td>
          ))}
        </Tr>
        <Tr>
          <Td border="2px" borderColor="gray.100" py="1">
            ストレス度
          </Td>
          {data?.stressBalance.map((stressBalance, index) => (
            <Td key={index} border="2px" borderColor="gray.100" py="1">
              <StressBalance value={stressBalance} />
            </Td>
          ))}
        </Tr>
        <Tr>
          <Td border="2px" borderColor="gray.100" py="1">
            メモ
          </Td>
          {data?.note.map((note, index) => (
            <Note key={index} note={note} notes={data.note} />
          ))}
        </Tr>
      </DataDisplayTable>
    </Box>
  );
};
