import {createIcon} from "@chakra-ui/react";

export const ArrowUpIcon = createIcon({
    displayName: "ArrowUp",
    defaultProps: {
        color: "#999999",
    },
    viewBox: "0 0 14 14",
    path: (
        <path fillRule="evenodd" clipRule="evenodd"
              d="M8 13C8 13.5523 7.55228 14 7 14C6.44772 14 6 13.5523 6 13V3.41421L1.70711 7.70711C1.31658 8.09763 0.683418 8.09763 0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289L6.29289 0.292893C6.68342 -0.0976315 7.31658 -0.0976315 7.70711 0.292893L13.7071 6.29289C14.0976 6.68342 14.0976 7.31658 13.7071 7.70711C13.3166 8.09763 12.6834 8.09763 12.2929 7.70711L8 3.41421V13Z"
              fill="currentColor"/>

    ),
});
