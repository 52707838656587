import { LoopeIcon, CloseCircleIcon } from "components/app";
import {
  InputGroup,
  Input,
  InputLeftElement,
  InputRightElement,
  Box,
  Popover,
  PopoverBody,
  PopoverContent,
  IconButton,
} from "components/lib";

import {
  PopoverAnchor,
  useDisclosure,
  useOutsideClick,
} from "@chakra-ui/react";
import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import {
  usePatientInteractiveSearchQuery,
  useRemoveQueryCache,
} from "hooks/api/patient/usePatientInteractiveSearchQuery";
import { PatientInteractiveSearchResultList } from "./PatientInteractiveSearchResultList";
import { debounce } from "throttle-debounce";

// 検索回数を間引くために、debounce でラップしています。
const debounedUpdateQuery = debounce(800, false, (callback) => {
  callback();
});

export const PatientInteractiveSearchInput: FC = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const initialFocusRef = useRef<HTMLInputElement>(null);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [query, setQuery] = useState("");
  const {
    data: searchResults,
    refetch,
    isFetching,
  } = usePatientInteractiveSearchQuery(query);
  const removeQueryCache = useRemoveQueryCache();

  useOutsideClick({
    ref,
    handler: () => closeResult(),
  });

  // 再検索
  useEffect(() => {
    if (query === "") {
      closeResult();
    } else {
      debounedUpdateQuery(refetch);
      onOpen();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const closeResult = () => {
    removeQueryCache();
    onClose();
  };

  const handleChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value.trim());
  };

  const handleClearQuery = () => {
    setQuery("");
  };

  return (
    <Box w="380px" minW="380px" ref={ref}>
      <Popover
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialFocusRef}
      >
        <PopoverAnchor>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              children={<LoopeIcon boxSize="6" />}
            />

            <Input
              placeholder="患者名またはIDで検索"
              onChange={handleChangeInput}
              px="10"
              ref={initialFocusRef}
              value={query}
            />

            {query !== "" && (
              <InputRightElement
                children={
                  <IconButton
                    aria-label="クリア"
                    variant="unstyled"
                    onClick={handleClearQuery}
                    icon={<CloseCircleIcon boxSize="6" />}
                  />
                }
              />
            )}
          </InputGroup>
        </PopoverAnchor>

        <PopoverContent w="380px" minW="380px">
          <PopoverBody p="0">
            <PatientInteractiveSearchResultList
              isLoading={isFetching}
              searchResults={searchResults}
            />
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};
