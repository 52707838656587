import { FC } from "react";
import {
  Modal,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Button,
} from "components/lib";
import { AddIcon } from "components/app";
import { useDisclosure } from "@chakra-ui/react";
import { ContactForm as ContactFormType, Hospital } from "types";
import { useToast } from "hooks/useToast";
import { useInvalidateHospitalQuery } from "hooks/api/hospital/useHospitalQuery";
import { useUpdateHospitalMutation } from "hooks/api/hospital/useUpdateHospitalMutation";
import { ContactForm } from "../ContactForm";

type Props = {
  hospital: Hospital;
};

export const CreateContactButton: FC<Props> = ({ hospital }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate, isLoading } = useUpdateHospitalMutation();
  const invalidateHospitalQuery = useInvalidateHospitalQuery();
  const toast = useToast();

  const onSubmit = (data: ContactFormType) => {
    mutate(
      {
        description: hospital.contactInfo.description,
        contacts: [...hospital.contactInfo.contacts, data],
      },
      {
        onSuccess: () => {
          invalidateHospitalQuery();
          toast({ title: "対応窓口を追加しました", status: "success" });
          onClose();
        },
        onError: (e) => {
          toast({
            title: "エラーが発生しました",
            status: "error",
          });
          throw e;
        },
      }
    );
  };

  return (
    <>
      <Button
        variant="outline"
        backgroundColor="white"
        leftIcon={<AddIcon boxSize="5" color="orange.800" />}
        onClick={onOpen}
      >
        対応窓口を追加
      </Button>

      <Modal
        size="5xl"
        isOpen={isOpen}
        onClose={onClose}
        closeOnEsc={!isLoading}
        closeOnOverlayClick={!isLoading}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>対応窓口を追加</ModalHeader>
          <ModalCloseButton />
          <ModalBody p="10">
            <ContactForm onSubmit={onSubmit} isLoading={isLoading} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
