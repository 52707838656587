import { FC } from "react";
import { Box } from "components/lib";
import { CheckIcon } from "components/app";
import { differenceInCalendarDays } from "date-fns";
import { PatientTreatmentRecords } from "types";

type Props = {
  treatmentRecords: PatientTreatmentRecords;
  date: Date;
};

export const DailyTreatmentRecord: FC<Props> = ({ treatmentRecords, date }) => {
  for (let i = treatmentRecords.length - 1; i >= 0; i--) {
    const treatmentRecord = treatmentRecords[i];
    const diff = differenceInCalendarDays(
      date,
      treatmentRecord.lastTreatmentDate
    );

    if (diff === 0) {
      return (
        <Box
          h="100%"
          w="100%"
          backgroundColor="orange.50"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CheckIcon color="orange.800"></CheckIcon>
        </Box>
      );
    } else if (diff >= 1) {
      return (
        <Box fontSize="xs" color="orange.800">
          {diff}日
        </Box>
      );
    }
  }

  return null;
};
