import { alertLabel, alertLevelColorScheme } from "data";
import { Box, Td } from "components/lib";
import { ExclamationCircleIcon, CheckIcon } from "components/app";
import { useDisclosure } from "@chakra-ui/react";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "components/lib";
import { SymptomValue } from "types";
import { FC } from "react";
import { formatDateToString } from "utils";

type Props = {
  symptomName: string;
  value: SymptomValue | null;
  date: Date;
};

export const Symptom: FC<Props> = ({ symptomName, value, date }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClick = () => {
    if (value === null) return;
    onOpen();
  };

  return (
    <Td
      onClick={handleClick}
      cursor={value === null ? "" : "pointer"}
      border="2px"
      borderColor="gray.100"
      backgroundColor={alertLevelColorScheme[value?.grade || -1].secondary}
    >
      {value?.grade === null || value?.grade === undefined ? (
        "-"
      ) : value?.grade === 0 ? (
        <CheckIcon />
      ) : (
        <ExclamationCircleIcon
          boxSize="5"
          color={alertLevelColorScheme[value?.grade || -1].primary}
        />
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody p="10">
            <Box
              fontWeight="bold"
              fontSize="2xl"
              display="flex"
              alignItems="center"
              gap="2"
            >
              <ExclamationCircleIcon
                boxSize="8"
                color={alertLevelColorScheme[value?.grade ?? -1].primary}
              />
              <Box>{symptomName}</Box>
            </Box>

            <Box mt="6" display="flex" fontSize="sm" gap="2">
              <Box>{formatDateToString(date, "MM月dd日")}</Box>
              <Box color={alertLevelColorScheme[value?.grade ?? -1].primary}>
                {alertLabel[value?.grade ?? -1]}
              </Box>
            </Box>

            <Box fontWeight="bold" mt="2">
              {value?.scale}.{value?.text}
            </Box>

            <Box display="flex" justifyContent="center" gap="5" mt="10">
              <Button w="120px" onClick={onClose}>
                閉じる
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Td>
  );
};
