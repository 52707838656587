import { useQuery } from "react-query";
import {
  GetDataByTermsRequest,
  PatientTemperatureRecords,
  GetPatientTemperatureRecordsResponse,
} from "types";
import { get } from "lib/axios";
import { useQueryClient } from "react-query";
import { useEffect } from "react";
import { formatDateToString } from "utils";

const getPatientTemperatureRecords = async (
  patientId: string,
  params: GetDataByTermsRequest
): Promise<PatientTemperatureRecords> => {
  const { data } = await get<GetPatientTemperatureRecordsResponse>(
    `/patients/${patientId}/health_record/temperature/daily`,
    {
      params: {
        from_date: formatDateToString(params.from_date),
        to_date: formatDateToString(params.to_date),
      },
    }
  );

  return data.values.map((data) => {
    return [Date.parse(data.date), data.value];
  });
};

export const usePatientTemperatureRecordsQuery = (
  patientId: string,
  params: GetDataByTermsRequest
) => {
  return useQuery(
    [`healthRecord/temperature`],
    () => {
      return getPatientTemperatureRecords(patientId, params);
    },
    {
      retry: false,
      staleTime: 1000 * 60 * 5,
    }
  );
};

export const useRemoveCacheOnUnMount = () => {
  const queryClient = useQueryClient();

  useEffect(() => {
    return () => {
      queryClient.removeQueries("healthRecord/temperature");
    };
  }, [queryClient]);
};
