import { FC, useEffect } from "react";
import { useForm } from "react-hook-form";
import isEmail from "validator/lib/isEmail";
import { StaffForm as StaffFormType, Staff } from "types";
import {
  Box,
  Button,
  FormControl,
  Checkbox,
  Input,
  FormHelperText,
  FormErrorMessage,
  FormLabel,
  Container,
  Select,
} from "components/lib";

type Props = {
  staff?: Staff;
  isLoading: boolean;
  onSubmit: (data: StaffFormType) => void;
  validEmailDomainList?: string[];
};

export const StaffForm: FC<Props> = ({
  staff,
  onSubmit,
  isLoading,
  validEmailDomainList = [],
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<StaffFormType>({
    defaultValues: {
      isAdmin: false,
      fullName: "",
      jobType: "doctor",
      email: "",
      ...staff,
    },
  });

  useEffect(() => {
    return () => reset();
  }, [reset]);

  const isEdit = staff !== undefined;

  const onSubmitForm = (data: StaffFormType) => {
    onSubmit(data);
  };

  const emailRegistration = isEdit
    ? { defaultValue: staff.email }
    : register("email", {
        required: "必須項目です",
        validate: {
          validEmail: (v) => {
            return isEmail(v) ? undefined : "不正なメールアドレスです";
          },
          validEmailDomain: (v) => {
            const inputedDomain = v.split("@").pop() || "";

            return validEmailDomainList.find(
              (validDomain) => validDomain === inputedDomain
            )
              ? undefined
              : "登録できないメールアドレスです";
          },
        },
      });

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Container maxW="896px">
        <Box display="flex" gap="4">
          <FormControl minW="64px" w="64px">
            <FormLabel>管理者</FormLabel>
            <Box w="100%" pl="4" pt="2.5">
              <Checkbox {...register("isAdmin")} />
            </Box>
          </FormControl>

          <FormControl>
            <FormLabel isRequired>役職</FormLabel>
            <Select {...register("jobType")}>
              <option value="doctor">医師</option>
            </Select>
          </FormControl>

          <FormControl minW="280px" isInvalid={errors.fullName !== undefined}>
            <FormLabel isRequired>氏名</FormLabel>
            <Input
              placeholder="例) 山田　太郎"
              {...register("fullName", {
                required: "必須項目です",
                maxLength: {
                  value: 12,
                  message: "12文字以内で入力してください",
                },
              })}
            />
            <FormHelperText>12文字以内</FormHelperText>
            {errors.fullName && (
              <FormErrorMessage>{errors.fullName.message}</FormErrorMessage>
            )}
          </FormControl>

          <FormControl minW="280px" isInvalid={errors.email !== undefined}>
            <FormLabel isRequired={!isEdit}>メールアドレス</FormLabel>
            <Input
              isDisabled={isEdit}
              placeholder="例) abc@example.com"
              {...emailRegistration}
            />
            <FormHelperText>
              {isEdit
                ? "アドレスを変更するには、再登録が必要です"
                : "メールアドレスは登録後に編集できません"}
            </FormHelperText>
            {errors.email && (
              <FormErrorMessage>{errors.email.message}</FormErrorMessage>
            )}
          </FormControl>
        </Box>

        <Box mt="10" textAlign="center">
          <Button type="submit" w="200px" isLoading={isLoading}>
            保存
          </Button>
        </Box>
      </Container>
    </form>
  );
};
