import { useQuery } from "react-query";
import { GetTagsResponse, Tag } from "types";
import { get } from "lib/axios";
import { AxiosError } from "axios";

const getTags = async (): Promise<Tag[]> => {
  const { data } = await get<GetTagsResponse>(`/tags`);
  return data;
};

export const useTagsQuery = () => {
  return useQuery<Tag[], AxiosError>([`tagList`], () => getTags(), {
    retry: false,
    staleTime: 1000 * 60 * 10,
  });
};
