import { createIcon } from "@chakra-ui/react";

export const CloseCircleIcon = createIcon({
  displayName: "CloseCircleIcon",
  defaultProps: {
    color: "#636369",
  },
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M12 2C10.0222 2 8.0888 2.58649 6.4443 3.68531C4.79981 4.78412 3.51808 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53042 17.6726 4.92894 19.0711C6.32747 20.4696 8.10929 21.422 10.0491 21.8078C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C21.9928 9.35005 20.9369 6.81069 19.0631 4.93689C17.1893 3.06308 14.65 2.0072 12 2ZM15.6682 14.3182C15.823 14.5038 15.9027 14.7405 15.8918 14.982C15.8809 15.2234 15.7801 15.452 15.6092 15.6229C15.4384 15.7937 15.2098 15.8946 14.9683 15.9055C14.7269 15.9164 14.4901 15.8366 14.3046 15.6818L12.0046 13.3773L9.7 15.6818C9.51917 15.8627 9.27392 15.9642 9.01818 15.9642C8.76245 15.9642 8.5172 15.8627 8.33637 15.6818C8.15554 15.501 8.05395 15.2557 8.05395 15C8.05395 14.7443 8.15554 14.499 8.33637 14.3182L10.6364 12.0136L8.33637 9.71364C8.15554 9.53281 8.05395 9.28755 8.05395 9.03182C8.05395 8.77609 8.15554 8.53083 8.33637 8.35C8.5172 8.16917 8.76245 8.06759 9.01818 8.06759C9.27392 8.06759 9.51917 8.16917 9.7 8.35L12.0046 10.6545L14.3046 8.35C14.4901 8.19523 14.7269 8.11545 14.9683 8.12636C15.2098 8.13727 15.4384 8.23807 15.6092 8.40896C15.7801 8.57984 15.8809 8.80844 15.8918 9.04985C15.9027 9.29127 15.823 9.52804 15.6682 9.71364L13.3636 12.0136L15.6682 14.3182Z"
      fill="currentColor"
    />
  ),
});
