import { FC } from "react";
import {
  Box,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "components/lib";
import { format } from "date-fns";
import { ja } from "date-fns/locale";
import { getHolidayColorSchema } from "utils";
import { usePatientDataDisplayTermsContext } from "contexts/PatientDataDisplayTermsContext";

type Props = {
  isLoading: boolean;
};

export const DataDisplayTable: FC<Props> = ({ isLoading, children }) => {
  const { eachDayOfTermsByMonth } = usePatientDataDisplayTermsContext();

  return (
    <TableContainer
      backgroundColor="white"
      maxWidth="100%"
      overflowX="hidden"
      borderRadius="16px"
      mt={4}
    >
      {isLoading ? (
        <Box textAlign="center" p="4">
          <Spinner />
        </Box>
      ) : (
        <Table
          variant="unstyled"
          style={{ tableLayout: "fixed" }}
          height="100%"
        >
          <Thead>
            <Tr>
              <Th w="132px" minW="132px" border="none" />
              {Object.keys(eachDayOfTermsByMonth).map((month, index) => (
                <Th
                  key={index}
                  colSpan={eachDayOfTermsByMonth[month].length}
                  textAlign="left"
                  border="2px"
                  borderColor="gray.100"
                  borderTop="none"
                  overflow="hidden"
                >
                  {month}
                </Th>
              ))}
            </Tr>
          </Thead>

          <Tbody>
            <Tr>
              <Td border="none" />
              {Object.values(eachDayOfTermsByMonth).map((dayList) =>
                dayList.map((day, index) => (
                  <Td
                    key={index}
                    {...getHolidayColorSchema(day)}
                    border="2px"
                    borderColor="gray.100"
                  >
                    {format(day, "d")}
                  </Td>
                ))
              )}
            </Tr>

            <Tr>
              <Td border="none" />
              {Object.values(eachDayOfTermsByMonth).map((dayList) =>
                dayList.map((day, index) => (
                  <Td
                    key={index}
                    {...getHolidayColorSchema(day)}
                    border="2px"
                    borderColor="gray.100"
                  >
                    {format(day, "E", { locale: ja })}
                  </Td>
                ))
              )}
            </Tr>
            {children}
          </Tbody>
        </Table>
      )}
    </TableContainer>
  );
};
