import { FC } from "react";
import {
  Td as ChakraTd,
  TableCellProps as ChakraTableCellProps,
} from "@chakra-ui/react";

export type TableCellProps = ChakraTableCellProps;

export const Td: FC<TableCellProps> = ({ children, ...rest }) => {
  return (
    <ChakraTd
      textAlign="center"
      fontSize="sm"
      py={0}
      px={0}
      border="none"
      {...rest}
    >
      {children}
    </ChakraTd>
  );
};
