import { FC } from "react";
import { ArrowDownIcon , ArrowUpIcon } from "components/app";
import { BoxProps, IconButton, Th } from "components/lib";

export const PatientListHeader: FC<{
  w?: BoxProps["w"];
  pr?: BoxProps["pr"];
  pl?: BoxProps["pl"];
  borderRadius?: BoxProps["borderRadius"];
  sortable?: boolean;
  isSortActive?: boolean;
  order?: string;
  onClick?: () => void;
}> = ({
  w,
  pr,
  pl,
  borderRadius,
  onClick,
  sortable = false,
  isSortActive = false,
  order = '',
  children,
}) => {
  return (
    <Th
      pt="6"
      pb="4"
      px="0"
      pr={pr}
      pl={pl}
      w={w}
      border="none"
      boxSizing="content-box"
      borderRadius={borderRadius}
      backgroundColor="white"
      position="sticky"
      textAlign="left"
      top="0"
      zIndex="2"
    >
      {children}
      {sortable && (
        <IconButton
          size="xs"
          variant="ghost"
          aria-label="sort"
          ml="1"
          isActive={isSortActive}
          onClick={onClick}
          _active={{
            backgroundColor: "red.50",
          }}
          icon={
            !isSortActive?
                <ArrowDownIcon
                    boxSize="4"
                    color="gray.700"
                    width="14px"
                    height="14px"
                />
                : (
            order === 'ASC' ?
              <ArrowUpIcon
                  boxSize="4"
                  color="orange.800"
              />
              :
            <ArrowDownIcon
              boxSize="4"
              color="orange.800"
            />
                )
          }
        />
      )}
    </Th>
  );
};
