import { FC } from "react";
import { FormLabel as ChakraFormLabel, FormLabelProps } from "@chakra-ui/react";
import { Box } from "components/lib";

type Props = { isRequired?: boolean } & FormLabelProps;

export const FormLabel: FC<Props> = ({
  isRequired = false,
  children,
  ...rest
}) => {
  return (
    <ChakraFormLabel fontWeight="bold" fontSize="md" {...rest}>
      <Box display="flex" alignItems="center">
        <Box lineHeight="1.2">{children}</Box>
        {isRequired && (
          <Box
            display="inline-block"
            backgroundColor="red.500"
            color="white"
            fontSize="xs"
            paddingX="1"
            ml="1"
            borderRadius="sm"
          >
            必須
          </Box>
        )}
      </Box>
    </ChakraFormLabel>
  );
};
