import axios, { AxiosRequestConfig } from "axios";
import MockAdapter from "axios-mock-adapter";
import { getAuth } from "firebase/auth";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 5000,
});

const getAuthorizationHeader = async (): Promise<AxiosRequestConfig> => {
  const auth = getAuth();
  const token = await auth.currentUser?.getIdToken();

  if (token === undefined) {
    return {};
  } else {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  }
};

// モック用 インスタンス
export const mockInstance = axios.create();
export const mock = new MockAdapter(mockInstance, {
  delayResponse: 300,
});

export const get = async <T>(url: string, config?: AxiosRequestConfig) => {
  const authHeader = await getAuthorizationHeader();
  return axiosInstance.get<T>(url, { ...config, ...authHeader });
};

export const post = async <T, U = {}>(
  url: string,
  params?: U,
  config?: AxiosRequestConfig
) => {
  const authHeader = await getAuthorizationHeader();
  return axiosInstance.post<T>(url, params, { ...config, ...authHeader });
};

export const put = async <T, U = {}>(
  url: string,
  params?: U,
  config?: AxiosRequestConfig
) => {
  const authHeader = await getAuthorizationHeader();
  return axiosInstance.put<T>(url, params, { ...config, ...authHeader });
};

export const patch = async <T, U = {}>(
  url: string,
  params?: U,
  config?: AxiosRequestConfig
) => {
  const authHeader = await getAuthorizationHeader();
  return axiosInstance.patch<T>(url, params, { ...config, ...authHeader });
};

// "delete" が関数名として許可できないので、"remove" で代替しています。
export const remove = async <T>(url: string, config?: AxiosRequestConfig) => {
  const authHeader = await getAuthorizationHeader();
  return axiosInstance.delete<T>(url, { ...config, ...authHeader });
};
