import {
  createContext,
  useContext,
  FC,
  useState,
  useEffect,
  useCallback,
} from "react";
import {
  getAuth,
  signInWithCustomToken,
  onAuthStateChanged,
  signOut as firebaseSignOut,
} from "firebase/auth";
import { get, post } from "lib/axios";
import {
  GetUserResponse,
  PostSignInResponse,
  PostSignInRequest,
  User,
} from "types";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

const convertUserResponse = (data: GetUserResponse): User => {
  return {
    id: data.id,
    hospitalId: data.hospital_id,
    fullName: data.full_name,
    jobType: data.job_type,
    isAdmin: data.is_admin,
    email: data.email,
    isTemporary: data.is_temporary,
  };
};

/**
 * ユーザーのサインイン状態を管理する Context。
 */
interface Context {
  currentUser?: User;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  activateTemporaryUser: () => void;
}

const AuthContext = createContext<Context | null>(null);

export const AuthContextProvider: FC = ({ children }) => {
  const queryClient = useQueryClient();
  const [isUserInitialized, setIsUserInitialized] = useState(false);
  const [currentUser, setUser] = useState<User | undefined>();
  const navigate = useNavigate();

  const signIn = async (email: string, password: string) => {
    const res = await post<PostSignInResponse, PostSignInRequest>(
      "/auth/sign_in",
      {
        email,
        password,
      }
    );

    const auth = getAuth();
    await signInWithCustomToken(auth, res.data.custom_token);
  };

  const signOut = () => {
    const auth = getAuth();
    return firebaseSignOut(auth);
  };

  const activateTemporaryUser = () => {
    if (currentUser === undefined || !currentUser.isTemporary) return;

    setUser({
      ...currentUser,
      isTemporary: false,
    });
  };

  // 最新のセッション情報で、ユーザー情報を更新する。
  const initializeUser = useCallback(() => {
    const auth = getAuth();

    // firebase の AuthState が変更された時のコールバック
    onAuthStateChanged(auth, async (user) => {
      // queryClient.removeQueries(); // ログアウト時に、cache をすべて削除する。
      // setUser(undefined);
      if (user) {
        const { data: userResponse } = await get<GetUserResponse>("/staffs/me");
        setUser(convertUserResponse(userResponse));
      } else {
        queryClient.removeQueries(); // ログアウト時に、cache をすべて削除する。
        setUser(undefined);
      }
      setIsUserInitialized(true);
    });
  }, [queryClient]);

  useEffect(() => {
    initializeUser();
  }, [initializeUser]);

  useEffect(() => {
    // 仮登録ユーザーの場合は、強制的にパスワード設定ページに遷移させる
    if (currentUser !== undefined && currentUser.isTemporary) {
      navigate("/activateTemporaryUser");
    }
  }, [currentUser, navigate]);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        signIn,
        signOut,
        activateTemporaryUser,
      }}
    >
      {isUserInitialized && children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (context === null) throw Error("Auth context is null.");

  return context;
};
