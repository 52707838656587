import { FC, useEffect } from "react";
import { Box, Container } from "components/lib";
import { Header, HighLevelAlertPatientList } from "components/app";
import { PatientListFilter } from "./PatientListFilter";
import { PatientList } from "./PatientList";
import { PatientInteractiveSearchInput } from "./PatientInteractiveSearchInput";
import { useAuthContext } from "contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { usePatientsQuery } from "hooks/api/patient/usePatientsQuery";
import { useIsFirstRender } from "hooks/useIsFirstRender";
import { usePatientSearchContext } from "contexts/PatientSearchContext";
import { Title } from "components/app";
import { useScreenViewLogger } from "hooks/useScreenViewLogger";
import { AlertIconExplanationButton } from "./AlertIconExplanationButton";

const PatientPageContent: FC = () => {
  const isFirstRender = useIsFirstRender();
  const { searchParams } = usePatientSearchContext();
  const { refetch } = usePatientsQuery();

  // 検索条件が変わるたびに再検索する(マウント時には実行しない)
  useEffect(() => {
    if (!isFirstRender) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useScreenViewLogger();

  return (
    <Box display="flex" flexDirection="column" h="100%">
      <Title title="患者一覧" />
      <Header>
        <HighLevelAlertPatientList />
      </Header>

      <Container maxW="1440px" px={0} flex={1} overflow="hidden">
        <Box display="flex" h="100%">
          <Box backgroundColor="white" maxH="100%" overflow="scroll">
            <PatientListFilter />
          </Box>

          <Box
            flex={1}
            px={6}
            py={10}
            maxH="100%"
            display="flex"
            flexDirection="column"
          >
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" gap={[4, 4, 4, 4, 8]}>
                <Box as="h1" fontSize="4xl" lineHeight={1}>
                  患者一覧
                </Box>
                <AlertIconExplanationButton />
              </Box>
              <PatientInteractiveSearchInput />
            </Box>

            <Box flex="1" overflowY="hidden" mt="5">
              <PatientList />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export const PatientsPage: FC = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuthContext();

  useEffect(() => {
    if (currentUser === undefined) {
      navigate("/login");
    }
  }, [currentUser, navigate]);

  if (currentUser === undefined) return null;

  return <PatientPageContent />;
};
