import { ChevronLeftIcon, ChevronRightIcon } from "components/app";
import { Box, Button } from "components/lib";
import {
  addMonths,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import {
  format,
  differenceInCalendarDays,
  differenceInCalendarMonths,
} from "date-fns";
import { FC, useState } from "react";
import { IconButton } from "..";

const formatedDayList = ["日", "月", "火", "水", "木", "金", "土"];

const PickerCell: FC = ({ children }) => {
  return (
    <Box position="relative" paddingBottom="100%">
      <Box
        position="absolute"
        left="0"
        top="0"
        right="0"
        bottom="0"
        display="flex"
        justifyContent="center"
        alignItems="center"
        px={1}
        py={1}
      >
        {children}
      </Box>
    </Box>
  );
};

type DayPickerProps = {
  selectedDate: Date;
  onSelect: (date: Date) => void;
};

export const DayPicker: FC<DayPickerProps> = ({ selectedDate, onSelect }) => {
  const [displayDate, setDisplayDate] = useState<Date>(selectedDate);

  // 日付のリストを作る
  const monthStartDay = startOfMonth(displayDate);
  const monthEndDay = endOfMonth(displayDate);
  const calendarStartDay = startOfWeek(monthStartDay);
  const calendarEndDay = endOfWeek(monthEndDay);
  const calendarDayList = eachDayOfInterval({
    start: calendarStartDay,
    end: calendarEndDay,
  });

  const getButtonColor = (day: Date) => {
    if (differenceInCalendarDays(day, selectedDate) === 0) {
      return {
        backgroundColor: "orange.800",
        color: "white",
      };
    } else if (differenceInCalendarMonths(day, displayDate) !== 0) {
      return {
        backgroundColor: "white",
        color: "gray.700",
      };
    }
  };

  const showPrevMonth = () => {
    setDisplayDate(addMonths(displayDate, -1));
  };

  const showNextMonth = () => {
    setDisplayDate(addMonths(displayDate, +1));
  };

  const handleSelectDate = (date: Date) => {
    onSelect(date);
  };

  return (
    <Box px="4" py="4">
      <Box display="flex" alignItems="center">
        <Box flex="1" fontWeight="bold">
          {format(displayDate, "yyyy年MM月")}
        </Box>
        <Box display="flex" gap="4">
          <IconButton
            onClick={showPrevMonth}
            variant="ghost"
            size="sm"
            aria-label="前月"
            icon={<ChevronLeftIcon color="black" />}
          />
          <IconButton
            onClick={showNextMonth}
            variant="ghost"
            size="sm"
            aria-label="次月"
            icon={<ChevronRightIcon color="black" />}
          />
        </Box>
      </Box>

      <Box display="grid" gridTemplateColumns="repeat(7, 1fr)" mt="4">
        {formatedDayList.map((day, index) => (
          <PickerCell key={index}>{day}</PickerCell>
        ))}
        {calendarDayList.map((day, index) => (
          <PickerCell key={index}>
            <Button
              flex={1}
              display="inline-block"
              size="sm"
              variant="unstyled"
              borderRadius="50%"
              {...getButtonColor(day)}
              onClick={() => handleSelectDate(day)}
            >
              {format(day, "d")}
            </Button>
          </PickerCell>
        ))}
      </Box>
    </Box>
  );
};
