import {createIcon} from "@chakra-ui/react";

export const ArrowDownIcon = createIcon({
    displayName: "ArrowDown",
    defaultProps: {
        color: "#999999",
    },
    viewBox: "0 0 14 14",
    path: (
        <path fillRule="evenodd" clipRule="evenodd"
              d="M8 1C8 0.447715 7.55228 0 7 0C6.44772 0 6 0.447715 6 1V10.5858L1.70711 6.29289C1.31658 5.90237 0.683418 5.90237 0.292893 6.29289C-0.0976311 6.68342 -0.0976311 7.31658 0.292893 7.70711L6.29289 13.7071C6.68342 14.0976 7.31658 14.0976 7.70711 13.7071L13.7071 7.70711C14.0976 7.31658 14.0976 6.68342 13.7071 6.29289C13.3166 5.90237 12.6834 5.90237 12.2929 6.29289L8 10.5858V1Z"
              fill="currentColor"/>
    ),
});
