import { createContext, useContext, FC, useState, useMemo } from "react";
import { addMonths, addWeeks, eachDayOfInterval, format } from "date-fns";
import { DateDisplayTerms } from "types";

// 患者詳細のデータ表示期間を管理する context.
interface Context {
  displayStartDate: Date; // 表示開始日
  // setDisplayStartDate: (date: Date) => void;
  displayEndDate: Date; // 表示終了日
  setDisplayEndDate: (date: Date) => void;
  displayTerms: DateDisplayTerms; // 表示する期間
  eachDayOfTerms: Date[];
  eachDayOfTermsByMonth: { [key: string]: Date[] }; // 月別にまとめた日付のリスト
  setDisplayTerms: (terms: DateDisplayTerms) => void;
}

const PatientDataDisplayTermsContext = createContext<Context | null>(null);

export const PatientDataDisplayTermsContextProvider: FC = ({ children }) => {
  const [displayTerms, setDisplayTerms] = useState<DateDisplayTerms>("3weeks");
  const [displayEndDate, setDisplayEndDate] = useState<Date>(
    new Date()
  );

  const displayStartDate = useMemo(() => {
    switch (displayTerms) {
      case "3weeks":
        return addWeeks(displayEndDate, -3);
      case "3months":
        return addMonths(displayEndDate, -3);
      case "6months":
        return addMonths(displayEndDate, -6);
    }
  }, [displayEndDate, displayTerms]);

  const eachDayOfTerms = useMemo(() => {
    return eachDayOfInterval({
      start: displayStartDate,
      end: displayEndDate,
    });
  }, [displayStartDate, displayEndDate]);

  //from で指定された日付~ terms の期間の日付を、月別にまとめて返す。
  const eachDayOfTermsByMonth = useMemo(() => {
    let result: { [key: string]: Date[] } = {};

    eachDayOfTerms.forEach((day) => {
      const key = format(day, "yyyy年MM月");
      if (result[key] === undefined) {
        result[key] = [day];
      } else {
        result[key].push(day);
      }
    });

    return result;
  }, [eachDayOfTerms]);

  return (
    <PatientDataDisplayTermsContext.Provider
      value={{
        displayStartDate,
        displayTerms,
        displayEndDate,
        eachDayOfTerms,
        eachDayOfTermsByMonth,
        // setDisplayStartDate,
        setDisplayEndDate,
        setDisplayTerms,
      }}
    >
      {children}
    </PatientDataDisplayTermsContext.Provider>
  );
};

export const usePatientDataDisplayTermsContext = () => {
  const context = useContext(PatientDataDisplayTermsContext);

  if (context === null) throw Error("PatientDataDisplayTermsContext is null.");

  return context;
};
