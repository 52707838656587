import { FC } from "react";
import { Box, Link } from "components/lib";
import { Spinner } from "@chakra-ui/react";
import { Link as ReactRouterLink } from "react-router-dom";
import { PatientInteractiveSerchResult } from "types";

type Props = {
  isLoading: boolean;
  searchResults?: PatientInteractiveSerchResult[];
};

export const PatientInteractiveSearchResultList: FC<Props> = ({
  isLoading,
  searchResults,
}) => {
  if (isLoading || searchResults === undefined)
    return (
      <Box textAlign="center" p="4">
        <Spinner />
      </Box>
    );

  if (searchResults.length <= 0) {
    return (
      <Box textAlign="center" p="4" color="gray.700">
        該当する患者はいません
      </Box>
    );
  }

  return (
    <Box as="ul" maxH="50vh" overflow="scroll">
      {searchResults.map((result) => (
        <Box key={result.id} as="li">
          <Link
            as={ReactRouterLink}
            to={`/patientDetail/${result.id}`}
            p="4"
            display="flex"
            alignItems="center"
            color="black"
            _hover={{
              textDecoration: "none",
              backgroundColor: "orange.50",
            }}
          >
            <Box flex="1">{result.name}</Box>
            <Box fontSize="xs" color="gray.700">
              {result.patientCode}
            </Box>
          </Link>
        </Box>
      ))}
    </Box>
  );
};
