import { Box } from "components/lib";
import { FC } from "react";
import { usePatientDataDisplayTermsContext } from "contexts/PatientDataDisplayTermsContext";
import { format } from "date-fns";
import { ja } from "date-fns/locale";

export const DisplayTerms: FC = () => {
  const { displayStartDate, displayEndDate } =
    usePatientDataDisplayTermsContext();
  return (
    <Box
      display="inline-block"
      backgroundColor="white"
      px="4"
      py="2"
      mt="8"
      borderRadius="16px"
    >
      <Box display="flex" alignItems="center" gap="2">
        <Box fontWeight="bold">
          {format(displayStartDate, "yyyy年MM月dd日(E)", { locale: ja })}
        </Box>
        <Box>〜</Box>
        <Box fontWeight="bold">
          {format(displayEndDate, "yyyy年MM月dd日(E)", { locale: ja })}
        </Box>
        <Box>を表示中</Box>
      </Box>
    </Box>
  );
};
