import { createIcon } from "@chakra-ui/react";

export const CheckInCircleIcon = createIcon({
  displayName: "CheckInCircle",
  defaultProps: {
    color: "green.400",
  },
  viewBox: "0 0 80 80",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.8181 13.5243C42.9435 12.788 49.1347 14.1985 54.3367 17.5153C55.889 18.505 57.9497 18.049 58.9394 16.4967C59.9291 14.9444 59.4731 12.8837 57.9208 11.894C51.4183 7.74802 43.6793 5.98496 36.0226 6.90524C28.3659 7.82552 21.2651 11.3722 15.9302 16.941C10.5953 22.5097 7.35628 29.756 6.76511 37.4452C6.17395 45.1343 8.26719 52.7906 12.6882 59.1094C17.1091 65.4282 23.5843 70.0186 31.0104 72.0985C38.4364 74.1784 46.3539 73.6191 53.4138 70.5158C60.4737 67.4125 66.2392 61.9573 69.7279 55.0797C73.2166 48.2021 74.2126 40.3276 72.5463 32.7979C72.1485 31.0005 70.3689 29.8658 68.5714 30.2636C66.774 30.6614 65.6393 32.441 66.0371 34.2384C67.3702 40.2621 66.5733 46.5617 63.7824 52.0638C60.9914 57.5659 56.3791 61.9301 50.7311 64.4127C45.0832 66.8953 38.7492 67.3428 32.8084 65.6789C26.8675 64.015 21.6874 60.3427 18.1506 55.2876C14.6138 50.2325 12.9392 44.1075 13.4122 37.9562C13.8851 31.8049 16.4763 26.0079 20.7442 21.5529C25.0121 17.0978 30.6928 14.2605 36.8181 13.5243ZM72.3574 19.024C73.6592 17.7223 73.6592 15.6117 72.3574 14.31C71.0557 13.0082 68.9451 13.0082 67.6434 14.31L38.1594 43.794L28.8364 35.8028C27.4386 34.6047 25.3343 34.7666 24.1362 36.1644C22.9381 37.5621 23.1 39.6664 24.4977 40.8645L36.1644 50.8645C37.4869 51.9981 39.4591 51.9223 40.6907 50.6907L72.3574 19.024Z"
      fill="currentColor"
    />
  ),
});
