import { Tr, Td } from "components/lib";
import { FC, useEffect } from "react";
import { usePatientDataDisplayTermsContext } from "contexts/PatientDataDisplayTermsContext";
import {
  usePatientTreatmentRecordsQuery,
  useRemoveCacheOnUnMount,
} from "hooks/api/patient/usePatientTreatmentRecordsQuery";
import { useIsFirstRender } from "hooks/useIsFirstRender";
import { DailyTreatmentRecord } from "../DailyTreatmentRecord";
import { useParams } from "react-router";

export const TreatmentRecordList: FC = () => {
  const { patientId = "" } = useParams<{ patientId: string }>();
  const isFirstRender = useIsFirstRender();
  const { eachDayOfTerms, displayStartDate, displayEndDate } =
    usePatientDataDisplayTermsContext();
  const { data: treatmentRecords = [], refetch } =
    usePatientTreatmentRecordsQuery(patientId, {
      from_date: displayStartDate,
      to_date: displayEndDate,
    });

  useEffect(() => {
    if (!isFirstRender) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayStartDate, displayEndDate]);

  useRemoveCacheOnUnMount();

  return (
    <Tr minH={8}>
      <Td border="2px" borderColor="gray.100" py="1" backgroundColor="gray.400">
        投与開始/経過
      </Td>
      {eachDayOfTerms.map((day, index) => (
        <Td
          key={index}
          border="2px"
          borderColor="gray.100"
          backgroundColor="gray.400"
        >
          <DailyTreatmentRecord
            treatmentRecords={treatmentRecords}
            date={day}
          />
        </Td>
      ))}
    </Tr>
  );
};
