import { Header, Title } from "components/app";
import { Box, Button, Container } from "components/lib";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useScreenViewLogger } from "hooks/useScreenViewLogger";

export const NotFoundPage: FC = () => {
  const navigate = useNavigate();
  useScreenViewLogger();

  return (
    <Box>
      <Title title="404" />
      <Header />
      <Box mt={16}>
        <Container mt="8" textAlign="center">
          <Box color="gray.700" fontSize="3xl">
            404 Error
          </Box>
          <Box fontSize="3xl" mt="6">
            お探しのページは見つかりません
          </Box>

          <Box as="p" mt="8">
            お探しのページは一時的にアクセスできない状況にあるか、
            <br />
            移動もしくは削除された可能性があります。
          </Box>

          <Box mt="8">
            <Button minW="200px" onClick={() => navigate("/")}>
              トップへ戻る
            </Button>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};
