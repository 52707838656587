import { useMutation } from "react-query";
import { post } from "lib/axios";
import { AxiosError } from "axios";
import { PostResetPasswordRequest } from "types";

const resetPassword = async (
  params: PostResetPasswordRequest
): Promise<void> => {
  await post<undefined, PostResetPasswordRequest>(
    "/auth/reset_password",
    params
  );
};

export const useResetPasswordMutation = () => {
  return useMutation<void, AxiosError, PostResetPasswordRequest>((params) => {
    return resetPassword(params);
  });
};
