import { useQuery } from "react-query";
import {
  GetDataByTermsRequest,
  PatientBloodPressureRecords,
  GetPatientBloodPressureRecordsResponse,
} from "types";
import { get } from "lib/axios";
import { useQueryClient } from "react-query";
import { useEffect } from "react";
import { formatDateToString } from "utils";

const getPatientBloodPressureRecords = async (
  patientId: string,
  params: GetDataByTermsRequest
): Promise<PatientBloodPressureRecords> => {
  const { data } = await get<GetPatientBloodPressureRecordsResponse>(
    `/patients/${patientId}/health_record/blood_pressure/daily`,
    {
      params: {
        from_date: formatDateToString(params.from_date),
        to_date: formatDateToString(params.to_date),
      },
    }
  );

  return data.values.map((data) => {
    const v = data.value === null ? [null, null] : data.value;
    return [Date.parse(data.date), v[0], v[1]];
  });
};

export const usePatientBloodPressureRecordsQuery = (
  patientId: string,
  params: GetDataByTermsRequest
) => {
  return useQuery(
    ["healthRecord/bloodPressure"],
    () => {
      return getPatientBloodPressureRecords(patientId, params);
    },
    {
      retry: false,
      staleTime: 1000 * 60 * 5,
    }
  );
};

export const useRemoveCacheOnUnMount = () => {
  const queryClient = useQueryClient();

  useEffect(() => {
    return () => {
      queryClient.removeQueries("healthRecord/bloodPressure");
    };
  }, [queryClient]);
};
