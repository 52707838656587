import { FC } from "react";
import { Link as ChakraLink, LinkProps } from "@chakra-ui/react";

type Props = LinkProps & {
  to?: string;
};

export const Link: FC<Props> = ({ children, ...rest }) => {
  return (
    <ChakraLink color="orange.800" {...rest}>
      {children}
    </ChakraLink>
  );
};
