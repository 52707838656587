import { useQuery } from "react-query";
import {
  GetDataByTermsRequest,
  PatientSleepRecords,
  GetPatientSleepRecordsResponse,
} from "types";
import { get } from "lib/axios";
import { useQueryClient } from "react-query";
import { useEffect } from "react";
import { formatDateToString } from "utils";

const getPatientSleepRecords = async (
  patientId: string,
  params: GetDataByTermsRequest
): Promise<PatientSleepRecords> => {
  const { data } = await get<GetPatientSleepRecordsResponse>(
    `/patients/${patientId}/health_record/sleep/daily`,
    {
      params: {
        from_date: formatDateToString(params.from_date),
        to_date: formatDateToString(params.to_date),
      },
    }
  );

  return data.values.map((data) => {
    return [
      Date.parse(data.date),
      data.value === null ? null : data.value / 60,
    ];
  });
};

export const usePatientSleepRecordsQuery = (
  patientId: string,
  params: GetDataByTermsRequest
) => {
  return useQuery(
    [`healthRecord/sleep`],
    () => {
      return getPatientSleepRecords(patientId, params);
    },
    {
      retry: false,
      staleTime: 1000 * 60 * 5,
    }
  );
};

export const useRemoveCacheOnUnMount = () => {
  const queryClient = useQueryClient();

  useEffect(() => {
    return () => {
      queryClient.removeQueries("healthRecord/sleep");
    };
  }, [queryClient]);
};
