import { FC, useMemo } from "react";
import { LeafIcon, ThunderIcon } from "components/app";
import { Box } from "components/lib";

type Props = {
  value: number | null;
};

export const StressBalance: FC<Props> = ({ value }) => {
  const Icon = useMemo(() => {
    switch (value) {
      case 1:
      case 2:
      case 3:
        return <LeafIcon />;
      case 4:
        return <ThunderIcon color="blue.300" />;
      case 5:
        return <ThunderIcon />;
      default:
        return <Box>-</Box>;
    }
  }, [value]);

  const color = useMemo(() => {
    switch (value) {
      case 1:
      case 2:
      case 3:
        return "green.400";
      case 4:
        return "blue.300";
      case 5:
        return "yellow.800";
      default:
        return "black";
    }
  }, [value]);

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      {Icon}
      <Box color={color} fontSize="xs">
        {value}
      </Box>
    </Box>
  );
};
