import { useQuery } from "react-query";
import {
  PatientDailyAlertDetail,
  GetPatientDailyAlertDetailsResponse,
} from "types";
import { get } from "lib/axios";
import { useQueryClient } from "react-query";
import { useEffect } from "react";

const getPatientDailyAlertDetails = async (
  patientId: string,
  date: string
): Promise<PatientDailyAlertDetail[]> => {
  const { data } = await get<GetPatientDailyAlertDetailsResponse>(
    `patients/${patientId}/alerts_per_day`,
    {
      params: {
        date,
      },
    }
  );

  return data.map((data) => ({
    alertKey: data.alert_key,
    alertLevel: data.alert_level,
    title: data.title,
    description: data.description,
    updatedAt: data.updated_at,
  }));
};

export const usePatientDailyAlertDetailsQuery = (
  patientId: string,
  date: string
) => {
  return useQuery(
    [`patientDailyAlertDetails`],
    () => {
      return getPatientDailyAlertDetails(patientId, date);
    },
    {
      retry: false,
      staleTime: 1000 * 60 * 5,
      enabled: false,
    }
  );
};

export const useRemoveCacheOnUnMount = () => {
  const queryClient = useQueryClient();

  return useEffect(() => {
    return () => {
      queryClient.removeQueries("patientDailyAlertDetails");
    };
  }, [queryClient]);
};
