import { FC, useState, useEffect } from "react";
import { Box, Button, Container } from "components/lib";
import { Header, CheckInCircleIcon, Title } from "components/app";
import { useNavigate } from "react-router";
import { useAuthContext } from "contexts/AuthContext";
import { EditPasswordForm } from "components/app";
import { useToast } from "hooks/useToast";
import { useSearchParams } from "react-router-dom";
import { useResetPasswordMutation } from "hooks/api/auth/useResetPasswordMutation";
import { useScreenViewLogger } from "hooks/useScreenViewLogger";

export const EditPasswordPage: FC = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuthContext();
  const [resetPasswordFnished, setResetPasswordFnished] = useState(false);
  const [searchParams] = useSearchParams();
  const { mutate, isLoading } = useResetPasswordMutation();
  const toast = useToast();

  useEffect(() => {
    if (currentUser !== undefined) {
      navigate("/");
    }
  }, [currentUser, navigate]);

  useScreenViewLogger();

  const handleSubmit = (password: string, confirmationPassword: string) => {
    const token = searchParams.get("token") ?? "";
    mutate(
      {
        password: password,
        password_confirmation: confirmationPassword,
        token: token,
      },
      {
        onSuccess: () => {
          setResetPasswordFnished(true);
        },
        onError: (e) => {
          toast({
            title: "エラーが発生しました",
            status: "error",
          });
          throw e;
        },
      }
    );
  };

  return (
    <Box>
      <Title title="新規パスワード登録" />
      <Header />
      <Box mt={16}>
        {resetPasswordFnished ? (
          <Box>
            <Box as="h1" fontSize="4xl" textAlign="center">
              パスワード再設定完了
            </Box>
            <Box mt="20" textAlign="center">
              <CheckInCircleIcon boxSize="80px" />
            </Box>
            <Box as="p" mt="9" textAlign="center">
              パスワードの再設定が完了しました。
            </Box>

            <Box mt="100px" textAlign="center">
              <Button
                w="400px"
                variant="outline"
                backgroundColor="white"
                onClick={() => navigate("/login")}
              >
                ログイン
              </Button>
            </Box>
          </Box>
        ) : (
          <Box>
            <Box textAlign="center" as="h1" fontSize="4xl">
              新規パスワード登録
            </Box>

            <Box as="p" mt="6" textAlign="center">
              新しいパスワードを入力して再設定を完了してください
            </Box>

            <Container maxW="25rem" mt="14">
              <EditPasswordForm onSubmit={handleSubmit} isLoading={isLoading} />
            </Container>
          </Box>
        )}
      </Box>
    </Box>
  );
};
