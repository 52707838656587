import React, { FC } from "react";
import {
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Box,
} from "components/lib";
import {
  AlertLevelBadge,
  ExclamationCircleIcon,
  QuestionIcon,
} from "components/app";
import { Divider } from "@chakra-ui/react";

const ListItem: FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Box as="li" display="flex" alignItems="center" py="2" gap="2">
      {children}
    </Box>
  );
};

export const AlertIconExplanationButton: FC = () => {
  return (
    <Popover placement="bottom-start">
      <PopoverTrigger>
        <Button
          variant="link"
          size="sm"
          leftIcon={<QuestionIcon boxSize="5" />}
        >
          アイコンの見方
        </Button>
      </PopoverTrigger>

      <PopoverContent p="8" w="32rem">
        <PopoverBody p="0">
          <Box as="ul">
            <ListItem>
              <ExclamationCircleIcon boxSize="6" />
              <Box position="relative" top="-1px">
                レベル3.4のアラートが現時点で出ている場合に表示
              </Box>
            </ListItem>
          </Box>
          <Divider my="2" />
          <Box as="ul">
            <ListItem>
              <AlertLevelBadge alertLevel={4} karteStatus={0} />
              最新のマイカルテの開始日〜今日までの注意レベルが4
            </ListItem>
            <ListItem>
              <AlertLevelBadge alertLevel={3} karteStatus={0} />
              最新のマイカルテの開始日〜今日までの注意レベルが3
            </ListItem>
            <ListItem>
              <AlertLevelBadge alertLevel={2} karteStatus={0} />
              最新のマイカルテの開始日〜今日までの注意レベルが2
            </ListItem>
            <ListItem>
              <AlertLevelBadge alertLevel={1} karteStatus={0} />
              最新のマイカルテの開始日〜今日までの注意レベルが1
            </ListItem>
            <ListItem>
              <AlertLevelBadge alertLevel={0} karteStatus={0} />
              最新のマイカルテの開始日〜今日までの注意レベルが0
            </ListItem>
            <ListItem>
              <AlertLevelBadge alertLevel={-1} karteStatus={1} />
              マイカルテ作成済だが開始日〜今日までの記録なし
            </ListItem>
            <ListItem>
              <AlertLevelBadge alertLevel={-1} karteStatus={0} />
              マイカルテ未作成
            </ListItem>
            <ListItem>
              <AlertLevelBadge alertLevel={-1} karteStatus={-1} />
              マイカルテ作成不可
            </ListItem>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
