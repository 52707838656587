import { FC } from "react";
import {
  Th as ChakraTh,
  TableColumnHeaderProps as ChakraTableColumnHeaderProps,
} from "@chakra-ui/react";

export type TableColumnHeaderProps = ChakraTableColumnHeaderProps;

export const Th: FC<TableColumnHeaderProps> = ({ children, ...rest }) => {
  return (
    <ChakraTh
      fontSize="sm"
      fontWeight="bold"
      px="4"
      py="2"
      color="black"
      border="1px"
      borderColor="gray.400"
      textAlign="center"
      {...rest}
    >
      {children}
    </ChakraTh>
  );
};
