import { DateDisplayTerms, PatientDailyAlertSummary } from "types";
import { getXaxisIntervalByTerms } from "utils/chart";

export const getCountChartOptions = (
  alertSummaries: PatientDailyAlertSummary[],
  terms: DateDisplayTerms
) => {
  if (alertSummaries.length <= 0) return undefined;

  const data = alertSummaries.map((summary) => [
    Date.parse(summary.date),
    summary.count,
  ]);

  const options: Highcharts.Options = {
    chart: {
      spacingTop: 0,
      spacingBottom: 27,
      spacingLeft: 0,
      spacingRight: 0,
      height: 180,
    },
    title: {
      text: "",
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        type: "line",
        data,
        color: "#999",
        zIndex: 99,
        lineWidth: 4,
        marker: {
          enabled: true,
        },
        states: {
          inactive: {
            enabled: false,
          },
          hover: {
            halo: null,
            enabled: false,
          },
        },
      },
    ],
    xAxis: {
      title: undefined,
      type: "datetime",
      startOnTick: true,
      minPadding: 0,
      tickLength: 36,
      gridLineWidth: 1,
      labels: {
        enabled: false,
      },
      opposite: true,
      lineColor: "#e5e5e5",
      tickColor: "#e5e5e5",
      tickPositioner: function () {
        const newTicks = [],
          min = this.min || 0,
          max = this.max || 0,
          interval = getXaxisIntervalByTerms(terms);

        for (let i = min; i <= max; i += interval) {
          newTicks.push(i);
        }

        return newTicks;
      },
    },
    yAxis: [
      {
        title: {
          useHTML: true,
          text: '<div style="width: 133px;">件数</div>',
          margin: 0,
          rotation: 0,
          align: "high",
          offset: 0,
          y: -10,
          x: 16,
          style: {
            fontSize: "14px",
            color: "#000",
          },
        },
        tickAmount: 4,
        labels: {
          useHTML: true,
          x: -8,
          style: {
            fontSize: "14px",
            color: "#636369",
          },
        },
      },
    ],
    tooltip: {
      enabled: false,
    },
  };

  return options;
};
