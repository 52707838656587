import { FC, useState } from "react";
import {
  Input,
  FormControl,
  FormLabel,
  Button,
  FormErrorMessage,
  FormHelperText,
} from "components/lib";
import { useForm } from "react-hook-form";
import { useToast } from "hooks/useToast";

type Props = {
  onSubmit: (password: string, confirmationPassword: string) => void;
  isLoading: boolean;
};

export const EditPasswordForm: FC<Props> = ({ onSubmit, isLoading }) => {
  const toast = useToast();
  const [hasError, setHasError] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      confirmedPassword: "",
    },
  });

  const onFormSubmit = (data: {
    password: string;
    confirmedPassword: string;
  }) => {
    if (data.password !== data.confirmedPassword) {
      setHasError(true);
      toast({
        title: "パスワードが一致しません",
        status: "error",
      });

      return;
    }

    onSubmit(data.password, data.confirmedPassword);
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)} noValidate>
      <FormControl isInvalid={errors.password !== undefined || hasError}>
        <FormLabel htmlFor="password">新しいパスワード</FormLabel>
        <Input
          {...register("password", {
            required: "必須項目です",
            minLength: {
              value: 8,
              message: "8文字以内で入力してください",
            },
          })}
          onFocus={() => setHasError(false)}
          id="password"
          type="password"
        />
        <FormHelperText>英数字8文字以上</FormHelperText>

        {errors.password && (
          <FormErrorMessage>{errors.password.message}</FormErrorMessage>
        )}
      </FormControl>

      <FormControl
        mt={10}
        isInvalid={errors.confirmedPassword !== undefined || hasError}
      >
        <FormLabel htmlFor="confirmedPassword">
          新しいパスワード(確認用)
        </FormLabel>
        <Input
          {...register("confirmedPassword", { required: "必須項目です" })}
          onFocus={() => setHasError(false)}
          id="confirmedPassword"
          type="password"
        />

        {errors.confirmedPassword && (
          <FormErrorMessage>
            {errors.confirmedPassword.message}
          </FormErrorMessage>
        )}
      </FormControl>

      <Button
        type="submit"
        isFullWidth
        size="lg"
        mt={10}
        colorScheme="orange"
        isLoading={isLoading}
      >
        送信
      </Button>
    </form>
  );
};
