import { FC } from "react";
import {
  Modal,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  IconButton,
} from "components/lib";
import { PencilIcon } from "components/app";
import { useDisclosure } from "@chakra-ui/react";
import { Hospital, Contact, ContactForm as ContactFormType } from "types";
import { useToast } from "hooks/useToast";
import { useInvalidateHospitalQuery } from "hooks/api/hospital/useHospitalQuery";
import { useUpdateHospitalMutation } from "hooks/api/hospital/useUpdateHospitalMutation";
import { ContactForm } from "../ContactForm";

type Props = {
  hospital: Hospital;
  contact: Contact;
};

export const EditContactIconButton: FC<Props> = ({ hospital, contact }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate, isLoading } = useUpdateHospitalMutation();
  const invalidateHospitalQuery = useInvalidateHospitalQuery();
  const toast = useToast();
  const onSubmit = (data: ContactFormType) => {
    mutate(
      {
        description: hospital.contactInfo.description,
        contacts: hospital.contactInfo.contacts.map((_contact) => {
          if (_contact.id === contact.id) {
            return data;
          } else {
            return _contact;
          }
        }),
      },
      {
        onSuccess: () => {
          invalidateHospitalQuery();
          toast({ title: "対応窓口を変更しました", status: "success" });
          onClose();
        },
      }
    );
  };

  return (
    <>
      <IconButton
        aria-label="編集"
        variant="ghost"
        backgroundColor="white"
        icon={<PencilIcon boxSize="6" color="orange.800" />}
        onClick={onOpen}
      />

      <Modal
        size="5xl"
        isOpen={isOpen}
        onClose={onClose}
        closeOnEsc={!isLoading}
        closeOnOverlayClick={!isLoading}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>対応窓口を変更</ModalHeader>
          <ModalCloseButton />
          <ModalBody p="10">
            <ContactForm
              contact={contact}
              onSubmit={onSubmit}
              isLoading={isLoading}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
