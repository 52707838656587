import { useQuery } from "react-query";
import { GetPatientsResponse, Patient, PatientSearchParams } from "types";
import { get } from "lib/axios";
import Qs from "query-string";
import { usePatientSearchContext } from "contexts/PatientSearchContext";

// API が受け取るパラメーターに変換する。
const convertSearchParams = (searchParams: PatientSearchParams) => {
  return {
    tag_ids: searchParams.tagIds,
    treatment_types: searchParams.treatmentTypes,
    disease_types: searchParams.diseaseTypes,
    sort_keys: searchParams.sortKeys,
    orders: searchParams.orderTypes,
  };
};

const getPatients = async (
  searchParams: PatientSearchParams
): Promise<Patient[]> => {
  const apiParams = convertSearchParams(searchParams);
  const { data } = await get<GetPatientsResponse>("/patients", {
    params: apiParams,
    paramsSerializer: (params) => {
      return Qs.stringify(params);
    },
  });

  return data.results.map((patient) => ({
    id: patient.id,
    patientCode: patient.patient_code,
    firstName: patient.first_name,
    lastName: patient.last_name,
    alertLevel: patient.alert_level,
    tags: patient.tags,
    diseaseType: patient.disease_type,
    treatmentType: patient.treatment_type,
    isRelapse: patient.is_relapse,
    chemotherapyRound: patient.chemotherapy_round,
    lastTreatmentDate: patient.last_treatment_date,
    karteStatus: patient.karte_status,
    lastAlertedDate: patient.last_alerted_date,
    age: patient.age,
    isHighlighted: patient.is_highlighted,
  }));
};

export const usePatientsQuery = () => {
  const { searchParams } = usePatientSearchContext();

  return useQuery(
    [`patientList`],
    () => {
      return getPatients(searchParams);
    },
    {
      retry: false,
      staleTime: 1000 * 60 * 5,
    }
  );
};
