import { useMutation } from "react-query";
import { PatchStaffResponse, Staff, StaffForm, PatchStaffRequest } from "types";
import { patch } from "lib/axios";
import { AxiosError } from "axios";

const updateStaff = async (
  staffId: string,
  params: PatchStaffRequest
): Promise<Staff> => {
  const { data } = await patch<PatchStaffResponse, PatchStaffRequest>(
    `/staffs/${staffId}`,
    params
  );

  return {
    id: data.id,
    hospitalId: data.hospital_id,
    fullName: data.full_name,
    jobType: data.job_type,
    isAdmin: data.is_admin,
    isSuperAdmin: data.is_super_admin,
    isTemporary: data.is_temporary,
    email: data.email,
  };
};

export const useUpdateStaffMutation = () => {
  return useMutation<
    Staff,
    AxiosError,
    { staffId: string; formData: StaffForm }
  >(({ staffId, formData }) => {
    return updateStaff(staffId, {
      is_admin: formData.isAdmin,
      job_type: formData.jobType,
      full_name: formData.fullName,
    });
  });
};
