import { isSameMonth } from "date-fns";
import { Highcharts, getProtLinesByTreatmentRecords } from "utils/chart";
import {
  PatientTreatmentRecords,
  DateDisplayTerms,
  PatientDailyAlertSummary,
} from "types";
import { getXaxisIntervalByTerms } from "utils/chart";

export const getAlertChartOptions = (
  alertSummaries: PatientDailyAlertSummary[],
  treatmentRecords: PatientTreatmentRecords,
  terms: DateDisplayTerms
) => {
  if (alertSummaries.length <= 0) return undefined;

  const data = alertSummaries.map((summary) => [
    Date.parse(summary.date),
    summary.maxAlertLevel,
  ]);

  let prevDate: Date | null = null;
  const options: Highcharts.Options = {
    chart: {
      spacingTop: 14,
      spacingBottom: 0,
      spacingLeft: 0,
      spacingRight: 0,
      height: 132,
    },
    title: {
      text: "",
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        type: "line",
        data,
        color: "transparent",
        zIndex: 999,
        lineWidth: 1,
        connectNulls: true,
        marker: {
          enabled: false,
        },
        states: {
          inactive: {
            enabled: false,
          },
          hover: {
            enabled: false,
          },
        },
      },
    ],
    xAxis: {
      title: {
        text: "",
      },
      type: "datetime",
      startOnTick: true,
      minPadding: 0,
      tickWidth: 0,
      tickLength: 0,
      gridLineWidth: 1,
      opposite: true,
      lineColor: "#e5e5e5",
      labels: {
        useHTML: true,
        style: {
          fontSize: "14px",
          color: "#000",
          padding: "0",
        },
        formatter: function () {
          const currentDate = new Date(this.value);

          if (prevDate == null || !isSameMonth(prevDate, currentDate)) {
            prevDate = currentDate;
            return Highcharts.dateFormat(
              '<div style="font-size: 14px; text-align: center; color: #000;"><span style="font-weight: bold; display: inline-block; margin-bottom: 2px; white-space:nowrap;">%Y年%m月</span><br><span>%e(%a)</span></div>',
              this.value as number
            );
          } else {
            prevDate = currentDate;
            return Highcharts.dateFormat("%e(%a)", this.value as number);
          }
        },
      },
      plotLines: getProtLinesByTreatmentRecords(treatmentRecords),
      tickPositioner: function () {
        const newTicks = [],
          min = this.min || 0,
          max = this.max || 0,
          interval = getXaxisIntervalByTerms(terms);

        for (let i = min; i <= max; i += interval) {
          newTicks.push(i);
        }

        return newTicks;
      },
    },
    yAxis: {
      title: undefined,
      max: 2,
      min: 0,
      tickInterval: 1,
      labels: {
        useHTML: true,
        formatter: function () {
          if (this.value === 1) {
            return '<div style="width:133px; font-size: 14px;color: #000; padding-left: 13px;box-sizing: border-box;">最大アラート</div>';
          } else {
            return "";
          }
        },
        x: 0,
        y: 20,
      },
    },
    tooltip: {
      enabled: false,
    },
  };

  return options;
};

export const getALertChartCallback = (
  alertSummaries: PatientDailyAlertSummary[]
) => {
  if (alertSummaries.length <= 0) return undefined;

  const data = alertSummaries.map((summary) => [
    Date.parse(summary.date),
    summary.maxAlertLevel,
  ]);

  return (chart: Highcharts.Chart) => {
    for (var j = 0; j < data.length - 1; j++) {
      var path: any[] = [],
        borderColor = "",
        alertValue = data[j][1];

      var p1 = chart.series[0].points[j],
        p2 = chart.series[0].points[j + 1];

      if (alertValue !== null) {
        if (alertValue === 1) {
          borderColor = "#5AAAF3";
        } else if (alertValue === 2) {
          borderColor = "#E0970A";
        } else if (alertValue === 3) {
          borderColor = "#EF609C";
        } else if (alertValue === 4) {
          borderColor = "#8C4CCB";
        } else {
          borderColor = "transparent";
        }

        chart.renderer
          .arc(
            (p1.plotX || 0) + chart.plotLeft,
            chart.plotTop + 36,
            4,
            0,
            -Math.PI,
            Math.PI
          )
          .attr({
            fill: borderColor,
            zIndex: 999,
          })
          .add();
      }

      if (data[j][1] === data[j + 1][1]) {
        p2 = p2 === undefined ? p1 : p2;
        path.push("M");
        path.push((p1.plotX || 0) + chart.plotLeft, chart.plotTop + 36);
        path.push("L");
        path.push((p2.plotX || 0) + chart.plotLeft, chart.plotTop + 36);
      }

      chart.renderer
        .path(path)
        .attr({
          "stroke-width": 8,
          stroke: borderColor,
          zIndex: 1000,
        })
        .add();
    }
  };
};
