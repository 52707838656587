import { FC } from "react";
import { Helmet } from "react-helmet-async";

type Props = {
  title: string;
};

export const Title: FC<Props> = ({ title }) => {
  return (
    <Helmet>
      <title>ハカルテ - {title}</title>
    </Helmet>
  );
};
