import { useQuery } from "react-query";
import { GetPatientsResponse, Patient } from "types";
import { get } from "lib/axios";

const getHighLevelAlertPatients = async (): Promise<Patient[]> => {
  const { data } = await get<GetPatientsResponse>(`/patients`, {
    params: {
      min_alert_level: 3,
    },
  });

  return data.results.map((patient) => ({
    id: patient.id,
    patientCode: patient.patient_code,
    firstName: patient.first_name,
    lastName: patient.last_name,
    alertLevel: patient.alert_level,
    tags: patient.tags,
    diseaseType: patient.disease_type,
    treatmentType: patient.treatment_type,
    isRelapse: patient.is_relapse,
    chemotherapyRound: patient.chemotherapy_round,
    lastTreatmentDate: patient.last_treatment_date,
    karteStatus: patient.karte_status,
    lastAlertedDate: patient.last_alerted_date,
    age: patient.age,
    isHighlighted: patient.is_highlighted,
  }));
};

export const useHighLevelAlertPatientsQuery = () => {
  return useQuery([`highLevelPatientList`], getHighLevelAlertPatients, {
    retry: false,
    staleTime: 1000 * 60 * 5,
  });
};
