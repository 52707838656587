import { createIcon } from "@chakra-ui/react";

export const TrashIcon = createIcon({
  displayName: "TrashIcon",
  defaultProps: {
    color: "gray.700",
  },
  viewBox: "0 0 24 24",
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.72076 2C8.8599 2 8.09562 2.55086 7.82339 3.36754L7.27924 5H4C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H4.13445L4.9547 19.2012C5.0606 20.7764 6.3692 22 7.94795 22H16.0521C17.6308 22 18.9394 20.7764 19.0453 19.2012L19.8655 7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H16.7208L16.1766 3.36754C15.9044 2.55086 15.1401 2 14.2792 2H9.72076ZM14.6126 5L14.2792 4H9.72076L9.38742 5H14.6126ZM6.13897 7H17.861L17.0498 19.0671C17.0145 19.5921 16.5783 20 16.0521 20H7.94795C7.4217 20 6.9855 19.5921 6.9502 19.0671L6.13897 7ZM11 10H9V18H11V10ZM13 10H15V18H13V10Z"
      fill="currentColor"
    />
  ),
});
