import { useMutation } from "react-query";
import { remove } from "lib/axios";
import { AxiosError } from "axios";

const deleteTagMutation = async (tagId: string) => {
  await remove<null>(`/tags/${tagId}`);
  return null;
};

export const useDeleteTagMutation = () => {
  return useMutation<null, AxiosError, string>((tagId) => {
    return deleteTagMutation(tagId);
  });
};
