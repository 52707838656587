import { Box, Container, Image, Link } from "components/lib";
import { FC } from "react";
import { LoggedInUserPopoverNavigation } from "components/app";
import { Link as ReactRouterLink } from "react-router-dom";
import { useAuthContext } from "contexts/AuthContext";
import { manualLink } from "data";

export const Header: FC = ({ children }) => {
  const { currentUser } = useAuthContext();

  return (
    <Box
      backgroundColor="white"
      borderBottom="1px"
      borderBottomColor="gray.300"
    >
      <Container
        maxWidth="1440px"
        display="flex"
        alignItems="center"
        px={6}
        h="3.5rem"
      >
        <Box flex={1}>
          <Link
            as={ReactRouterLink}
            to="/"
            size="120px"
            display="inline-block"
            position="relative"
            top="3px"
          >
            <Image src="/logo.svg" alt="ハカルテ" width="120px" />
          </Link>
        </Box>
        <Box flex={2} display="flex" justifyContent="center">
          {children}
        </Box>

        <Box flex={1} display="flex" justifyContent="flex-end">
          {currentUser !== undefined ? (
            <LoggedInUserPopoverNavigation />
          ) : (
            <Link href={manualLink} isExternal>
              <Box fontSize="sm">使い方</Box>
            </Link>
          )}
        </Box>
      </Container>
    </Box>
  );
};
