import { FC, useEffect } from "react";
import { Box, Container } from "components/lib";
import { Header, Title } from "components/app";
import { useNavigate } from "react-router";
import { useAuthContext } from "contexts/AuthContext";
import { EditPasswordForm } from "components/app";
import { useToast } from "hooks/useToast";
import { useChangePasswordMutation } from "hooks/api/auth/useChangePasswordMutation";
import { useScreenViewLogger } from "hooks/useScreenViewLogger";

export const ActivateTemporaryUserPage: FC = () => {
  const navigate = useNavigate();
  const { currentUser, activateTemporaryUser } = useAuthContext();
  const { mutate, isLoading } = useChangePasswordMutation();
  const toast = useToast();

  useEffect(() => {
    if (currentUser === undefined || !currentUser.isTemporary) {
      navigate("/");
    }
  }, [currentUser, navigate]);

  useScreenViewLogger();

  const handleSubmit = (password: string, confirmationPassword: string) => {
    mutate(
      {
        password: password,
        password_confirmation: confirmationPassword,
      },
      {
        onSuccess: () => {
          toast({
            title: "パスワードを更新しました",
            status: "success",
          });
          activateTemporaryUser();
          navigate("/");
        },
        onError: (e) => {
          toast({
            title: "エラーが発生しました",
            status: "error",
          });
          throw e;
        },
      }
    );
  };

  return (
    <Box>
      <Title title="新規パスワード登録" />
      <Header />
      <Box mt={16}>
        <Box textAlign="center" as="h1" fontSize="4xl">
          新規パスワード登録
        </Box>

        <Box as="p" mt="6" textAlign="center">
          新しいパスワードを入力して再設定を完了してください
        </Box>

        <Container maxW="25rem" mt="14">
          <EditPasswordForm onSubmit={handleSubmit} isLoading={isLoading} />
        </Container>
      </Box>
    </Box>
  );
};
