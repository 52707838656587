import { FC } from "react";
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  IconButton,
} from "components/lib";
import { TrashIcon } from "components/app";
import { useDisclosure } from "@chakra-ui/react";
import { useDeleteStaffMutation } from "hooks/api/staff/useDeleteStaffMutation";
import { useToast } from "hooks/useToast";
import { Staff } from "types";
import { useInvalidateStaffsCache } from "hooks/api/staff/useStaffsQuery";

type Props = {
  staff: Staff;
};

export const DeleteStaffIconButton: FC<Props> = ({ staff }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate, isLoading } = useDeleteStaffMutation();
  const ivalidateStaffsCache = useInvalidateStaffsCache();
  const toast = useToast();

  const handleDeleteStaff = () => {
    mutate(staff.id, {
      onSuccess: () => {
        ivalidateStaffsCache();
        toast({
          title: "医療者・スタッフを削除しました",
          status: "success",
        });
        onClose();
      },
    });
  };

  return (
    <>
      <IconButton
        aria-label="削除"
        icon={<TrashIcon boxSize="6" color="orange.800" />}
        variant="ghost"
        onClick={onOpen}
      />

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnEsc={!isLoading}
        closeOnOverlayClick={!isLoading}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>医療者・スタッフを削除</ModalHeader>
          <ModalCloseButton />
          <ModalBody p="10">
            <Box as="p" textAlign="center">
              {staff.fullName} を削除します。
              <br />
              よろしいですか?
            </Box>
            <Box display="flex" justifyContent="center" gap="16px" mt="10">
              <Button
                colorScheme="gray"
                onClick={onClose}
                isDisabled={isLoading}
              >
                キャンセル
              </Button>
              <Button onClick={handleDeleteStaff} isLoading={isLoading}>
                削除
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
