import { useToast as useChakraToast, UseToastOptions } from "@chakra-ui/react";
import { Box } from "components/lib";

type Props = {
  title: string;
  status?: UseToastOptions["status"];
};

const getColorSchema = (status: UseToastOptions["status"]) => {
  switch (status) {
    case "success":
      return {
        backgroundColor: "green.50",
        color: "green.700",
      };
    case "error":
      return {
        backgroundColor: "red.50",
        color: "red.500",
      };
    default:
      return {
        backgroundColor: "green.50",
        color: "green.700",
      };
  }
};

export const useToast = () => {
  const chakraToast = useChakraToast();

  return ({ title, status = "success" }: Props) => {
    chakraToast({
      position: "top",
      render: () => (
        <Box
          fontSize="sm"
          {...getColorSchema(status)}
          textAlign="center"
          position="relative"
          top="56px"
          p="4"
        >
          {title}
        </Box>
      ),
    });
  };
};
