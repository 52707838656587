import {
  default as OriginalHighCharts,
  XAxisPlotLinesOptions,
} from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import { PatientTreatmentRecords } from "types";
import { DateDisplayTerms } from "types";

HighchartsMore(OriginalHighCharts);
OriginalHighCharts.setOptions({
  lang: {
    weekdays: ["日", "月", "火", "水", "木", "金", "土"],
  },
});

export const Highcharts = OriginalHighCharts;

// 治療開始日に応じて、PlotLine のデータを返す。
export const getProtLinesByTreatmentRecords = (
  treatmentRecords: PatientTreatmentRecords = []
): XAxisPlotLinesOptions[] => {
  return treatmentRecords.map((record) => {
    return {
      color: "#FE7C19",
      width: 2,
      value: record.lastTreatmentDate.getTime(),
      label: {
        useHTML: true,
        text: "投与開始",
        rotation: 0,
        x: 0,
        y: 12,
        style: {
          display: "inline-block",
          padding: "4px",
          backgroundColor: "#FE7C19",
          color: "#fff",
          fontWeight: "bold",
        },
      },
      zIndex: 3,
    };
  });
};

// 表示期間に応じて、チャートの x軸を区切る間隔を返す。
export const getXaxisIntervalByTerms = (terms: DateDisplayTerms): number => {
  switch (terms) {
    case "3weeks":
      return 5 * 24 * 3600 * 1000;
    case "3months":
    case "6months":
      return 10 * 24 * 3600 * 1000;
  }
};
