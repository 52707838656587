import { FC } from "react";
import {
  FormErrorMessage as ChakraFormErrorMessage,
  FormErrorMessageProps,
} from "@chakra-ui/react";

type Props = FormErrorMessageProps;

export const FormErrorMessage: FC<Props> = ({ children, ...props }) => {
  return (
    <ChakraFormErrorMessage color="red.500" fontSize="sm" mt="0.5" {...props}>
      {children}
    </ChakraFormErrorMessage>
  );
};
