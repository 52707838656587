import { forwardRef } from "react";
import {
  AccordionButton as ChakraAccordionButton,
  AccordionButtonProps,
} from "@chakra-ui/react";

type Props = AccordionButtonProps;

export const AccordionButton = forwardRef<HTMLButtonElement, Props>(
  ({ children, ...rest }, ref) => {
    return (
      <ChakraAccordionButton
        ref={ref}
        background="gray.100"
        fontWeight="bold"
        _focus={{ outline: "none" }}
        {...rest}
      >
        {children}
      </ChakraAccordionButton>
    );
  }
);
