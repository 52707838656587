import { useMutation } from "react-query";
import {
  EditHospitalForm,
  PatchHospitalRequest,
  PatchHospitalResponse,
} from "types";
import { patch } from "lib/axios";
import { AxiosError } from "axios";

const updateHospitalMutation = async (
  params: PatchHospitalRequest
): Promise<void> => {
  await patch<PatchHospitalResponse, PatchHospitalRequest>("/hospital", params);
};

export const useUpdateHospitalMutation = () => {
  return useMutation<void, AxiosError, EditHospitalForm>((input) => {
    const params: PatchHospitalRequest = {
      contact_info: {
        description: input.description,
        contacts: input.contacts.map((contact) => ({
          id: contact.id,
          name: contact.name,
          tel_number: contact.telNumber,
          description: contact.description,
        })),
      },
    };

    return updateHospitalMutation(params);
  });
};
