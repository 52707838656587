import { FC, useEffect } from "react";
import { Box, Container } from "components/lib";
import { Header, Title } from "components/app";
import { HospitalAndStaffSwitchTab } from "components/app/navigation/HospitalAndStaffSwitchTab";
import { useAuthContext } from "contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { StaffList } from "./StaffList";
import { CreateStaffButton } from "./CreateStaffButton";
import { useScreenViewLogger } from "hooks/useScreenViewLogger";

export const StaffsPage: FC = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuthContext();

  useEffect(() => {
    if (currentUser === undefined || currentUser.isAdmin === false) {
      navigate("/login");
    }
  }, [currentUser, navigate]);

  useScreenViewLogger();

  if (currentUser === undefined) return null;

  return (
    <Box pb="24">
      <Title title="医療者・スタッフ" />
      <Header />
      <HospitalAndStaffSwitchTab activeTab="staffs" />
      <Container maxW="1024px" mt="10">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box as="h1" fontSize="4xl">
            医療者・スタッフ
          </Box>

          <CreateStaffButton />
        </Box>

        <StaffList />
      </Container>
    </Box>
  );
};
