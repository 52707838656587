import { createIcon } from "@chakra-ui/react";

export const ChevronLeftIcon = createIcon({
  displayName: "ChevronLeft",
  defaultProps: {
    color: "white",
  },
  viewBox: "0 0 16 16",
  path: (
    <path
      d="M10.3871 13.0524C10.7596 12.6798 10.7596 12.078 10.3871 11.7055L6.68069 7.99907L10.3871 4.29267C10.7596 3.92012 10.7596 3.31831 10.3871 2.94576C10.0145 2.5732 9.41273 2.5732 9.04018 2.94576L4.65554 7.33039C4.28299 7.70294 4.28299 8.30476 4.65554 8.67731L9.04018 13.0619C9.40317 13.4249 10.0145 13.4249 10.3871 13.0524Z"
      fill="currentColor"
    />
  ),
});
