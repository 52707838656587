import { FC } from "react";
import {
  Td,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalHeader,
  TableContainer,
  Table,
  Tr,
  Tbody,
  Box,
} from "components/lib";
import { CheckIcon, NoteIcon } from "components/app";
import { useDisclosure } from "@chakra-ui/react";
import { usePatientDataDisplayTermsContext } from "contexts/PatientDataDisplayTermsContext";
import { format, isSameMonth } from "date-fns";
import { ja } from "date-fns/locale";
import { getHolidayColorSchema } from "utils";
import { usePatientTreatmentRecordsQuery } from "hooks/api/patient/usePatientTreatmentRecordsQuery";
import { usePatientDailyAlertSummariesQuery } from "hooks/api/patient/usePatientDailyAlertSummariesQuery";
import { DailyTreatmentRecord } from "../../DailyTreatmentRecord";
import { useParams } from "react-router-dom";

type Props = {
  note: string | null;
  notes: (string | null)[];
};

export const Note: FC<Props> = ({ note, notes }) => {
  const { patientId = "" } = useParams<{ patientId: string }>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { eachDayOfTerms, displayStartDate, displayEndDate } =
    usePatientDataDisplayTermsContext();
  const { data: treatmentRecords = [] } = usePatientTreatmentRecordsQuery(
    patientId,
    {
      from_date: displayStartDate,
      to_date: displayEndDate,
    }
  );
  const { data: alertSummaries = [] } = usePatientDailyAlertSummariesQuery(
    patientId,
    {
      from_date: displayStartDate,
      to_date: displayEndDate,
    }
  );

  const getColorSchema = (maxAlertLevel: number) => {
    if (maxAlertLevel >= 4) {
      return {
        backgroundColor: "purple.50",
      };
    }
  };

  return (
    <Td border="2px" borderColor="gray.100" py="1" onClick={onOpen}>
      {note && <NoteIcon />}

      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>メモ</ModalHeader>
          <ModalBody>
            <Box display="flex" gap="4" mt="2">
              <Box display="flex" gap="2" alignItems="center">
                <Box
                  w="40px"
                  h="24px"
                  backgroundColor="red.50"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <CheckIcon color="orange.800" boxSize="20px" />
                </Box>
                <Box fontSize="sm">抗がん剤投与開始日</Box>
              </Box>
              <Box display="flex" gap="2" alignItems="center">
                <Box w="40px" h="24px" backgroundColor="purple.50" />
                <Box fontSize="sm">レベル4のアラート</Box>
              </Box>
            </Box>

            <TableContainer mt="4">
              <Table
                variant="unstyled"
                style={{ tableLayout: "fixed" }}
                height="100%"
              >
                <Tbody>
                  {eachDayOfTerms.map((day, index) => {
                    const prevDay = eachDayOfTerms[index - 1];

                    return (
                      <Tr key={index} h="2.3rem">
                        <Td
                          border="1px"
                          borderColor="gray.400"
                          py="2"
                          w="100px"
                        >
                          {!isSameMonth(prevDay, day) &&
                            format(day, "yyyy年MM月")}
                        </Td>
                        <Td
                          {...getHolidayColorSchema(day)}
                          border="1px"
                          borderColor="gray.400"
                          w="50px"
                        >
                          {format(day, "d")}
                        </Td>
                        <Td
                          {...getHolidayColorSchema(day)}
                          border="1px"
                          borderColor="gray.400"
                          w="50px"
                        >
                          {format(day, "E", { locale: ja })}
                        </Td>
                        <Td
                          border="1px"
                          borderColor="gray.400"
                          backgroundColor="gray.100"
                          w="80px"
                        >
                          <DailyTreatmentRecord
                            treatmentRecords={treatmentRecords}
                            date={day}
                          />
                        </Td>
                        <Td
                          border="1px"
                          borderColor="gray.400"
                          py="2"
                          px="4"
                          textAlign="left"
                          whiteSpace="pre-wrap"
                          {...(getColorSchema(
                            alertSummaries[index]?.maxAlertLevel
                          ) || 0)}
                        >
                          {notes[index]}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Td>
  );
};
