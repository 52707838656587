import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

export const initSentry = () => {
  if (process.env.REACT_APP_SENTRY_ENVIRONMENT === "development") {
    // ローカル環境では、エラーをSentry に通知しない.
    return;
  }

  Sentry.init({
    dsn: "https://58deeb22a8774feba5f1208a561cade4@o241731.ingest.sentry.io/6419852",
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    integrations: [new BrowserTracing({})],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.01,
  });
};
