import { useMutation } from "react-query";
import { PostTagResponse, Tag } from "types";
import { post } from "lib/axios";
import { AxiosError } from "axios";

const createTagMutation = async (tagName: string) => {
  const res = await post<PostTagResponse>("/tags", {
    name: tagName,
  });

  return res.data;
};

export const useCreateTagMutation = () => {
  return useMutation<Tag, AxiosError, string>((tagName) => {
    return createTagMutation(tagName);
  });
};
