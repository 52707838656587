import { useEffect } from "react";
import { useQuery } from "react-query";
import { Patient, GetPatientDetailResponse } from "types";
import { get } from "lib/axios";
import { useQueryClient } from "react-query";

const getPatientDetail = async (patientId: string): Promise<Patient> => {
  const { data: patient } = await get<GetPatientDetailResponse>(
    `/patients/${patientId}`
  );

  return {
    id: patient.id,
    patientCode: patient.patient_code,
    firstName: patient.first_name,
    lastName: patient.last_name,
    alertLevel: patient.alert_level,
    tags: patient.tags,
    diseaseType: patient.disease_type,
    treatmentType: patient.treatment_type,
    isRelapse: patient.is_relapse,
    chemotherapyRound: patient.chemotherapy_round,
    lastTreatmentDate: patient.last_treatment_date,
    karteStatus: patient.karte_status,
    lastAlertedDate: patient.last_alerted_date,
    age: patient.age,
    isHighlighted: patient.is_highlighted,
  };
};

export const usePatientDetailQuery = (patientId: string) => {
  return useQuery(
    ["patientDetail", patientId],
    () => {
      return getPatientDetail(patientId);
    },
    {
      retry: false,
      staleTime: 1000 * 60 * 10,
    }
  );
};

export const useRemovePatientDetailCacheOnUnmount = (patientId: string) => {
  const queryClient = useQueryClient();

  return useEffect(() => {
    return () => {
      queryClient.removeQueries(["patientDetail", patientId]);
    };
  }, [queryClient, patientId]);
};
