import { useMutation } from "react-query";
import { post } from "lib/axios";
import { AxiosError } from "axios";

const sendPasswordResetEmail = async (email: string): Promise<void> => {
  await post<undefined, { email: string }>("/auth/password_reset_email", {
    email,
  });
};

export const usePasswordResetEmailMutation = () => {
  return useMutation<void, AxiosError, string>((email) => {
    return sendPasswordResetEmail(email);
  });
};
