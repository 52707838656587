import { createIcon } from "@chakra-ui/react";

export const LeafIcon = createIcon({
  displayName: "Leaf",
  defaultProps: {
    color: "green.400",
    boxSize: "4",
  },
  viewBox: "0 0 16 16",
  path: (
    <path
      d="M2.16146 3.72965C2.04687 4.2661 2 4.97965 2 5.46923C2 9.49527 4.41667 12.1515 8.10417 12.1515C9.88021 12.1515 10.9375 11.3911 11.5 10.7921C11.9115 11.4015 12.125 12.0265 12.3906 12.8651C12.4792 13.1359 12.6771 13.2661 12.9062 13.2661C13.349 13.2661 13.7083 12.8807 13.7083 12.3703C13.7083 11.6255 12.6146 10.3234 12.0521 9.80256C9.66667 7.58381 6.0625 8.94319 5.21354 6.58902C5.15625 6.44319 5.3125 6.34944 5.43229 6.46923C7.25 8.42756 9.70833 6.81819 11.9323 8.85464C12.1771 9.07339 12.4792 8.97444 12.526 8.70881C12.5521 8.56298 12.5677 8.3234 12.5677 8.0734C12.5677 5.50048 10.8021 4.12027 8.125 4.12027C7.25521 4.12027 6.23958 4.34944 5.45833 4.34944C4.64583 4.34944 3.72396 4.29735 2.95833 3.50569C2.67187 3.21402 2.26042 3.29735 2.16146 3.72965Z"
      fill="currentColor"
    />
  ),
});
