import { createIcon } from "@chakra-ui/react";

export const AddIcon = createIcon({
  displayName: "Add",
  defaultProps: {
    color: "#636369",
  },
  viewBox: "0 0 24 24",
  path: (
    <path
      d="m4 11.995c0 .7431.60433 1.3375 1.33746 1.3375h5.33004v5.33c0 .7332.5944 1.3375 1.3275 1.3375.7431 0 1.3375-.6043 1.3375-1.3375v-5.33h5.33c.7332 0 1.3375-.5944 1.3375-1.3375 0-.7331-.6043-1.3275-1.3375-1.3275h-5.33v-5.33004c0-.73313-.5944-1.33746-1.3375-1.33746-.7331 0-1.3275.60433-1.3275 1.33746v5.33004h-5.33004c-.73313 0-1.33746.5944-1.33746 1.3275z"
      fill="currentColor"
    />
  ),
});
