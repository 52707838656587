import { useQuery } from "react-query";
import { GetHospitalResponse, Hospital } from "types";
import { get } from "lib/axios";
import { useQueryClient } from "react-query";

const getHospital = async (): Promise<Hospital | undefined> => {
  const { data } = await get<GetHospitalResponse>(`/hospital`);

  return {
    id: data.id,
    name: data.name,
    code: data.code,
    contactInfo: {
      description: data.contact_info.description,
      contacts: data.contact_info.contacts.map((info) => ({
        id: info.id,
        name: info.name,
        telNumber: info.tel_number,
        description: info.description,
      })),
    },
    whitelistDomains: data.whitelist_domains,
  };
};

export const useHospitalQuery = () => {
  return useQuery(["hospital"], getHospital, {
    retry: false,
    staleTime: 1000 * 60 * 10,
  });
};

export const useInvalidateHospitalQuery = () => {
  const queryClient = useQueryClient();

  return () => {
    queryClient.invalidateQueries("hospital");
  };
};
