import { forwardRef } from "react";
import { Input as ChakraInput, InputProps } from "@chakra-ui/react";

export * from "./InputGroup";
export * from "./InputLeftElement";
export * from "./InputRightElement";

type Props = InputProps;

export const Input = forwardRef<HTMLInputElement, Props>(({ ...rest }, ref) => {
  return (
    <ChakraInput
      ref={ref}
      focusBorderColor="orange.800"
      borderRadius="4px"
      backgroundColor="white"
      borderColor="gray.300"
      _placeholder={{
        color: "gray.500",
      }}
      _invalid={{
        color: "red.500",
        borderColor: "red.500",
        backgroundColor: "red.50",
        _placeholder: {
          color: "red.500",
        },
      }}
      _disabled={{
        color: "gray.700",
        backgroundColor: "gray.300",
      }}
      {...rest}
    />
  );
});
