import { useMutation } from "react-query";
import { remove } from "lib/axios";
import { AxiosError } from "axios";

const deleteStaff = async (staffId: string): Promise<void> => {
  await remove(`/staffs/${staffId}`);
};

export const useDeleteStaffMutation = () => {
  return useMutation<void, AxiosError, string>((staffId) => {
    return deleteStaff(staffId);
  });
};
