import { FC, useState, useEffect } from "react";
import { Box, Container } from "components/lib";
import { Header, MailIcon, Title } from "components/app";
import { RequestResetPasswordForm } from "./RequestResetPasswordForm";
import { useAuthContext } from "contexts/AuthContext";
import { useNavigate } from "react-router";
import { useScreenViewLogger } from "hooks/useScreenViewLogger";

export const RequestResetPasswordPage: FC = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuthContext();
  const [sendMailFnished, setSendMailFnished] = useState(false);

  useEffect(() => {
    if (currentUser !== undefined) {
      navigate("/");
    }
  }, [currentUser, navigate]);

  useScreenViewLogger();

  return (
    <Box>
      <Title title="パスワード再設定" />
      <Header />
      <Box mt={16}>
        {sendMailFnished ? (
          <Box textAlign="center">
            <Box as="h1" fontSize="4xl">
              送信完了
            </Box>
            <Box mt="20">
              <MailIcon boxSize="80px" />
            </Box>
            <Box as="p" mt="9" textAlign="center">
              パスワード再設定用のURLをメールでお送りしました。
              <br />
              メールに記載のリンクをクリックし、パスワードを再設定してください。
            </Box>
          </Box>
        ) : (
          <Box textAlign="center">
            <Box as="h1" fontSize="4xl">
              パスワード再設定
            </Box>
            <Box as="p" mt="6">
              パスワード再設定用のURLをメールでお送りします。
              <br />
              登録しているメールアドレスを入力の上、送信してください。
            </Box>
            <Container maxW="25rem" mt="14">
              <RequestResetPasswordForm
                onSuccess={() => setSendMailFnished(true)}
              />
            </Container>
          </Box>
        )}
      </Box>
    </Box>
  );
};
