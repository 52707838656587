import { TrashIcon } from "components/app";
import { useDisclosure } from "@chakra-ui/react";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  IconButton,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
} from "components/lib";
import { Tag } from "types";
import { FC } from "react";
import { useDeleteTagMutation } from "hooks/api/tag/useDeleteTagMutation";
import { useQueryClient } from "react-query";
import { usePatientSearchContext } from "contexts/PatientSearchContext";

type Props = {
  tag: Tag;
};

export const DeleteTagIconButton: FC<Props> = ({ tag }) => {
  const { mutate, isLoading } = useDeleteTagMutation();
  const { searchParams, updateSearchParams } = usePatientSearchContext();
  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleDelete = () => {
    mutate(tag.id, {
      onSuccess: () => {
        const tags = queryClient.getQueryData<Tag[]>("tagList") || [];
        queryClient.setQueryData(
          "tagList",
          tags.filter(({ id }) => id !== tag.id)
        );

        // 患者の検索条件からタグを削除する。(=再検索する)
        updateSearchParams(
          "tagIds",
          searchParams.tagIds.filter((id) => id !== tag.id)
        );
      },
    });
  };

  return (
    <>
      <IconButton
        onClick={onOpen}
        icon={<TrashIcon boxSize="6" color="orange.800" />}
        variant="ghost"
        aria-label="タグを編集"
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>タグを削除</ModalHeader>
          <ModalCloseButton />
          <ModalBody p="10">
            <Box as="p" textAlign="center">
              「{tag.name}」を削除します。
              <br />
              このタグがついている情報すべてに
              <br />
              影響しますよろしいですか?
            </Box>
            <Box display="flex" justifyContent="center" gap="5" mt="10">
              <Button
                colorScheme="gray"
                mr="4"
                onClick={onClose}
                w="120px"
                disabled={isLoading}
              >
                キャンセル
              </Button>
              <Button w="120px" isLoading={isLoading} onClick={handleDelete}>
                削除
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
