import { Box } from "components/lib";
import { alertLevelColorScheme } from "data";
import { FC } from "react";
import { AlertLevel, KarteStatus } from "types";

type Props = {
  alertLevel: AlertLevel;
  karteStatus: KarteStatus;
};

export const AlertLevelBadge: FC<Props> = ({ alertLevel, karteStatus }) => {
  const getColorSchema = (): { color: string; backgroundColor: string } => {
    if (alertLevel >= 0) {
      return {
        color: "white",
        backgroundColor:
          alertLevelColorScheme[alertLevel].primary || "transparent",
      };
    }

    if (karteStatus === 1) {
      return {
        color: "white",
        backgroundColor: "gray.700",
      };
    } else {
      return {
        color: "gray.700",
        backgroundColor: "transparent",
      };
    }
  };

  const getDisplayValue = () => {
    if (alertLevel >= 0) {
      return alertLevel.toString();
    } else {
      if (karteStatus === 1) return "0";
      if (karteStatus === 0) return "未";
      if (karteStatus === -1) return "-";
    }
  };

  return (
    <Box
      lineHeight="1"
      width="1.5rem"
      height="1.5rem"
      borderRadius="50%"
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      fontWeight="bold"
      fontSize="xs"
      {...getColorSchema()}
    >
      <Box position="relative" top="-1px">
        {getDisplayValue()}
      </Box>
    </Box>
  );
};
