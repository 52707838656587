import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  Spinner,
} from "components/lib";
import { useTagsQuery } from "hooks/api/tag/useTagsQuery";
import { EditTagLink } from "../EditTagLink";
import { usePatientSearchContext } from "contexts/PatientSearchContext";
import { ChangeEvent } from "react";
import { usePatientsQuery } from "hooks/api/patient/usePatientsQuery";

export const TagFilter = () => {
  const { data: tags = [], isLoading } = useTagsQuery();
  const { updateSearchParams, searchParams } = usePatientSearchContext();
  const { isFetching: isPatientsLoading } = usePatientsQuery();
  const selectedTagIds = searchParams["tagIds"];

  const handleChangeTag = (e: ChangeEvent<HTMLInputElement>) => {
    const tagId = e.target.value;
    const newTagIds = e.target.checked
      ? [...selectedTagIds, tagId]
      : selectedTagIds.filter((id) => id !== tagId);

    updateSearchParams("tagIds", newTagIds);
  };

  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box flex="1" textAlign="left" fontSize="sm">
            タグ
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel as="ul" p={0} backgroundColor="white">
        {isLoading ? (
          <Box display="flex" justifyContent="center" py="4">
            <Spinner />
          </Box>
        ) : (
          tags.map((tag) => (
            <Box
              key={tag.id}
              as="li"
              px={4}
              py={2.5}
              borderTop="1px"
              borderTopColor="gray.300"
            >
              <Checkbox
                w="100%"
                spacing={4}
                size="lg"
                value={tag.id}
                onChange={handleChangeTag}
                isChecked={selectedTagIds.includes(tag.id)}
                isDisabled={isPatientsLoading}
              >
                <Box fontSize="sm">{tag.name}</Box>
              </Checkbox>
            </Box>
          ))
        )}
        <EditTagLink />
      </AccordionPanel>
    </AccordionItem>
  );
};
