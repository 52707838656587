import { useMutation } from "react-query";
import { post } from "lib/axios";
import { AxiosError } from "axios";
import { PostChangePasswordRequest } from "types";

const changePassword = async (
  params: PostChangePasswordRequest
): Promise<void> => {
  await post<undefined, PostChangePasswordRequest>(
    "/auth/change_password",
    params
  );
};

export const useChangePasswordMutation = () => {
  return useMutation<void, AxiosError, PostChangePasswordRequest>((params) => {
    return changePassword(params);
  });
};
