import { FC } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Input,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
} from "components/lib";
import { useQueryClient } from "react-query";
import { useCreateTagMutation } from "hooks/api/tag/useCreateTagMutation";
import { Tag } from "types";

type Props = {
  allTags: Tag[];
};

export const CreateTagForm: FC<Props> = ({ allTags }) => {
  const { mutate, isLoading } = useCreateTagMutation();
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<{ name: string }>({
    defaultValues: {
      name: "",
    },
  });

  const onSubmit = (data: { name: string }) => {
    mutate(data.name, {
      onSuccess: (newTag) => {
        setValue("name", "");

        // cache 更新
        const tags = queryClient.getQueryData<Tag[]>("tagList") || [];
        queryClient.setQueryData("tagList", [...tags, newTag]);
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box p="6">
        <FormControl isInvalid={errors.name !== undefined}>
          <Input
            {...register("name", {
              required: "必須項目です",
              maxLength: {
                value: 20,
                message: "20文字以内で入力してください",
              },
              validate: {
                unique: (v) => {
                  return allTags.findIndex((tag) => tag.name === v) >= 0
                    ? "同じ名前のタグは作成できません"
                    : undefined;
                },
              },
            })}
          />
          <FormHelperText>20文字以内</FormHelperText>
          {errors.name && (
            <FormErrorMessage>{errors.name.message}</FormErrorMessage>
          )}
        </FormControl>
        <Box mt="4" display="flex" justifyContent="flex-end">
          <Button type="submit" isLoading={isLoading}>
            新規タグを追加
          </Button>
        </Box>
      </Box>
    </form>
  );
};
