import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { setUpFirebase } from "lib/firebase";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { initSentry } from "lib/sentry";

import { theme } from "./theme";
import {
  LoginPage,
  RequestResetPasswordPage,
  EditPasswordPage,
  HospitalPage,
  StaffsPage,
  PatientsPage,
  PatientDetailPage,
  NotFoundPage,
  ActivateTemporaryUserPage,
  ErrorBoundary,
} from "./pages";

import { AuthContextProvider } from "./contexts/AuthContext";
import { PatientSearchContextProvider } from "./contexts/PatientSearchContext";
import { HelmetProvider } from "react-helmet-async";

initSentry();
setUpFirebase();
const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <HelmetProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <ErrorBoundary>
              <AuthContextProvider>
                <PatientSearchContextProvider>
                  <Routes>
                    <Route
                      path="/"
                      element={<Navigate to="/patients" replace />}
                    />
                    <Route path="/login" element={<LoginPage />} />
                    <Route
                      path="/requestResetPassword"
                      element={<RequestResetPasswordPage />}
                    />
                    <Route
                      path="/editPassword"
                      element={<EditPasswordPage />}
                    />
                    <Route
                      path="/activateTemporaryUser"
                      element={<ActivateTemporaryUserPage />}
                    />
                    <Route path="/hospital" element={<HospitalPage />} />
                    <Route path="/staffs" element={<StaffsPage />} />
                    <Route path="/patients" element={<PatientsPage />} />
                    <Route
                      path="/patientDetail/:patientId"
                      element={<PatientDetailPage />}
                    />
                    <Route path="*" element={<NotFoundPage />} />
                  </Routes>
                </PatientSearchContextProvider>
              </AuthContextProvider>
            </ErrorBoundary>
          </BrowserRouter>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </HelmetProvider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
