import { FC } from "react";
import { useHospitalQuery } from "hooks/api/hospital/useHospitalQuery";
import {
  Box,
  TableContainer,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "components/lib";
import { CreateContactButton } from "./CreateContactButton";
import { EditContactIconButton } from "./EditContactIconButton";
import { DeleteContactButton } from "./DeleteContactButton";

export const ContactList: FC = () => {
  const { data: hospital } = useHospitalQuery();

  if (hospital === undefined) return null;

  return (
    <Box mt="16">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box as="h2" fontSize="2xl">
          対応窓口
        </Box>
        <CreateContactButton hospital={hospital} />
      </Box>

      <TableContainer mt="4" backgroundColor="white" borderRadius="16px">
        <Table>
          <Thead>
            <Tr borderBottom="1px" borderBottomColor="gray.300">
              <Th
                w="240px"
                textAlign="left"
                px="10"
                pt="6"
                pb="4"
                border="none"
              >
                窓口名
              </Th>
              <Th
                w="200px"
                textAlign="left"
                px="10"
                pt="6"
                pb="4"
                border="none"
              >
                電話番号
              </Th>
              <Th textAlign="left" px="10" pt="6" pb="4" border="none">
                備考
              </Th>
              <Th w="120px" border="none" />
            </Tr>
          </Thead>
          <Tbody>
            {hospital.contactInfo.contacts.length <= 0 ? (
              <Tr>
                <Td colSpan={5} py="20" color="gray.700">
                  患者さんにご案内する連絡先窓口を設定しましょう
                </Td>
              </Tr>
            ) : (
              hospital.contactInfo.contacts.map((contact, index) => (
                <Tr key={index} borderTop="1px" borderTopColor="gray.300">
                  <Td py="4" px="10" textAlign="left" fontSize="md">
                    {contact.name}
                  </Td>
                  <Td py="4" px="10" textAlign="left" fontSize="md">
                    {contact.telNumber}
                  </Td>
                  <Td py="4" px="10" textAlign="left" fontSize="md">
                    {contact.description}
                  </Td>
                  <Td py="0" pr="4" textAlign="right">
                    <EditContactIconButton
                      hospital={hospital}
                      contact={contact}
                    />
                    <DeleteContactButton
                      hospital={hospital}
                      contact={contact}
                    />
                  </Td>
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};
