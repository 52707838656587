import { FC, useEffect } from "react";
import { useAuthContext } from "contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { PatientDataDisplayTermsContextProvider } from "contexts/PatientDataDisplayTermsContext";
import { PatientDetailPageContent } from "./PatientDetailPageContent";
import { Title } from "components/app";
import { useScreenViewLogger } from "hooks/useScreenViewLogger";

export const PatientDetailPage: FC = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuthContext();

  // TODO: ProtectedRoute 的なコンポーネントにまとめる
  useEffect(() => {
    if (currentUser === undefined) {
      navigate("/login");
    }
  }, [currentUser, navigate]);

  useScreenViewLogger();

  if (currentUser === undefined) return null;

  return (
    <PatientDataDisplayTermsContextProvider>
      <Title title="患者詳細" />
      <PatientDetailPageContent />
    </PatientDataDisplayTermsContextProvider>
  );
};
