import { forwardRef } from "react";
import { Button as ChakraButton, ButtonProps } from "@chakra-ui/react";

type Props = ButtonProps;

export const Button = forwardRef<HTMLButtonElement, Props>(
  ({ children, ...rest }, ref) => {
    return (
      <ChakraButton
        ref={ref}
        colorScheme="orange"
        borderRadius="4px"
        fontWeight="normal"
        _hover={{
          opacity: "0.8",
        }}
        {...rest}
      >
        {children}
      </ChakraButton>
    );
  }
);
