import { useQuery } from "react-query";
import {
  GetDataByTermsRequest,
  PatientSymptomsAndMoods,
  GetPatientSymptomsResponse,
} from "types";
import { get } from "lib/axios";
import { useQueryClient } from "react-query";
import { useEffect } from "react";
import { formatDateToString } from "utils";

const getPatientDailySymptomsAndMoods = async (
  patientId: string,
  params: GetDataByTermsRequest
): Promise<PatientSymptomsAndMoods> => {
  const { data } = await get<GetPatientSymptomsResponse>(
    `/patients/${patientId}/symptoms`,
    {
      params: {
        from_date: formatDateToString(params.from_date),
        to_date: formatDateToString(params.to_date),
      },
    }
  );

  return {
    date: [...data.date],
    symptoms: data.symptoms.map((symptom) => ({
      symptomId: symptom.symptom_id,
      symptomName: symptom.symptom_name,
      values: [...symptom.values],
    })),
    moods: [...data.moods],
  };
};

export const usePatientDailySymptomsAndMoodsQuery = (
  patientId: string,
  params: GetDataByTermsRequest
) => {
  return useQuery(
    [`patientSymptoms`],
    () => {
      return getPatientDailySymptomsAndMoods(patientId, params);
    },
    {
      retry: false,
      staleTime: 1000 * 60 * 5,
    }
  );
};

export const useRemoveCacheOnUnMount = () => {
  const queryClient = useQueryClient();

  useEffect(() => {
    return () => {
      queryClient.removeQueries("patientSymptoms");
    };
  }, [queryClient]);
};
