import { ChangeEvent, FC } from "react";
import {
  Box,
  Checkbox,
  Modal,
  ModalCloseButton,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalHeader,
} from "components/lib";

import { CreateTagForm } from "../CreateTagForm";
import { useTagsQuery } from "hooks/api/tag/useTagsQuery";
import { useUpdatePatientTagsMutation } from "hooks/api/patient/useUpdatePatientTagsMutation";
import { Patient, Tag } from "types";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  patient: Patient;
  onChangePatientTags?: (newPatientTags: Tag[]) => void;
};

export const EditPatientTagModal: FC<Props> = ({
  isOpen,
  onClose,
  patient,
  onChangePatientTags = () => {},
}) => {
  const { data: allTags = [] } = useTagsQuery();
  const { mutate } = useUpdatePatientTagsMutation();
  const patientTagIds = patient.tags.map((tag) => tag.id);

  const handleChangePatientTag = (e: ChangeEvent<HTMLInputElement>) => {
    const newPatientTagIds = e.target.checked
      ? [...patientTagIds, e.target.value]
      : patientTagIds.filter((tagId) => tagId !== e.target.value);

    mutate(
      { patientId: patient.id, tagIds: newPatientTagIds },
      {
        onSuccess: (newTags) => {
          onChangePatientTags(newTags);
        },
      }
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {patient.lastName}
          {patient.firstName}さんのタグを管理
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="0">
          <Box as="ul">
            {allTags.map((tag, index) => (
              <Box
                key={index}
                as="li"
                pl="4"
                pr="2"
                py="4"
                display="flex"
                alignItems="center"
                borderBottom="1px"
                borderBottomColor="gray.300"
              >
                <Checkbox
                  size="lg"
                  flex="1"
                  value={tag.id}
                  onChange={handleChangePatientTag}
                  defaultChecked={patientTagIds.includes(tag.id)}
                >
                  <Box fontSize="sm" ml="2">
                    {tag.name}
                  </Box>
                </Checkbox>
              </Box>
            ))}
          </Box>

          <CreateTagForm allTags={allTags} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
