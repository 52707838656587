import { forwardRef } from "react";
import { Textarea as ChakraTextarea, TextareaProps } from "@chakra-ui/react";

type Props = TextareaProps;

export const Textarea = forwardRef<HTMLTextAreaElement, Props>((props, ref) => {
  return (
    <ChakraTextarea
      ref={ref}
      focusBorderColor="orange.800"
      backgroundColor="white"
      _placeholder={{
        color: "gray.700",
      }}
      {...props}
    />
  );
});
