import { Box, Spinner, Badge } from "components/lib";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { usePatientDetailQuery } from "hooks/api/patient/usePatientDetailQuery";
import {diseaseType, treatmentType} from "data";
import { format } from "date-fns";
import { ja } from "date-fns/locale";
import { Patient } from "types";
import { EditTagLink } from "./EditTagLink";

type PatientInfoProps = {
  patient?: Patient;
};

const PatientInfo: FC<PatientInfoProps> = ({ patient }) => {
  if (patient === undefined) return null;

  return (
    <>
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems="center"
        gap="4"
        pb="2"
      >
        <Box fontSize="lg">
          {patient.lastName} {patient.firstName}
        </Box>

        <Box fontSize="sm">{patient.patientCode}</Box>

        <Box fontSize="sm" fontWeight="bold">
          {patient.age}歳
        </Box>

        <Box fontSize="sm" fontWeight="bold">
          {diseaseType[patient.diseaseType]}
        </Box>

        <Box fontSize="sm">{patient.isRelapse ? "再発" : "初発"}</Box>

        {patient.chemotherapyRound !== null && (
          <Box fontSize="sm">
            {treatmentType[patient.treatmentType]}
            {` ${patient.chemotherapyRound}クール目`}
          </Box>
        )}

        <Box fontSize="sm">
          <Box as="span" fontWeight="bold">
            前回治療・診察：
          </Box>
          {patient.lastTreatmentDate &&
            format(Date.parse(patient.lastTreatmentDate), "yyyy.MM.dd(E)", {
              locale: ja,
            })}
        </Box>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        borderTop="1px"
        borderTopColor="gray.300"
        pt="4"
      >
        <Box
          display="flex"
          flex="1"
          flexWrap="wrap"
          gap="2"
          fontSize="sm"
          color="black"
        >
          {patient.tags.map((tag) => (
            <Badge key={tag.id} backgroundColor="white">
              {tag.name}
            </Badge>
          ))}
        </Box>

        <Box minW="8rem" textAlign="right">
          <EditTagLink patient={patient} />
        </Box>
      </Box>
    </>
  );
};

export const PatientDetail: FC = () => {
  const { patientId = "" } = useParams<{ patientId: string }>();
  const { data: patient, isLoading } = usePatientDetailQuery(patientId);

  return (
    <Box display="flex" justifyContent="center" mt="4">
      <Box
        backgroundColor="orange.50"
        p={4}
        borderRadius="16px"
        w="815px"
        minH="7rem"
      >
        {isLoading ? (
          <Box textAlign="center" pt="1.5rem">
            <Spinner />
          </Box>
        ) : (
          <PatientInfo patient={patient} />
        )}
      </Box>
    </Box>
  );
};
