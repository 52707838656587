import { useQuery } from "react-query";
import {
  GetDataByTermsRequest,
  PatientDailyAlertSummary,
  GetPatientDailyAlertSummariesResponse,
} from "types";
import { get } from "lib/axios";
import { useQueryClient } from "react-query";
import { useEffect } from "react";
import { formatDateToString } from "utils";

const getPatientDailyAlertSummaries = async (
  patientId: string,
  params: GetDataByTermsRequest
): Promise<PatientDailyAlertSummary[]> => {
  const { data } = await get<GetPatientDailyAlertSummariesResponse>(
    `/patients/${patientId}/alerts`,
    {
      params: {
        from_date: formatDateToString(params.from_date),
        to_date: formatDateToString(params.to_date),
      },
    }
  );

  return data.map((data) => ({
    date: data.date,
    maxAlertLevel: data.max_alert_level,
    count: data.count,
  }));
};

export const usePatientDailyAlertSummariesQuery = (
  patientId: string,
  params: GetDataByTermsRequest
) => {
  return useQuery(
    [`patientAlerts`],
    () => {
      return getPatientDailyAlertSummaries(patientId, params);
    },
    {
      retry: false,
      staleTime: 1000 * 60 * 5,
    }
  );
};

export const useRemoveCacheOnUnMount = () => {
  const queryClient = useQueryClient();

  return useEffect(() => {
    return () => {
      queryClient.removeQueries("patientAlerts");
    };
  }, [queryClient]);
};
