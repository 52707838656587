import { PencilIcon } from "components/app";
import { useDisclosure } from "@chakra-ui/react";
import {
  ModalOverlay,
  IconButton,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  FormErrorMessage,
} from "components/lib";
import { useForm } from "react-hook-form";
import { Tag } from "types";
import { FC } from "react";
import { useUpdateTagMutation } from "hooks/api/tag/useUpdateTagMutation";
import { useQueryClient } from "react-query";

type Props = {
  tag: Tag;
  allTags: Tag[];
};

export const EditTagIconButton: FC<Props> = ({ tag, allTags }) => {
  const { mutate, isLoading } = useUpdateTagMutation();
  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ name: string }>({
    defaultValues: {
      name: tag.name,
    },
  });

  const onSubmit = (data: { name: string }) => {
    mutate(
      { tagId: tag.id, name: data.name },
      {
        onSuccess: (updatedTag) => {
          // cache 更新
          const tagList = queryClient.getQueryData<Tag[]>("tagList") || [];
          const updatedTagList = tagList.map((tag) => {
            if (tag.id === updatedTag.id) {
              return updatedTag;
            }

            return tag;
          });
          queryClient.setQueryData("tagList", updatedTagList);

          onClose();
        },
      }
    );
  };

  return (
    <>
      <IconButton
        onClick={onOpen}
        icon={<PencilIcon boxSize="6" color="orange.800" />}
        variant="ghost"
        aria-label="タグを編集"
      />
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>タグを編集</ModalHeader>
          <ModalCloseButton />
          <ModalBody p="6">
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl isInvalid={errors.name !== undefined}>
                <Input
                  {...register("name", {
                    required: "必須項目です",
                    maxLength: {
                      value: 20,
                      message: "20文字以内で入力してください",
                    },
                    validate: {
                      unique: (v) => {
                        return allTags.findIndex(
                          (t) => t.id !== tag.id && t.name === v
                        ) >= 0
                          ? "同じ名前のタグは作成できません"
                          : undefined;
                      },
                    },
                  })}
                />
                <FormHelperText>20文字以内</FormHelperText>
                {errors.name !== undefined && (
                  <FormErrorMessage>{errors.name.message}</FormErrorMessage>
                )}
              </FormControl>

              <Box display="flex" justifyContent="flex-end" mt="6">
                <Button
                  colorScheme="gray"
                  mr="4"
                  onClick={onClose}
                  disabled={isLoading}
                >
                  キャンセル
                </Button>
                <Button type="submit" isLoading={isLoading}>
                  保存
                </Button>
              </Box>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
