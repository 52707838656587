import { Box, BoxProps } from "components/lib/layout";
import { FC, ReactNode } from "react";

type Props = {
  backgroundColor: BoxProps["backgroundColor"];
  children: ReactNode;
};

export const Badge: FC<Props> = ({ backgroundColor, children }) => {
  return (
    <Box
      display="inline-block"
      fontSize="xs"
      borderRadius="200px"
      p="2"
      lineHeight="100%"
      backgroundColor={backgroundColor}
    >
      {children}
    </Box>
  );
};
