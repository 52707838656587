import { FC, useEffect } from "react";
import { Box, Container } from "components/lib";
import { Header } from "components/app";
import { HospitalAndStaffSwitchTab } from "components/app/navigation/HospitalAndStaffSwitchTab";
import { useAuthContext } from "contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { HospitalInfo } from "./HospitalInfo";
import { ContactList } from "./ContactList";
import { Title } from "components/app";
import { useScreenViewLogger } from "hooks/useScreenViewLogger";

export const HospitalPage: FC = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuthContext();

  useEffect(() => {
    if (currentUser === undefined || currentUser.isAdmin === false) {
      navigate("/login");
    }
  }, [currentUser, navigate]);

  useScreenViewLogger();

  if (currentUser === undefined) return null;

  return (
    <Box pb="24">
      <Title title="問い合わせ先情報" />
      <Header />
      <HospitalAndStaffSwitchTab activeTab="hospital" />

      <Container maxW="1024px" mt="10">
        <Box as="h1" fontSize="4xl">
          問い合わせ先情報
        </Box>

        <HospitalInfo />
        <ContactList />
      </Container>
    </Box>
  );
};
