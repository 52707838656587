import { useMutation } from "react-query";
import { PostStaffRequest, PostStaffResponse, Staff, StaffForm } from "types";
import { post } from "lib/axios";
import { AxiosError } from "axios";

const createStaff = async (params: PostStaffRequest): Promise<Staff> => {
  const { data } = await post<PostStaffResponse, PostStaffRequest>(
    "/staffs",
    params
  );

  return {
    id: data.id,
    hospitalId: data.hospital_id,
    fullName: data.full_name,
    jobType: data.job_type,
    isAdmin: data.is_admin,
    isSuperAdmin: data.is_super_admin,
    isTemporary: data.is_temporary,
    email: data.email,
  };
};

export const useCreateStaffMutation = () => {
  return useMutation<Staff, AxiosError, StaffForm>((formData) => {
    return createStaff({
      is_admin: formData.isAdmin,
      job_type: formData.jobType,
      full_name: formData.fullName,
      email: formData.email,
    });
  });
};
