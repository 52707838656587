import { createIcon } from "@chakra-ui/react";

export const ThunderIcon = createIcon({
  displayName: "Thunder",
  defaultProps: {
    color: "yellow.800",
    boxSize: "4",
  },
  viewBox: "0 0 16 16",
  path: (
    <path
      d="M11.8634 6.09956L8.84446 6.06502V1.34664C8.8422 1.19375 8.78928 1.04592 8.69399 0.926336C8.5987 0.806747 8.46642 0.722153 8.31789 0.685811C8.16936 0.649469 8.01297 0.663432 7.87323 0.725512C7.73349 0.787593 7.61829 0.894282 7.5457 1.02886L3.40071 8.94578C3.35109 9.04989 3.32832 9.16476 3.33448 9.27992C3.34064 9.39508 3.37553 9.50686 3.43598 9.60508C3.49642 9.7033 3.58048 9.78482 3.68051 9.84222C3.78054 9.89961 3.89335 9.93105 4.00864 9.93367H6.97921L6.92395 14.6313C6.92159 14.786 6.97121 14.937 7.06486 15.0601C7.15851 15.1832 7.29078 15.2713 7.44046 15.3103C7.59015 15.3494 7.7486 15.337 7.89043 15.2753C8.03226 15.2135 8.14927 15.106 8.22271 14.9698L12.4644 7.12199C12.5218 7.01716 12.551 6.89924 12.5491 6.77975C12.5473 6.66027 12.5145 6.5433 12.4539 6.44028C12.3934 6.33726 12.3071 6.25172 12.2036 6.192C12.1001 6.13228 11.9829 6.10043 11.8634 6.09956Z"
      fill="currentColor"
    />
  ),
});
