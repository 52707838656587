import { FC, useMemo } from "react";
import { ExclamationCircleIcon, AlertLevelBadge } from "components/app";
import { EditTagLink } from "../EditTagLink";
import { diseaseType, treatmentType } from "data";
import { format } from "date-fns";
import { ja } from "date-fns/locale";
import { Badge, Box, Td, Tr } from "components/lib";
import { Patient } from "types";

type Props = {
  patient: Patient;
  onClick: (patient: Patient) => void;
};

export const PatientListItem: FC<Props> = ({ patient, onClick }) => {
  const ListItem = useMemo(() => {
    return (
      <Tr
        key={patient.id}
        borderTop="1px solid"
        borderTopColor="gray.300"
        transitionProperty="background-color"
        transitionDuration="fast"
        transitionTimingFunction="ease-out"
        _hover={{
          backgroundColor: "orange.50",
          cursor: "pointer",
        }}
        onClick={() => onClick(patient)}
      >
        <Td pl="6" pr="4" py="3">
          {patient.isHighlighted && <ExclamationCircleIcon boxSize="6" />}
        </Td>
        <Td pr="4" py="3">
          <AlertLevelBadge
            alertLevel={patient.alertLevel}
            karteStatus={patient.karteStatus}
          />
        </Td>
        <Td pr="4" py="3" textAlign="left" whiteSpace="pre-wrap">
          <Box fontWeight="bold">{`${patient.lastName ?? ""} ${
            patient.firstName ?? ""
          }`}</Box>
          <Box fontSize="xs" color="gray.800">
            {patient.patientCode}
          </Box>
        </Td>
        <Td pr="4" py="3" textAlign="left" whiteSpace="pre-wrap">
          <Box>{diseaseType[patient.diseaseType]}</Box>
          <Box fontSize="xs" color="gray.800">
            {treatmentType[patient.treatmentType]}
            {patient.chemotherapyRound !== null &&
              ` ${patient.chemotherapyRound}クール目`}
          </Box>
        </Td>
        <Td pr="4" py="3" textAlign="left">
          <Box fontSize="sm" color="gray.800">
            {patient.lastTreatmentDate &&
              format(Date.parse(patient.lastTreatmentDate), "yyyy.MM.dd(E)", {
                locale: ja,
              })}
          </Box>
        </Td>
        <Td
          pr="6"
          py="3"
          onClick={(e) => e.stopPropagation()}
          textAlign="right"
        >
          <Box display="flex" alignItems="center" gap="2">
            <Box
              display="flex"
              gap="1"
              flex="1"
              flexWrap="wrap"
              overflow="hidden"
            >
              {patient.tags.map((tag) => (
                <Badge backgroundColor="gray.100" key={tag.id}>
                  {tag.name}
                </Badge>
              ))}
            </Box>
            <Box>
              <EditTagLink patient={patient} />
            </Box>
          </Box>
        </Td>
      </Tr>
    );
  }, [patient, onClick]);

  return ListItem;
};
