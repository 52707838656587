import { FC } from "react";
import {
  Modal,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  IconButton,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Textarea,
  Button,
  Box,
} from "components/lib";
import { PencilIcon } from "components/app";
import { useDisclosure } from "@chakra-ui/react";
import { Hospital } from "types";
import { useToast } from "hooks/useToast";
import { useInvalidateHospitalQuery } from "hooks/api/hospital/useHospitalQuery";
import { useUpdateHospitalMutation } from "hooks/api/hospital/useUpdateHospitalMutation";
import { useForm } from "react-hook-form";

type Props = {
  hospital: Hospital;
};

export const EditHospitalInfoButton: FC<Props> = ({ hospital }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate, isLoading } = useUpdateHospitalMutation();
  const invalidateHospitalQuery = useInvalidateHospitalQuery();
  const toast = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ description: string }>({
    defaultValues: {
      description: hospital.contactInfo.description,
    },
  });

  const onSubmit = (data: { description: string }) => {
    mutate(
      {
        description: data.description,
        contacts: hospital.contactInfo.contacts,
      },
      {
        onSuccess: () => {
          invalidateHospitalQuery();
          toast({ title: "問い合わせ先を変更しました", status: "success" });
          onClose();
        },
        onError: (e) => {
          toast({
            title: "エラーが発生しました",
            status: "error",
          });
          throw e;
        },
      }
    );
  };

  return (
    <>
      <IconButton
        aria-label="編集"
        icon={<PencilIcon boxSize="6" color="orange.800" />}
        onClick={onOpen}
        variant="ghost"
      />

      <Modal
        size="5xl"
        isOpen={isOpen}
        onClose={onClose}
        closeOnEsc={!isLoading}
        closeOnOverlayClick={!isLoading}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>問い合わせ先を変更</ModalHeader>
          <ModalCloseButton />
          <ModalBody p="10">
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl>
                <FormLabel>病院名</FormLabel>
                <Input defaultValue={hospital.name} isDisabled />
                <FormHelperText>
                  病院名を変更するには問い合わせが必要です
                </FormHelperText>
              </FormControl>

              <FormControl mt="10" isInvalid={errors.description !== undefined}>
                <FormLabel>注意点</FormLabel>
                <Textarea
                  {...register("description")}
                  placeholder="例）あらかじめ患者IDをご確認の上お電話ください。"
                />
                <FormHelperText>300文字以内</FormHelperText>
              </FormControl>

              <Box mt="10" textAlign="center">
                <Button type="submit" w="200px" isLoading={isLoading}>
                  保存
                </Button>
              </Box>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
