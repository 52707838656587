import { forwardRef } from "react";
import {
  IconButton as ChakraIconButton,
  IconButtonProps,
} from "@chakra-ui/react";

type Props = IconButtonProps;

export const IconButton = forwardRef<HTMLButtonElement, Props>(
  ({ children, ...rest }, ref) => {
    return (
      <ChakraIconButton isRound ref={ref} {...rest}>
        {children}
      </ChakraIconButton>
    );
  }
);
