import { createIcon } from "@chakra-ui/react";

export const ChevronDownIcon = createIcon({
  displayName: "ChevronDown",
  defaultProps: {
    color: "#636369",
  },
  viewBox: "0 0 16 16",
  path: (
    <path
      d="M13.0528 4.94641C12.6802 4.57385 12.0784 4.57385 11.7058 4.94641L7.99944 8.65281L4.29304 4.94641C3.92049 4.57385 3.31867 4.57385 2.94612 4.94641C2.57357 5.31896 2.57357 5.92077 2.94612 6.29332L7.33076 10.678C7.70331 11.0505 8.30512 11.0505 8.67767 10.678L13.0623 6.29332C13.4253 5.93032 13.4253 5.31896 13.0528 4.94641Z"
      fill="currentColor"
    />
  ),
});
