import { FC } from "react";
import { Box, Container } from "components/lib";
import { Header, HighLevelAlertPatientList } from "components/app";
import { PatientDetail } from "../PatientDetail";
import { PatientDetailPageNavigation } from "../PatientDetailPageNavigation";
import {
  PatientAlertChart,
  PatientTemperatureChart,
  PatientStepsChart,
  PatientBloodPressureChart,
  PatientBodyWeightChart,
  PatientSleepChart,
} from "../charts";
import {
  PatientDailyLifeLogList,
  PatientDailyAlertList,
  PatientDailySymptomList,
} from "../tables";
import { DisplayTerms } from "../DisplayTerms";
import {
  usePatientDetailQuery,
  useRemovePatientDetailCacheOnUnmount,
} from "hooks/api/patient/usePatientDetailQuery";
import { useParams } from "react-router-dom";
import { NotFoundPage } from "pages/NotFoundPage";
import { usePatientDataDisplayTermsContext } from "contexts/PatientDataDisplayTermsContext";

export const PatientDetailPageContent: FC = () => {
  const { patientId = "" } = useParams<{ patientId: string }>();
  const { data: patient, isLoading } = usePatientDetailQuery(patientId);
  const { displayTerms } = usePatientDataDisplayTermsContext();
  useRemovePatientDetailCacheOnUnmount(patientId);

  if (isLoading === false && patient === undefined) {
    return <NotFoundPage />;
  }

  return (
    <Box display="flex" flexDirection="column" h="100%">
      <Header>
        <HighLevelAlertPatientList />
      </Header>

      <PatientDetailPageNavigation />

      <Container maxW="1440px" px="6">
        <PatientDetail />
        <DisplayTerms />

        {displayTerms === "3weeks" ? (
          <>
            <PatientDailyAlertList />
            <PatientDailySymptomList />
            <PatientDailyLifeLogList />
          </>
        ) : (
          <PatientAlertChart />
        )}

        <PatientBloodPressureChart />
        <PatientTemperatureChart />
        <PatientBodyWeightChart />
        <PatientStepsChart />
        <PatientSleepChart />
      </Container>
    </Box>
  );
};
