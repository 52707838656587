import { getDay, differenceInCalendarDays, format } from "date-fns";

// 土日用の色設定を返す。
export const getHolidayColorSchema = (
  day: Date
): { color: string; backgroundColor: string } => {
  switch (getDay(day)) {
    case 0: //日曜日
      return {
        color: "pink.300",
        backgroundColor: "pink.50",
      };
    case 6: //土曜日
      return {
        color: "blue.300",
        backgroundColor: "blue.50",
      };
    default:
      return {
        color: "black",
        backgroundColor: "white",
      };
  }
};

// 現在時刻からの差を表すテキストを返す。
// 未来の日付が渡された場合は、想定外なので、空文字を返す。
export const getDiffFromNowLabel = (date: Date) => {
  const diff = differenceInCalendarDays(date, new Date());
  if (diff === 0) {
    return "今日";
  } else if (diff < 0) {
    return `${Math.abs(diff)}日前`;
  }

  return "";
};

export const formatDateToString = (date: Date, formatString = "yyy-MM-dd") => {
  return format(date, formatString);
};
