import { FC } from "react";
import { useHospitalQuery } from "hooks/api/hospital/useHospitalQuery";
import {
  Box,
  TableContainer,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Spinner,
} from "components/lib";
import { EditHospitalInfoButton } from "./EditHospitalInfoButton";

export const HospitalInfo: FC = () => {
  const { data: hospital } = useHospitalQuery();

  if (hospital === undefined) {
    return (
      <Box mt="10" py="4" textAlign="center">
        <Spinner />
      </Box>
    );
  }

  return (
    <Box mt="8">
      <Box as="h2" fontSize="2xl">
        問い合わせ先
      </Box>
      <TableContainer mt="4" backgroundColor="white" borderRadius="16px">
        <Table>
          <Thead>
            <Tr borderBottom="1px" borderBottomColor="gray.300">
              <Th
                w="240px"
                textAlign="left"
                px="10"
                pt="6"
                pb="4"
                border="none"
              >
                病院名
              </Th>
              <Th textAlign="left" px="10" pt="6" pb="4" border="none">
                注意点
              </Th>
              <Th w="60px" border="none" />
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td
                py="4"
                px="10"
                textAlign="left"
                fontSize="md"
                verticalAlign="top"
              >
                {hospital.name}
              </Td>
              <Td py="4" px="10" textAlign="left" fontSize="md">
                <Box wordBreak="break-all" whiteSpace="normal">
                  {hospital.contactInfo.description || "未入力"}
                </Box>
              </Td>
              <Td py="0" pr="4" textAlign="right">
                <EditHospitalInfoButton hospital={hospital} />
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};
