import { useMutation } from "react-query";
import { put } from "lib/axios";
import { AxiosError } from "axios";
import { Tag, TagResponse } from "types";

const updateTagMutation = async (params: { tagId: string; name: string }) => {
  const { data } = await put<TagResponse>(`/tags/${params.tagId}`, {
    name: params.name,
  });

  return data;
};

export const useUpdateTagMutation = () => {
  return useMutation<Tag, AxiosError, { tagId: string; name: string }>(
    (params) => {
      return updateTagMutation(params);
    }
  );
};
