import { createIcon } from "@chakra-ui/react";

export const PencilIcon = createIcon({
  displayName: "PencilIcon",
  defaultProps: {
    color: "#636369",
  },
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M4.47227 14.7546L15.6096 3.58293C15.9831 3.20967 16.4895 3 17.0175 3C17.5455 3 18.0519 3.20967 18.4254 3.58293L20.4171 5.5746C20.7903 5.94807 21 6.45448 21 6.9825C21 7.51052 20.7903 8.01693 20.4171 8.39041L9.29119 19.5621C8.85945 19.9893 8.30899 20.2765 7.71159 20.3862L4.36925 21.0043C4.18608 21.041 3.99671 21.0322 3.81769 20.9789C3.63866 20.9255 3.47542 20.8292 3.3422 20.6982C3.20899 20.5672 3.10985 20.4057 3.05344 20.2276C2.99703 20.0495 2.98506 19.8603 3.01858 19.6765L3.62524 16.3342C3.746 15.7358 4.04064 15.1863 4.47227 14.7546Z"
      fill="currentColor"
    />
  ),
});
