import { createIcon } from "@chakra-ui/react";

export const ExclamationTriangleIcon = createIcon({
  displayName: "ExclamationTriangleIcon",
  defaultProps: {
    color: "#D40000",
  },
  viewBox: "0 0 24 24",
  path: (
    <path
      d="M4.11816 21H20.057C21.6869 21 22.7029 19.2325 21.888 17.8249L13.9185 4.05571C13.1036 2.6481 11.0716 2.6481 10.2566 4.05571L2.28721 17.8249C1.47227 19.2325 2.4883 21 4.11816 21ZM12.0876 13.5915C11.5055 13.5915 11.0292 13.1152 11.0292 12.5331V10.4164C11.0292 9.83434 11.5055 9.35808 12.0876 9.35808C12.6697 9.35808 13.1459 9.83434 13.1459 10.4164V12.5331C13.1459 13.1152 12.6697 13.5915 12.0876 13.5915ZM13.1459 17.8249H11.0292V15.7082H13.1459V17.8249Z"
      fill="currentColor"
    />
  ),
});
