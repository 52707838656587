import { FC, useEffect } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Button,
  Box,
  FormErrorMessage,
} from "components/lib";
import { Contact, ContactForm as ContactFormType } from "types";
import { useForm } from "react-hook-form";

type Props = {
  contact?: Contact;
  onSubmit: (data: ContactFormType) => void;
  isLoading: boolean;
};

export const ContactForm: FC<Props> = ({ contact, onSubmit, isLoading }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<ContactFormType>({
    defaultValues:
      contact === undefined
        ? { name: "", telNumber: "", description: "" }
        : contact,
  });

  useEffect(() => {
    return () => reset();
  }, [reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" gap={4} mt={6}>
        <FormControl flex={1} isInvalid={errors.name !== undefined}>
          <FormLabel isRequired>窓口名</FormLabel>
          <Input
            placeholder="例) 外来"
            {...register("name", {
              required: "必須項目です",
              maxLength: {
                value: 16,
                message: "16文字以内で入力してください",
              },
            })}
          />
          <FormHelperText>16文字以内</FormHelperText>
          {errors.name && (
            <FormErrorMessage>{errors.name.message}</FormErrorMessage>
          )}
        </FormControl>

        <FormControl
          width="160px"
          minW="160px"
          isInvalid={errors.telNumber !== undefined}
        >
          <FormLabel>電話番号</FormLabel>
          <Input
            type="tel"
            {...register("telNumber", {
              pattern: /^\d+$/,
            })}
            placeholder="例) 08012345678"
          />
          <FormHelperText>ハイフン無し数字のみ</FormHelperText>
          {errors.telNumber && (
            <FormErrorMessage>数字のみで入力してください</FormErrorMessage>
          )}
        </FormControl>

        <FormControl flex={1} isInvalid={errors.description !== undefined}>
          <FormLabel>備考</FormLabel>
          <Input
            placeholder="例) 平日 9:00-17:00"
            {...register("description", {
              maxLength: 22,
            })}
          />
          <FormHelperText>22文字以内</FormHelperText>
          {errors.description && (
            <FormErrorMessage>22文字以内で入力してください。</FormErrorMessage>
          )}
        </FormControl>
      </Box>

      <Box mt="10" textAlign="center">
        <Button type="submit" w="200px" isLoading={isLoading}>
          保存
        </Button>
      </Box>
    </form>
  );
};
