import { IconButton } from "components/lib";
import { PencilIcon } from "components/app";
import { useDisclosure } from "@chakra-ui/react";
import { FC } from "react";
import { Patient, Tag } from "types";
import { useQueryClient } from "react-query";
import { EditPatientTagModal } from "components/app/tag";

type Props = {
  patient: Patient;
};

export const EditTagLink: FC<Props> = ({ patient }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const queryClient = useQueryClient();

  const updatePatientTags = (newTags: Tag[]) => {
    const patientList =
      queryClient.getQueryData<Patient[]>("patientList") || [];

    const newPatientList = patientList.map((_patient) => {
      if (_patient.id === patient.id) {
        return {
          ..._patient,
          tags: newTags,
        };
      }

      return _patient;
    });

    queryClient.setQueryData("patientList", newPatientList);
  };

  return (
    <>
      <IconButton
        icon={<PencilIcon boxSize="6" color="orange.800" />}
        size="sm"
        variant="ghost"
        onClick={onOpen}
        aria-label="タグを編集"
      />

      <EditPatientTagModal
        isOpen={isOpen}
        onClose={onClose}
        patient={patient}
        onChangePatientTags={updatePatientTags}
      />
    </>
  );
};
