import { FC } from "react";
import {
  Modal,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  IconButton,
} from "components/lib";

import { useDisclosure } from "@chakra-ui/react";
import { useUpdateStaffMutation } from "hooks/api/staff/useUpdateStaffMutation";
import { useToast } from "hooks/useToast";
import { StaffForm as StaffFormType, Staff } from "types";
import { useInvalidateStaffsCache } from "hooks/api/staff/useStaffsQuery";
import { PencilIcon } from "components/app";
import { StaffForm } from "../../StaffForm";

type Props = {
  staff: Staff;
};

export const EditStaffIconButton: FC<Props> = ({ staff }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate, isLoading } = useUpdateStaffMutation();
  const ivalidateStaffsCache = useInvalidateStaffsCache();
  const toast = useToast();

  const onSubmit = (data: StaffFormType) => {
    mutate(
      { staffId: staff.id, formData: data },
      {
        onSuccess: () => {
          ivalidateStaffsCache();
          toast({
            title: "医療者・スタッフを変更しました",
            status: "success",
          });
          onClose();
        },
      }
    );
  };

  return (
    <>
      <IconButton
        aria-label="変更"
        icon={<PencilIcon boxSize="6" color="orange.800" />}
        variant="ghost"
        onClick={onOpen}
      />

      <Modal
        size="5xl"
        isOpen={isOpen}
        onClose={onClose}
        closeOnEsc={!isLoading}
        closeOnOverlayClick={!isLoading}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>医療者・スタッフを変更</ModalHeader>
          <ModalCloseButton />
          <ModalBody p="10">
            <StaffForm
              staff={staff}
              isLoading={isLoading}
              onSubmit={onSubmit}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
